import React from "react";
import { useState, useRef, useEffect } from "react";

const PasswordDialog = ({ isOpen, onClose, onSubmit, askReason = false }) => {
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState('');
  const dialogRef = useRef(null);
  const reasonRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.focus();
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({password, reason});
    setPassword("");
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-100"
      style={{zIndex:600}}
    >
      <div
        className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md"
        role="dialog"
        aria-modal="true"
        aria-labelledby="password-dialog-title"
        tabIndex={-1}
      >
        <h2 id="password-dialog-title" className="text-xl font-bold mb-4">
          Enter Password
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              autocomplete="off"
              value={password}
              ref={dialogRef}
              onInput={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          {
            askReason && <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Reason
            </label>
            <textarea
              type="text"
              id="password"
              value={reason}
              ref={reasonRef}
              onChange={(e) => setReason(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          }


          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordDialog;
