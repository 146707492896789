import { useState, useEffect } from "react";
import store from "../store/index";
import { useCloud } from "./useCloud";
import { v4 as uuidv4 } from 'uuid';

const IN_PROCESS_ORDERS_STORE_NAME = "InProcessOrders";
const COMPLETE_ORDERS_STORE_NAME = "completedOrders";
const ORDER_HISTORY_STORE_NAME = "orderHistory";

const useOrder = () => {
  const [orders, setOrders] = useState([]);
  const { dbInstance: db, orderHistory } = store;

  useEffect(() => {
    const fetchOrders = async () => {
      const allOrders = await getAllOrders();
      const allKOTs = [];
      const allKOTsVPA = [];

      allOrders.forEach((order) => {
        const { printKOT } = order;
        allKOTs.push(printKOT);
        allKOTsVPA.push({ tableVPA: order.tableVPA });
      });

      await getLatestBillNumber();
      store.addPrintedKOTs(allKOTs);
      store.addPrintedKOT_VPA(allKOTsVPA);
      setOrders(allOrders);
    };

    if (db) fetchOrders();
  }, [db]);

  const getLatestBillNumber = async (onlineBillNo = 0) => {
    let completeOrdersBillNo = await getHighestBillNoFromCompletedOrders();
    let inProcessOrdersBillNo = await getHighestBillNoFromInProcessOrders();
    let reportedOrdersBillNo = await getHighestBillNoFromInReportedORders();
    let localBillNumber = Number(localStorage.getItem("lastBillNo")) || 0 ;
    let lastBillNo = Math.max(completeOrdersBillNo, inProcessOrdersBillNo, reportedOrdersBillNo, localBillNumber, onlineBillNo);
    store.setLastBillNo(lastBillNo);
    return lastBillNo;
  }

  const fetchOrders = async () => {
    const allOrders = await getAllOrders();
    store.runningKOTs = allOrders
    setOrders(allOrders);
  };
  
  useEffect(() => {
    fetchOrders();
  }, [store.dbInstance]);

  const getAllOrders = async () => {
    const db = store.dbInstance;
    if (!db) return [];
    const orders = await db.getAll(IN_PROCESS_ORDERS_STORE_NAME);
    setOrders(orders); 
    store.runningKOTs = orders 
    return orders
  };
  
  const getOrderDetails = async (tableVPA) => {
    const db = store.dbInstance;
    if (!db) return [];
    return await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
  }

  const getHighestBillNoFromCompletedOrders = async () => {
    if (!db) return 0;
    const completedOrders = await db.getAll(COMPLETE_ORDERS_STORE_NAME);
    if (completedOrders.length === 0) return 0;
    return completedOrders.reduce((max, order) => {
      return order.billNo > max ? order.billNo : max;
    }, 0);
  };

  const getHighestBillNoFromInProcessOrders = async () => {
    if (!db) return 0;
    const inProcessOrders = await db.getAll(IN_PROCESS_ORDERS_STORE_NAME);
    if (inProcessOrders.length === 0) return 0;
    return inProcessOrders.reduce((max, order) => {
      return order.billNo > max ? order.billNo : max;
    }, 0);
  };

  const getHighestBillNoFromInReportedORders = async () => {
    if (!db) return 0;
    const processedOrder = await db.getAll(ORDER_HISTORY_STORE_NAME);
    if (processedOrder.length === 0) return 0;
    return processedOrder.reduce((max, order) => {
      return order.billNo > max ? order.billNo : max;
    }, 0);
  };


  const saveOrder = async (order) => {
    const db = store.dbInstance;
    if (!db) return;
    await db.add(IN_PROCESS_ORDERS_STORE_NAME, order);
    getAllOrders();
  };

  const saveCompleteOrder = async (order) => {
    const db = store.dbInstance;
    if (!db) return;
    try {
      await db.add(COMPLETE_ORDERS_STORE_NAME, order);
    }catch (e)
    {
      await db.put(COMPLETE_ORDERS_STORE_NAME, order);
    }
   
  };

  const updateOrder = async (order) => {
    const db = store.dbInstance;
    if (!db) return;
    await db.put(IN_PROCESS_ORDERS_STORE_NAME, order);
    getAllOrders();
  };

  const deleteOrder = async (id) => {
    const db = store.dbInstance;
    if (!db) return;
    await db.delete(IN_PROCESS_ORDERS_STORE_NAME, id);
    getAllOrders();
  };

  const orderExists = async (tableVPA) => {
    const db = store.dbInstance;
    if (!db) return false;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    return !!order;
  };

  const getUpdatedBillNo = async () => {
    let lastBillNo = await getLatestBillNumber();
    const updatedBillNo = lastBillNo + 1;
    store.setLastBillNo(updatedBillNo);
    localStorage.setItem("lastBillNo", updatedBillNo);
    return updatedBillNo;
  };

  const processOrder = async ({
    rawOrder,
    formatedOrder,
    tableVPA,
    cancleOrders = [],
    extraOrders = [],
    username = "NA",
    usermobile = "NA",
    orderStatus = "KOTPrint",
    onlineBillNo = "NA",
    replaceItems = "",
    isOnline = false,
  }) => {
    const createNewOrder = async () => {
      const newOrderId = await getUpdatedBillNo();
      const newOrder = {
        orderId:  uuidv4().slice(0, 6),
        usermobile: usermobile,
        username: username,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: newOrderId,
        billNo: newOrderId,
        tableVPA: tableVPA,
        status: orderStatus,
        backendOrder: {
          items: formatedOrder,
          usermobile: "NA",
          username: "NA",
          tableVPA: tableVPA,
        },
        cancleOrders: [],
        extraOrders: extraOrders,
        onlineBillNo: onlineBillNo,
      };
      const billAmount = rawOrder.reduce((acc, item) => {
        let price = 0;
        if (item?.selectedVariant) {
          price = parseFloat(item.selectedVariant.varientAmount).toFixed(2);
        } else {
          price = parseFloat(item.price).toFixed(2);
        }
        return acc + price * item.quantity;
      }, 0);

      let allOrderItems = rawOrder.reduce((acc, item) => {
        if (item.itemId === undefined) {
          const id = Math.floor(Math.random() * 1000000);
          item.itemId = id;
          item.uniqueId = id;
        }
        if (!acc[item.uniqueId]) {
          acc[item.uniqueId] = item;
        } else {
          acc[item.uniqueId].quantity += item.quantity;
        }
        return acc;
      }, {});

      allOrderItems = Object.values(allOrderItems);

      newOrder["amount"] = billAmount;
      newOrder["printKOT"] = {
        amount: newOrder.amount,
        billNo: newOrder.billNo,
        onlineBillNo: newOrder.onlineBillNo,
        name: newOrder.username,
        tableVPA: newOrder.tableVPA,
        items: allOrderItems.reduce((acc, item) => {
          if (item?.selectedVariant) {
            return [
              ...acc,
              {
                ...item,
                itemId: item.itemId,
                name: item.name + " - " + item.selectedVariant.varientName,
                price: parseInt(item.selectedVariant.varientAmount),
                itemId: item.itemId,
                status: newOrder.status,
                quantity: parseInt(item.quantity),
                varientid: item.selectedVariant.id,
                uniqueId: item.uniqueId,
              },
            ];
          }
          return [
            ...acc,
            {
              ...item,
              itemId: item.itemId,
              name: item.name,
              price: parseInt(item.price),
              itemId: item.itemId,
              status: newOrder.status,
              quantity: parseInt(item.quantity),
              uniqueId: item.uniqueId,
            },
          ];
        }, []),
      };

      // stored in indexedDB
      await saveOrder(newOrder);
      // process to store kot in store
      store.pushPrintedKOTs(newOrder.printKOT);
      store.pushPrintedKOT_VPA({ tableVPA: newOrder.tableVPA });
    };

    const updateExistingOrder = async () => {
      const db = store.dbInstance;
      const existingOrder = await db.get(
        IN_PROCESS_ORDERS_STORE_NAME,
        tableVPA
      );
  
      // the order which is fetched is already processed or not
      // if processed then remove the processed items from the order
      if (
        existingOrder.onlineBillNo === onlineBillNo &&
        onlineBillNo !== "NA"
      ) {
        let items = existingOrder.printKOT.items;
        items = items.filter((item) => item.status !== replaceItems);
        existingOrder.printKOT.items = items;
        existingOrder.backendOrder.items = items.map((item) => ({
          itemId: item.itemId,
          quantity: parseInt(item.quantity),
          varientid: item.varientid,
        }));
      }

      let orderAllItems = [...rawOrder, ...existingOrder.printKOT.items];
      orderAllItems = orderAllItems.reduce((acc, item) => {
        if (!acc[item.uniqueId]) {
          acc[item.uniqueId] = item;
        } else {
          acc[item.uniqueId].quantity += parseInt(item.quantity);
        }
        return acc;
      }, {});

      existingOrder.printKOT.items = Object.values(orderAllItems);

      existingOrder["updatedAt"] = new Date().toISOString();

      // just updating states by pushing them into the existing orders fields
      existingOrder["onlineBillNo"] = onlineBillNo;

      existingOrder["printKOT"]["amount"] = existingOrder["amount"];
      existingOrder["printKOT"]["items"] = existingOrder["printKOT"][
        "items"
      ].map((item) => {
        return {
          ...item,
          status: item?.status === "KOTPrint" ? item.status : orderStatus,
        };
      });

      existingOrder["backendOrder"]["items"] = existingOrder["printKOT"][
        "items"
      ].map((item) => ({
        itemId: item.itemId,
        quantity: parseInt(item.quantity),
        varientid: item.varientid || undefined,
      }));

      existingOrder["cancleOrders"] = [
        ...existingOrder["cancleOrders"],
        ...cancleOrders,
      ];
      existingOrder["extraOrders"] = [
        ...existingOrder["extraOrders"],
        ...extraOrders,
      ];

      if (isOnline) {
        existingOrder["status"] = orderStatus;
      } else {
        existingOrder["status"] = "KOTUpdated";
      }

      await updateOrder(existingOrder);
      store.updatePrintedKOTs(existingOrder.printKOT);
      fireKOTUpdatedEvent(existingOrder.printKOT);
    };

    const updateExistingOrderOnline = async () => {
      const db = store.dbInstance;
      const existingOrder = await db.get(
        IN_PROCESS_ORDERS_STORE_NAME,
        tableVPA
      );
    };

    console.log({ rawOrder, formatedOrder, tableVPA, store });
    return new Promise(async (resolve, reject) => {
      try {
        const isExists = await orderExists(tableVPA);
        const isOnlineOrder = isOnline;
        if (isOnlineOrder && isExists) {
          console.log("Online Order already exists");
          await updateExistingOrderOnline();
        }
        if (isExists) {
          console.log("Order exists");
          await updateExistingOrder();
        } else {
          console.log("Order does not exists");
          await createNewOrder();
        }
        fireKOTUpdatedEvent();
        fetchOrders()
        resolve(true);
      } catch (error) {
        console.error("Error processing order:", error);
        reject(false);
      }
    });
  };

  const processManualOrder = async ({
    rawOrder,
    formattedOrder,
    canceledItems,
    bill,
    customer,
    orderStatus = "KOTPrint",
    table,
    billDetails
  }) => {
    const createNewOrder = async function () {
      const newOrderId = await getUpdatedBillNo();
      const newOrder = {
        orderID: uuidv4().slice(0, 6),
        usermobile: customer.phone,
        username: customer.name,
        userAddress: customer.address,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: newOrderId,
        billNo: newOrderId,
        tableVPA: table.vpa,
        status: orderStatus,
        backendOrder: {
          items: formattedOrder,
          usermobile: "NA",
          username: "NA",
          tableVPA: table.vpa,
        },
        cancleOrders: canceledItems,
        extraOrders: rawOrder.filter((item) => item.extra),
        onlineBillNo: "NA",
        customer
      };
      newOrder.amount = bill.billAmount;
      newOrder["printKOT"] = {
        amount: newOrder.amount,
        billNo: newOrder.billNo,
        onlineBillNo: newOrder.onlineBillNo,
        name: newOrder.username,
        tableVPA: newOrder.tableVPA,
        items: rawOrder.map((item) => ({
          ...item,
          status: newOrder.status,
        })),
      };
      newOrder.billDetails = billDetails
      // stored in indexedDB
      await saveOrder(newOrder);
      // process to store kot in store
      store.pushPrintedKOTs(newOrder.printKOT);
      store.pushPrintedKOT_VPA({ tableVPA: newOrder.tableVPA });
      return newOrder.billNo
    };

    const updateExistingOrder = async function () {
      const db = store.dbInstance;
      const existingOrder = await db.get(
        IN_PROCESS_ORDERS_STORE_NAME,
        table.vpa
      );

      existingOrder["updatedAt"] = new Date().toISOString();
      existingOrder["amount"] = bill.billAmount;

      existingOrder["printKOT"]["amount"] = existingOrder["amount"];
      
      existingOrder["printKOT"]["items"] = rawOrder.map(item => ({...item, status: 'KOTPrint'}));
      existingOrder["printKOT"].name = customer.name
      existingOrder['status'] = "KOTPrint"
      existingOrder['billDetails'] = billDetails
      existingOrder['customer'] = customer
      existingOrder.usermobile = customer.phone
      existingOrder.username = customer.name
      existingOrder.userAddress = customer.address


      await updateOrder(existingOrder);
      store.updatePrintedKOTs(existingOrder.printKOT);
      
      return existingOrder.billNo
    };

    return new Promise(async (resolve, reject) => {
      try {
        const isExists = await orderExists(table.vpa);
        let billNo = ''
        if (isExists) {
          console.log("Order exists");
          billNo = await updateExistingOrder();
        } else {
          console.log("Order does not exists");
          billNo = await createNewOrder();
        }
        fireKOTUpdatedEvent();
        resolve(billNo);
      } catch (error) {
        console.error("Error processing order:", error);
        reject(false);
      }
    });
  };

  const acceptOnlineOrder = async (_order) => {
    const { vpa: tableVPA, onlineCode } = _order;
    const db = store.dbInstance;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);

    order.printKOT.items.forEach((item) => {
      if (item.uniqueId.includes("-online")) {
        item.uniqueId = item.uniqueId.replace("-online", "");
      }
      item.status = "KOTPrint";
    });
    order.status = "KOTPrint";

    let allOrderItems = order.printKOT.items.reduce((acc, item) => {
      if (!acc[item.uniqueId]) {
        acc[item.uniqueId] = item;
      } else {
        acc[item.uniqueId].quantity += parseInt(item.quantity);
      }
      return acc;
    }, {});

    order.printKOT.items = Object.values(allOrderItems);
    order.status = "KOTPrint";
    if (order.onlineCodes) {
      order.onlineCodes.push(onlineCode);
    } else {
      order.onlineCodes = [onlineCode];
    }

    await updateOrder(order);
    store.updatePrintedKOTs(order.printKOT);
    fireKOTUpdatedEvent(order.printKOT);
  };

  const rejectOnlineOrder = async (tableVPA) => {
    const db = store.dbInstance;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    const items = order.printKOT.items;
    order.printKOT.items = items.filter((item) => item.status === "KOTPrint");
    order.backendOrder.items = items
      .filter((item) => item.status === "KOTPrint")
      .map((item) => ({
        itemId: item.itemId,
        quantity: item.quantity,
        varientid: item.varientid || undefined,
      }));
 
    if (order.printKOT.items.length > 0) {
      order.status = "KOTUpdated";
      await updateOrder(order);
      store.updatePrintedKOTs(order.printKOT);
      fireKOTUpdatedEvent(order.printKOT);
    } else {
      await deleteOrder(tableVPA);
      store.removeDigitalOrderVPA(tableVPA);
      store.removePrintedKOT(tableVPA);
      store.removePrintedKOT_VPA(tableVPA);
    };

    fireKOTUpdatedEvent()
    
  };

  const cancelOrder = async (tableVPA, reason) => {
    const db = store.dbInstance;
    if (!db) return;
    try {
      const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
      order.status = "BILLCancelled";
      order.cancelReason = reason;
      await db.put(COMPLETE_ORDERS_STORE_NAME, order);
      await db.delete(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
      store.removePrintedKOT_VPA(tableVPA);
      store.removePrintedKOT(tableVPA);
      return true;
    } catch (error) {
      console.error("Error processing order:", error);
      return false;
    }
  };

  const updateOrderStatus = async (tableVPA, status) => {
    const db = store.dbInstance;
    if (!db) return;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    order.status = status;
    await db.put(IN_PROCESS_ORDERS_STORE_NAME, order);
    getAllOrders();
  };

  const updateOrderStatusToBillGenerated = async (tableVPA) => {
    await updateOrderStatus(tableVPA, "KOTBillGenerated");
  };

  const generateOrderBill = async ({
    tableVPA,
    waiterid,
    discount,
    totalAmount,
    name,
    mobile,
    billDetails,
  }) => {
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    order.status = "KOTBillGenerated";
    order.assignedWaiter = waiterid;
    order.discount = discount;
    order.amount = totalAmount;
    order.usermobile = mobile || order.usermobile;
    order.username = name || order.username;
    order["printKOT"]["amount"] = totalAmount;
    order["tips"] = billDetails?.tips || 0
    order.billDetails = billDetails
    await db.put(IN_PROCESS_ORDERS_STORE_NAME, order);
    getAllOrders();
    store.updatePrintedKOTs(order.printKOT);
    fireKOTBillGeneratedEvent(order.printKOT);
  };

  const completeOrderBill = async (order) => {
    return new Promise(async (resolve, reject) => {
      const { tableVPA, methods, paymentname, tips } = order;
      try {
        const existingOrder = await db.get(
          IN_PROCESS_ORDERS_STORE_NAME,
          tableVPA
        );
        existingOrder["status"] = "KOTBillCompleted";
        existingOrder["payment"] = {
          methods,
          paymentname,
          tips,
        };
        existingOrder["updatedAt"] = new Date().toISOString();
        
        store.removePrintedKOT_VPA(tableVPA);
        store.removePrintedKOT(tableVPA);
        await saveCompleteOrder(existingOrder);
        await deleteOrder(tableVPA);
        resolve(true);
      } catch (error) {
        console.error("Error completing order bill:", error);
        reject(false);
      }
    });
  };

  const fireKOTBillGeneratedEvent = (order) => {
    const event = new CustomEvent("KOTBillGenerated", { detail: order });
    window.dispatchEvent(event);
  };

  const fireKOTUpdatedEvent = (order) => {
    const event = new CustomEvent("KOTUpdated", { detail: order });
    window.dispatchEvent(event);
  };

  const getAllCompletedOrders = async () => {
    if (!db) return [];
    return await db.getAll(COMPLETE_ORDERS_STORE_NAME);
  };

  const flushAllCompleteOrders = async () => {
    const db = store.dbInstance;
    if (!db) return;
    await pushToOrdersHistory(await getAllCompletedOrders());
    await db.clear(COMPLETE_ORDERS_STORE_NAME);
  };

  const pushToOrdersHistory = async (orders) => {
    const db = store.dbInstance;
    if (!db) return;
    for (const order of orders) {
      await db.add(ORDER_HISTORY_STORE_NAME, order);
    }
  };

  const billStatus = (status) => {
    switch (status) {
      case "KOTBillCompleted":
        return "paid";
      case "BILLCancelled":
        return "cancelled";
    }
  };

  const processOrderToHistory = (order) => {
    return {
      id: order.id,
      invoiceNo: order.billNo,
      customerName: order.username,
      waiter: order.assignedWaiter || "Not Available",
      table: `Table ${store.tables[order.tableVPA]?.tableNo}`,
      state: billStatus(order.status),
      orderDate: new Date(order.createdAt).toDateString(),
      amount: order.amount,
      paymentmethod:
        billStatus(order.status) === "paid" ? order.payment.paymentname : "NA",
      partialAmoutsplit:
        billStatus(order.status) === "paid" ? order.payment.methods || [] : [],
    };
  };

  const transformOrderHistory = (local, cloud, noSynced) => {
    const localHash = local.reduce((acc, order) => {
      acc[order.billNo] = processOrderToHistory(order);
      return acc;
    }, {});
    const noSyncedHash = noSynced.reduce((acc, order) => {
      acc[order.billNo] = processOrderToHistory(order);
      return acc;
    }, {});

    const cloudHash = cloud.reduce((acc, order) => {
      acc[order.invoiceNo] = order;
      return acc;
    }, {});

    const combineOrders = { ...localHash, ...cloudHash, ...noSyncedHash };
    return Object.values(combineOrders);
  };

  const getOrderHistory = async () => {
    const db = store.dbInstance;
    if (!db) return [];
    const cloudOrderHistory = store.orderHistory;
    const localOrderHistory = await db.getAll(ORDER_HISTORY_STORE_NAME);
    const noSyncedOrderHistory = await db.getAll(COMPLETE_ORDERS_STORE_NAME);
    const orderHistory = transformOrderHistory(
      localOrderHistory,
      cloudOrderHistory,
      noSyncedOrderHistory
    );
    return orderHistory;
  };

  const getOnlineOrderBillNo = async (tableVPA) => {
    const db = store.dbInstance;
    if (!db) return false;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    return order.onlineBillNo;
  };

  const plusMinusByBillNo = async (order) => {
    return new Promise(async (resolve, reject) => {
      const { tableVPA, billNo, items } = order;

      try {
        // Fetch the existing order from IndexedDB
        const existingOrder = await db.get(
          IN_PROCESS_ORDERS_STORE_NAME,
          tableVPA
        );

        if (!existingOrder) {
          console.error("Order not found in IndexedDB.");
          reject("Order not found");
          return;
        }

        let updatedAmount = 0;

        // Update backendOrder items
        existingOrder.backendOrder.items.forEach((item) => {
          const change = items.find((c) => c.uniqueId === item.uniqueId);
          if (change) {
            item.quantity += parseInt(change.quantity); // Increment or decrement quantity
            if (item.quantity < 0) item.quantity = 0; // Ensure quantity doesn't go below 0
          }
        });

        // Update printKOT items and recalculate total amount
        existingOrder.printKOT.items.forEach((item) => {
          console.log(item);

          const change = items.find((c) => c.uniqueId === item.uniqueId);
          if (change) {
            item.quantity += parseInt(change.quantity); // Increment or decrement quantity
            if (item.quantity < 0) {
              item.quantity = 0;
            } else {
              updatedAmount +=
                parseFloat(item.price) * parseInt(change.quantity); // Recalculate amount
            }
            // Ensure quantity doesn't go below 0
          }
        });

        // Update the total amount in the order
        existingOrder.amount =
          parseFloat(existingOrder.amount) + parseFloat(updatedAmount);
        existingOrder.printKOT.amount =
          parseFloat(existingOrder.printKOT.amount) + parseFloat(updatedAmount);
        existingOrder.status = "KOTUpdated";

        // Save the updated order back to IndexedDB
        await db.put(IN_PROCESS_ORDERS_STORE_NAME, existingOrder);

        console.log(
          existingOrder,
          "Order updated successfully:",
          updatedAmount
        );
        fireKOTUpdatedEvent(existingOrder.printKOT);
        store.forceReload();
        resolve(existingOrder);
      } catch (error) {
        console.error("Error updating order in IndexedDB:", error);
        reject(error);
      }
    });
  };

  const handleSwitchTable = async (from, to) => {
    try {
      // Step 1: Fetch the order from IndexedDB
      const table = await db.get(IN_PROCESS_ORDERS_STORE_NAME, from);

      if (!table) {
        throw new Error(`Table with VPA ${from} not found.`);
      }

      // Step 2: Temporarily store the original data for rollback purposes
      const originalTable = JSON.parse(JSON.stringify(table));

      // Step 3: Update all `tableVPA` references
      table.tableVPA = to;

      if (table.backendOrder && table.backendOrder.tableVPA) {
        table.backendOrder.tableVPA = to;
      } else {
        throw new Error("backendOrder.tableVPA update failed.");
      }

      if (table.printKOT && table.printKOT.tableVPA) {
        table.printKOT.tableVPA = to;
      } else {
        throw new Error("printKOT.tableVPA update failed.");
      }

      // Step 4: Validate in-memory updates before committing
      const updatesValid =
        table.tableVPA === to &&
        table.backendOrder.tableVPA === to &&
        table.printKOT.tableVPA === to;

      if (!updatesValid) {
        throw new Error("Inconsistent tableVPA updates detected.");
      }

      // Step 5: Execute asynchronous updates with validation
      const deleteOldEntry = db.delete(IN_PROCESS_ORDERS_STORE_NAME, from);
      const saveNewEntry = saveOrder(table);

      const [deleteResult, saveResult] = await Promise.allSettled([
        deleteOldEntry,
        saveNewEntry,
      ]);

      // Check if any operation failed
      if (
        deleteResult.status !== "fulfilled" ||
        saveResult.status !== "fulfilled"
      ) {
        throw new Error("Failed to update IndexedDB.");
      }

      // Step 6: Update the store only after successful database updates
      store.removePrintedKOT_VPA(from);
      const all = [...store.printedKOT_VPA, to];
      store.addPrintedKOT_VPA(all);

      // Ensure the store update function works correctly
      const storeUpdateResult = await store.switchTableUpdate(from, to);
      if (!storeUpdateResult) {
        throw new Error("Store switchTableUpdate failed.");
      }

      console.log(`Table switched successfully from ${from} to ${to}`);
      return true;
    } catch (error) {
      console.error("Error switching table:", error.message);
      return false;

      // Rollback: You can optionally restore the original table here
    }
  };

  const getTableOrder = async (tableVPA) => {
    try {
      const db = store.dbInstance;
      if (!db) return;
      const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
      return order;
    } catch (error) {
      console.error("Error fetching table order status:", error);
      return null;
    }
  };

  const getTableOrderStatus = async (tableVPA) => {
    try {
      const db = store.dbInstance;
      if (!db) return;
      const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
      return order.status;
    } catch (error) {
      console.error("Error fetching table order status:", error);
      return "NA";
    }
  };

  const cleanSyncedOrders = async () => {
    const db = store.dbInstance;
    if (!db) new Error("Database not found");
    const completedOrders = await db.getAll(COMPLETE_ORDERS_STORE_NAME);
    if (completedOrders.length === 0) return [];
    const processedBills = JSON.parse(localStorage.getItem('processedBills') || '[]' ) ;
    
    completedOrders.forEach(order => {
      if(order.orderID === undefined)
      {
        order.orderID = uuidv4().slice(0, 6)
      }
    })

    const updatedOrders = completedOrders.filter((order) => !processedBills.includes(order.orderID) ).map((order) => {
      return order;
    });

    await db.clear(COMPLETE_ORDERS_STORE_NAME);
    for (const order of updatedOrders) {
      await db.add(COMPLETE_ORDERS_STORE_NAME, order);
      processedBills.push(order.orderID);
    }
    return updatedOrders;
  };

  const changeOrderPaymentMethod = async ({ billNo, paymentname }) => {
    try {
      const db = store.dbInstance;
      if (!db) return;

      const order = await db.get(ORDER_HISTORY_STORE_NAME, billNo);
      if (!order) return;
      order.payment.methods[0].name = paymentname;
      order.payment.paymentname = paymentname;
      await db.put(ORDER_HISTORY_STORE_NAME, order);

      const completeOrdersUpdatedEvent = new CustomEvent(
        "COMPLETED_ORDERS_UPDATED"
      );
      window.dispatchEvent(completeOrdersUpdatedEvent);
      return true;
    } catch (error) {
      console.error("Error updating order payment method:", error);
      return false;
    }
  };

  const getBillDetails = async (billNo) => {
    const order = orderHistory.find((order) => order.billNo === billNo);
    return order;
  };

  const updateOrderNameMobile = async (order, type) => {
    const db = store.dbInstance;
    if (!db) return;
    const existingOrder = await db.get(
      IN_PROCESS_ORDERS_STORE_NAME,
      order.tableVPA
    );
    if (type == "name") {
      existingOrder.username = order.username;
      existingOrder.backendOrder.username = order.username;
      existingOrder.printKOT.name = order.username;
      console.log(existingOrder, order.username, ".........ourdebda");
    } else {
      existingOrder.usermobile = order.usermobile;
      existingOrder.backendOrder.usermobile = order.usermobile;
      existingOrder.printKOT.mobile = order.usermobile;
      console.log(existingOrder, order.usermobile, ".........ourdebda");
    }
    await db.put(IN_PROCESS_ORDERS_STORE_NAME, existingOrder);
    // const updatedOrders = await getAllOrders();
    // setOrders(updatedOrders);
  };

  const setKOTItemPreparedStatus = async ({vpa, billNo, uniqueId, status}) => {
    const db = store.dbInstance;
    if (!db) return;
    const orders = await db.getAll(IN_PROCESS_ORDERS_STORE_NAME);
    const runningOrder= orders.find(order => order.tableVPA == vpa);
    runningOrder.printKOT.items.forEach(item => {
      if(item.uniqueId === uniqueId)
      {
        item.preparedStatus = status
      
        if(!item.readyAt && status == 'ready')
        {
          item.readyAt = new Date()
        }else if( status != 'ready')
        {
          item.readyAt = false
        }
      }
    })
    updateOrder(runningOrder)
  }

  return {
    orders,
    getAllOrders,
    getOrderDetails,
    saveOrder,
    updateOrder,
    deleteOrder,
    processOrder,
    processManualOrder,
    updateOrderStatus,
    updateOrderStatusToBillGenerated,
    generateOrderBill,
    completeOrderBill,
    getAllCompletedOrders,
    flushAllCompleteOrders,
    cancelOrder,
    pushToOrdersHistory,
    getOrderHistory,
    acceptOnlineOrder,
    rejectOnlineOrder,
    getOnlineOrderBillNo,
    plusMinusByBillNo,
    handleSwitchTable,
    getTableOrder,
    getTableOrderStatus,
    cleanSyncedOrders,
    changeOrderPaymentMethod,
    getBillDetails,
    updateOrderNameMobile,
    processOrderToHistory,
    setKOTItemPreparedStatus,
    getLatestBillNumber
  };
};

export default useOrder;
