export const  Reprint = async (
  billNo,
  discount,
  totalAmount,
  items,

  roomData,
  orderDate = ''
) => {
  const hotelData = {
    name: localStorage.getItem("name"),
    address: localStorage.getItem("address"),
  };
  const date = new Date(orderDate);
  const day = date.getDate(); 
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const time = '';
  const totalBillAmount = parseInt(totalAmount);

  const billHtml = `
      <div style="padding: 0; margin:0; background-color: white; font-family: Arial, sans-serif; width: 100%; max-width: 300px;">
        <h2 style="text-align: center; font-size: 1rem; font-weight: bold; margin-bottom: 1rem;">${
          hotelData.name
        }</h2>
        <p style="text-align: center; font-size: 0.7rem; line-height: 1rem;">${
          hotelData.address
        }</p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="font-size: 0.7rem; margin: 0.3rem 0;"><strong>Date:</strong> ${`${day} / ${month} / ${year} : ${time}`} </p>
        <p style="font-size: 0.9rem; margin: 0.5rem 0;"><strong>Table:</strong>  ${
          roomData.table
        }<strong>      Bill No: </strong> ${billNo}</p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <div>
          <table style="width: 100%; font-size: 0.7rem; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left;">Item</th>
                <th style="text-align: right;">Qty.</th>
                <th style="text-align: right;">Price</th>
                <th style="text-align: right;">Amount</th>
              </tr>
            </thead>
            <tbody>
              ${items
                ?.map(
                  (item) => item.quantity > 0 && `
                <tr>
                  <td style="padding: 0.25rem 0;"><strong>${
                    item.name
                  }</strong></td>
                  <td style="text-align: right;">${item.quantity}</td>
                  <td style="text-align: right;">${
                    item.price ? item.price : item.amount
                  }</td>
                  <td style="text-align: right;">${
                    parseFloat(item.price || item.amount) *
                    parseFloat(item.quantity)
                  }</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </div>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: right; font-size: 0.6rem; margin: 0.5rem 0;"><strong>Sub Total:</strong> ₹${
          parseFloat(totalBillAmount) + parseFloat(discount)
        }</p>
        ${
          discount > 0
            ? ` <p style="text-align: right; font-size: 0.9rem; margin: 0.5rem 0;"><strong>Discount:<i>${parseInt((parseInt(discount)*100)/(parseInt(totalBillAmount) + parseInt(discount))).toFixed(2)} % </i>/</strong> ₹${parseInt(
                discount
              )}</p>`
            : ""
        } 
        <p style="text-align: right; font-size: 1.2rem; margin: 0.5rem 0;"><strong>Grand Total:</strong> ₹${
          parseFloat(totalAmount) 
        }</p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">Powered by QRapid</p>
      </div>
    `;
  // Write the HTML to the new window
  const WinPrint = window.open("", "", "width=800,height=600");
  WinPrint.document.write(billHtml);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  
  return WinPrint.close();
};
