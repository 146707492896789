import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

import TableOverview from "../Component/TableOverview";
import Dashboard from "../pages/Dashboard";
import AcceptOrder from "../Component/AcceptOrder/AcceptOrder.main";
import Login from "../Component/AuthGard/LogIn";
import ItemManagement from "../Component/Menu/MenuManagament";
import SignUp from "../Component/AuthGard/SignUp";
import SignUpNew from "../Component/AuthGard/SignUpNew";
import ManagerForm from "../Component/AuthGard/ManagerForm";
import Room from "../Component/AuthGard/Room";
import Managment from "../Component/Managment/Managment";
import Ordermain from "../Component/PartnerOrder/Ordermain";

import ProtectedRoute from "./ProtectedRoute";
import Layout from "../Component/Layout";
import Orders from "../pages/Orders";
import Settings from "../pages/Settings"
import Reports from "../pages/Reports"

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/newsignup" element={<SignUpNew />} />
                <Route element={<Layout />}>
                    <Route path="/managerform" element={<ManagerForm />} />
                    <Route path="/rooms" element={<Room />} />
                    <Route
                        index
                        path="/table-overview"
                        element={<ProtectedRoute element={TableOverview} />}
                    />
                    <Route
                        path="/dashboard"
                        element={<ProtectedRoute element={Dashboard} />}
                    />
                    <Route
                        path="/accept-order"
                        element={<ProtectedRoute element={AcceptOrder} />}
                    />
                    <Route
                        path="/menu-managment"
                        element={<ProtectedRoute element={ItemManagement} />}
                    />
                    <Route path="/setting"
                        element={<ProtectedRoute element={Settings} />}
                    >
                    </Route>
                    <Route
                        path="/order-list-lifetime"
                        element={<ProtectedRoute element={Reports} />}
                    />
                    <Route
                        path="/order-main"
                        element={<ProtectedRoute element={Orders} />}
                    />
                    <Route
                        path="/order-parcel"
                        element={<ProtectedRoute element={Ordermain} />}
                    />
                    <Route
                        path="/hotel-managment"
                        element={<ProtectedRoute element={Managment} />}
                    />
                    <Route path="*" element={<Navigate to="/table-overview" />} />{" "}
                    {/* Redirect to table overview if route is unknown */}
                </Route>
            </Routes>
        </Router>
    );
};


export default AppRouter
