import { useEffect, useState } from "react";
import { SWIGGY } from "../LocalServicesDyno/LocalApiStore";

import { swiggyPrintKOT } from "./printoption/swiggyPrintKot";
import { swiggyPrintBillPartner } from "./printoption/SwiggyBillPrint";

export const SwiggyDetailsModel = ({ isOpen, onClose, data, btns, zomatoid }) => {
  const { acceptNewOrder, markOrderReady } = SWIGGY(); // Removed rejectOrder
  const [timeDifference, setTimeDifference] = useState("00:00:00");

  // Calculate time difference
  useEffect(() => {
    if (!data?.last_updated_time) return;

    const updateDifference = () => {
      const orderTime = new Date(data.last_updated_time);
      const currentTime = new Date();
      const diffInSeconds = Math.floor((currentTime - orderTime) / 1000);

      const hours = String(Math.floor(diffInSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((diffInSeconds % 3600) / 60)).padStart(2, "0");
      const seconds = String(diffInSeconds % 60).padStart(2, "0");

      setTimeDifference(`${hours}:${minutes}:${seconds}`);
    };

    updateDifference();
    const intervalId = setInterval(updateDifference, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [data?.last_updated_time]);

  if (!isOpen) return null;

  const accept = async (order_id, delivery_time, items) => {
    delivery_time = 30;
    const response = await acceptNewOrder( order_id);

    if (response.statusCode == "0") {
      swiggyPrintKOT({ items, orderId: order_id });
      onClose();
    }
  };

  const markReady = async (order_id) => {
    const response = await markOrderReady(order_id );
    if (response.statusCode == "0") {
      alert("Success");
      onClose();
    }
  };

  return (
    <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
      <div className="bg-white w-3/4 max-w-full p-6 rounded-lg shadow-lg relative">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold">Order Details</h2>
          <button onClick={onClose} className="text-red-500 font-bold text-lg">
            ×
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="mt-4 mb-16">
          <h3 className="text-lg font-semibold mb-2">Order Information</h3>
          <p>
            <strong>Order ID:</strong> {data.order_id}
          </p>
          <p>
            <strong>Time:</strong> {data.last_updated_time}
          </p>
          <p>
            <strong>Restaurant ID:</strong> {data.restaurant_details.restaurant_lat} / {data.restaurant_details.restaurant_lng}
          </p>
          <p>
            <strong>State:</strong> {data.status.order_status}
          </p>
          <p>
            <strong>Payment Method:</strong> {/* Add the payment method data if available */}
          </p>
          <p>
            <strong>Delivery Mode:</strong> {/* Add delivery mode if available */}
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mt-4 mb-2">Items Ordered</h3>
          {data.cart.items.map((item) => (
            <div key={item.item_id} className="mb-2">
              <p>
                <strong>Item Name:</strong> {item.name}
              </p>
              <p>
                <strong>Quantity:</strong> {item.quantity}
              </p>
              <p>
                <strong>Total Cost:</strong> ₹{item.total}
              </p>
              {item.addons && item.addons.map((addon) => (
                <div key={addon.name}>
                  <p><strong>Addon:</strong> {addon.name} (₹{addon.price})</p>
                </div>
              ))}
            </div>
          ))}

          <h3 className="text-lg font-semibold mt-4 mb-2">Total Charges</h3>
          <p>
            <strong>Subtotal:</strong> ₹{data.cart.items.reduce((total, item) => total + item.total, 0)}
          </p>
          <p>
            <strong>Total Bill:</strong> ₹{data.bill}
          </p>
        </div>

        {/* Fixed Buttons */}
        <div className="fixed bottom-0 left-5 right-0 bg-white border-t p-4 pl-[10%] flex justify-around">
          {btns?.printkot && (
            <button
              onClick={() =>
                swiggyPrintKOT({ items: data.cart.items, orderId: data.order_id })
              }
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
            >
              Print KOT
            </button>
          )}
          {btns?.accept && (
            <button
              onClick={() =>
                accept(data.order_id, "30", data.cart.items)
              }
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
            >
              Accept & Print KOT (Elapsed: {timeDifference})
            </button>
          )}
          {btns?.ready && (
            <button
              onClick={() => markReady(data.order_id)}
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
            >
              Ready (Elapsed: {timeDifference})
            </button>
          )}
          {btns?.printbill && (
            <button
              onClick={() =>
                swiggyPrintBillPartner({
                  items: data.cart.items,
                  subtotal: "-",
                  total: data.bill,
                  orderId: data.order_id,
                })
              }
              className="bg-gray-500 text-white px-4 py-2 rounded shadow hover:bg-gray-600"
            >
              Print Bill
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
