import { useEffect } from "react";
import { requestFcmToken } from "../utils/firebaseUtils";
import { useCloud } from "./useCloud";
import { isAuthenticated } from "../utils/common";
const useNotifications = () => {
  const { registerNotificationToken } = useCloud();
  const registerToken = async () => {
    const user = isAuthenticated();
    try {
      const token = await requestFcmToken();
      if (token && user) {
        await registerNotificationToken(token);
      }
    } catch (error) {
      console.error("Error getting FCM token:", error);
    }
  };

  return { registerToken };
};

export default useNotifications;
