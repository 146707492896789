import { ZOMATOAPI,SWIGGYAPI, ZOMATOAPIBASEEND } from "./EndpointApi";

const BASE_URL = ZOMATOAPIBASEEND;

export const ZOMATO = () => {
  const fetchInstance = async (endpoint, options = {}) => {
    const url = `${BASE_URL}${endpoint}`;
    const defaultHeaders = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "Authrisation":`${new Date().getTime()}`
    };
  
    const fetchOptions = {
      ...options,
      headers: { ...defaultHeaders, ...(options.headers || {}) },
    };
  
    try {
      const response = await fetch(url, fetchOptions);
      if (!response.ok) {
        const errorData = await response.json();
        // console.error("API Error:", errorData);
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      return await response.json();
    } catch (err) {
      // console.error("Network or Parsing Error:", err);
      throw err;
    }
  };
  

  const login = async () => {
    return fetchInstance(ZOMATOAPI.LOGIN, { method: "POST" });
  };

  const getCurrentOrders = async () => {
    try {
      return await fetchInstance(ZOMATOAPI.GET_CURRENT_ORDERS, { method: "GET" });
    } catch (err) {
      // console.error("Failed to fetch current orders:", err);
      throw err; // Optionally re-throw if needed
    }
  };
  

  const getOrderDetails = async (orderId) => {
    return fetchInstance(ZOMATOAPI.GET_ORDER_DETAILS + `?order_id=${orderId}`, {
      method: "GET",
    });
  };

  const acceptNewOrder = async ({ order_id, delivery_time }) => {
    return fetchInstance(ZOMATOAPI.ACCEPT_NEW_ORDER + `?order_id=${order_id}&delivery_time=${delivery_time}`, {
      method: "POST",
    });
  };

  const acceptNewOrderRestaurant = async (resId, orderId, deliveryTime = "30") => {
    const endpoint = ZOMATOAPI.ACCEPT_NEW_ORDER_RESTAURANT.replace("{res_id}", resId);
    return fetchInstance(`${endpoint}?order_id=${orderId}&delivery_time=${deliveryTime}`, { method: "POST" });
  };

  const markOrderReady = async ({ order_id }) => {
    return fetchInstance(ZOMATOAPI.MARK_ORDER_READY + `?order_id=${order_id}`, { method: "POST" });
  };

  const markOrderReadyRestaurant = async (resId, orderId) => {
    const endpoint = ZOMATOAPI.MARK_ORDER_READY_RESTAURANT.replace("{res_id}", resId);
    return fetchInstance(`${endpoint}?order_id=${orderId}`, { method: "POST" });
  };

  const getItems = async () => {
    return fetchInstance(ZOMATOAPI.GET_ITEMS, { method: "GET" });
  };

  const getItemsRestaurant = async (resId) => {
    const endpoint = ZOMATOAPI.GET_ITEMS_RESTAURANT.replace("{res_id}", resId);
    return fetchInstance(endpoint, { method: "GET" });
  };

  const markItemInStock = async (itemId) => {
    return fetchInstance(ZOMATOAPI.MARK_ITEM_IN_STOCK + `?item_id=${itemId}`, { method: "POST" });
  };

  const markItemInStockRestaurant = async (resId, itemId) => {
    const endpoint = ZOMATOAPI.MARK_ITEM_IN_STOCK_RESTAURANT.replace("{res_id}", resId);
    return fetchInstance(`${endpoint}?item_id=${itemId}`, { method: "POST" });
  };

  const markCategoryInStock = async (categoryId) => {
    return fetchInstance(ZOMATOAPI.MARK_CATEGORY_IN_STOCK + `?category_id=${categoryId}`, { method: "POST" });
  };

  const markCategoryInStockRestaurant = async (resId, categoryId) => {
    const endpoint = ZOMATOAPI.MARK_CATEGORY_IN_STOCK_RESTAURANT.replace("{res_id}", resId);
    return fetchInstance(`${endpoint}?category_id=${categoryId}`, { method: "POST" });
  };

  const markItemOutOfStock = async (itemId) => {
    return fetchInstance(ZOMATOAPI.MARK_ITEM_OUT_OF_STOCK + `?item_id=${itemId}`, { method: "POST" });
  };

  const rejectOrder = async ({ restaurant_id, order_id }) => {
    return fetchInstance(ZOMATOAPI.REJECT_ORDER + `?restaurant_id=${restaurant_id}&order_id=${order_id}`, {
      method: "POST",
    });
  };
  const cloneZomatoOutlet = async () => {
    return fetchInstance(ZOMATOAPI.CLOSE_OUTLET, {
      method: "POST",
    });
  };
  const reopenZomatoOutlet = async () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 1); // Set the time to 1 minute later
  
    const payload = {
      date: String(now.getDate()).padStart(2, "0"),
      month: String(now.getMonth() + 1).padStart(2, "0"), // Month is 0-based
      year: String(now.getFullYear()),
      hour: String(now.getHours()).padStart(2, "0"),
      min: String(now.getMinutes()).padStart(2, "0"),
      sec: String(now.getSeconds()).padStart(2, "0"),
    };
  
    return fetchInstance(
      `${ZOMATOAPI.CLOSE_OUTLET}?date=${payload.date}&month=${payload.month}&year=${payload.year}&hour=${payload.hour}&min=${payload.min}&sec=${payload.sec}`,
      {
        method: "POST",
      }
    );
  };
  
  

  return {
    login,
    getCurrentOrders,
    getOrderDetails,
    acceptNewOrder,
    acceptNewOrderRestaurant,
    markOrderReady,
    markOrderReadyRestaurant,
    getItems,
    getItemsRestaurant,
    markItemInStock,
    markItemInStockRestaurant,
    markCategoryInStock,
    markCategoryInStockRestaurant,
    markItemOutOfStock,
    rejectOrder,
    cloneZomatoOutlet,
    reopenZomatoOutlet

  };
};


export const SWIGGY = () => {
  const fetchInstance = async (endpoint, options = {}) => {
    const url = `${BASE_URL}${endpoint}`;
    const defaultHeaders = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "Authorization": `${new Date().getTime()}`,
    };

    const fetchOptions = {
      ...options,
      headers: { ...defaultHeaders, ...(options.headers || {}) },
    };

    try {
      const response = await fetch(url, fetchOptions);
      if (!response.ok) {
        const errorData = await response.json();
        // console.error("API Error:", errorData);
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      return await response.json();
    } catch (err) {
      // console.error("Network or Parsing Error:", err);
      throw err;
    }
  };

  const login = async () => {
    return fetchInstance(SWIGGYAPI.LOGIN, { method: "POST" });
  };

  const getOrders = async () => {
    return fetchInstance(SWIGGYAPI.GET_ORDERS, { method: "GET" });
  };

  const acceptNewOrder = async (order_id) => {
    return fetchInstance(SWIGGYAPI.ACCEPT_NEW_ORDER+`?order_id=${order_id}`, { method: "POST"  });
  };

  const acceptNewOrderRestaurant = async (resId) => {
    const endpoint = SWIGGYAPI.ACCEPT_NEW_ORDER_RESTAURANT(resId);
    return fetchInstance(endpoint, { method: "POST", });
  };

  const markOrderReady = async (order_id) => {
    return fetchInstance(SWIGGYAPI.MARK_ORDER_READY+`?order_id=${order_id}`, { method: "POST" });
  };

  const markOrderReadyRestaurant = async (resId) => {
    const endpoint = SWIGGYAPI.MARK_ORDER_READY_RESTAURANT(resId);
    return fetchInstance(endpoint, { method: "POST" });
  };

  const getItems = async () => {
    return fetchInstance(SWIGGYAPI.GET_ITEMS, { method: "GET" });
  };

  const getItemsRestaurant = async (resId) => {
    const endpoint = SWIGGYAPI.GET_ITEMS_RESTAURANT(resId);
    return fetchInstance(endpoint, { method: "GET" });
  };

  const markItemInStock = async (itemId) => {
    return fetchInstance(SWIGGYAPI.MARK_ITEM_IN_STOCK + `?item_id=${itemId}`, { method: "POST" });
  };

  const markItemInStockRestaurant = async (resId, itemId) => {
    const endpoint = SWIGGYAPI.MARK_ITEM_IN_STOCK_RESTAURANT(resId);
    return fetchInstance(`${endpoint}?item_id=${itemId}`, { method: "POST" });
  };

  const markCategoryInStock = async (categoryId) => {
    return fetchInstance(SWIGGYAPI.MARK_CATEGORY_IN_STOCK + `?category_id=${categoryId}`, { method: "POST" });
  };

  const markCategoryInStockRestaurant = async (resId, categoryId) => {
    const endpoint = SWIGGYAPI.MARK_CATEGORY_IN_STOCK_RESTAURANT(resId);
    return fetchInstance(`${endpoint}?category_id=${categoryId}`, { method: "POST" });
  };

  const markItemOutOfStock = async (itemId) => {
    return fetchInstance(SWIGGYAPI.MARK_ITEM_OUT_OF_STOCK + `?item_id=${itemId}`, { method: "POST" });
  };

  const markItemOutOfStockRestaurant = async (resId, itemId) => {
    const endpoint = SWIGGYAPI.MARK_ITEM_OUT_OF_STOCK_RESTAURANT(resId);
    return fetchInstance(`${endpoint}?item_id=${itemId}`, { method: "POST" });
  };

  const markCategoryOutOfStock = async (categoryId) => {
    return fetchInstance(SWIGGYAPI.MARK_CATEGORY_OUT_OF_STOCK + `?category_id=${categoryId}`, { method: "POST" });
  };

  const closeOutlet = async () => {
    return fetchInstance(SWIGGYAPI.CLOSE_OUTLET, { method: "POST" });
  };

  const closeOutletRestaurant = async (resId) => {
    const endpoint = SWIGGYAPI.CLOSE_OUTLET_RESTAURANT(resId);
    return fetchInstance(endpoint, { method: "POST" });
  };

  const getOrderHistory = async () => {
    return fetchInstance(SWIGGYAPI.GET_ORDER_HISTORY, { method: "GET" });
  };

  const deleteItem = async (id) => {
    return fetchInstance(SWIGGYAPI.DELETE_ITEM(id), { method: "DELETE" });
  };

  const updateItemPrice = async (itemId, price) => {
    const endpoint = SWIGGYAPI.UPDATE_ITEM_PRICE(itemId);
    const payload = { price };
    return fetchInstance(endpoint, {
      method: "POST",
      body: JSON.stringify(payload),
    });
  };

  return {
    login,
    getOrders,
    acceptNewOrder,
    acceptNewOrderRestaurant,
    markOrderReady,
    markOrderReadyRestaurant,
    getItems,
    getItemsRestaurant,
    markItemInStock,
    markItemInStockRestaurant,
    markCategoryInStock,
    markCategoryInStockRestaurant,
    markItemOutOfStock,
    markItemOutOfStockRestaurant,
    markCategoryOutOfStock,
    closeOutlet,
    closeOutletRestaurant,
    getOrderHistory,
    deleteItem,
    updateItemPrice,
  };
};
