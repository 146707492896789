import { useState, useRef, useEffect } from "react";
import { Download, ChevronDown, Filter, X, EyeOff, Eye } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint } from "@fortawesome/free-solid-svg-icons";
import useOrder from "../hooks/useOrder";
import { useCloud } from "../hooks/useCloud";
import { Reprint } from "../Component/Print/ReprintBill";
import store from "../store/index";
import { formatDate, adjustOrderDate } from "../utils/common";
import PasswordDialog from "../Component/PasswordInput";
import toast from "react-hot-toast"
const dateToString = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};
// Utility function for calculating the total amount
const calculateTotalAmount = (orders) => {
  return orders
    .reduce((acc, order) => acc + parseInt(order.amount), 0);
};

export default function OrdersReport() {
  const CLOSING_HOURS = 6;
  const PASSWORD = "12345";
  const [showPasswordDialogue, setShowPasswordDialogue] = useState(false);
  const [isProtected, setProtected] = useState(true);

  const { getOrderHistory } = useOrder();
  const { getBillInfo, getOrderHistory: getOrderHistoryCloud } = useCloud();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, SetError] = useState(null);
  const [isViewBillOpen, setIsViewBillOpen] = useState(false)
  const [viewBillSelectedItem, SetViewBillSelectedItem] = useState(null)

  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allPaymentTypes, setAllPaymentTypes] = useState([])
  const [platform, setPlatform] = useState("all");
  const [dateFilter, setDateFilter] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPlatformDropdownOpen, setIsPlatformDropdownOpen] = useState(false);
  const filterRef = useRef(null);

  const getAllOrders = async () => {
    const allOrders = await getOrderHistory();
    setFilteredData(allOrders);
    setTotalAmount(calculateTotalAmount(allOrders));

    const paymentTypes = Object.keys(allOrders.reduce((acc, order) => {
      const method = order.paymentmethod.toLowerCase()
      if (acc[method] == undefined) {
        acc[method] = 0
      }
      acc[method] += 1;
      return acc
    }, {}))

    // setPaymentMethods(paymentTypes)
    setAllPaymentTypes(paymentTypes)

    if (allOrders.length > 0) {
      allOrders.sort((a, b) => b.invoiceNo - a.invoiceNo);
      const firstOrder =
        allOrders.find((order) => order.invoiceNo === "1") || allOrders[0];
      setStartDate(adjustOrderDate(firstOrder.orderDate, CLOSING_HOURS));
      setEndDate(adjustOrderDate(allOrders[0].orderDate, CLOSING_HOURS));
    }
    return allOrders;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cloudOrderHistory = await getOrderHistoryCloud();
        store.updateOrderHistory(cloudOrderHistory);
        const orders = await getAllOrders();
        setOrders(orders);
        setFilteredData(orders);
      } catch (e) {
        console.groupCollapsed("Error in loading reports");
        console.log(e);
        console.groupEnd();
        SetError({
          message: e?.response?.data?.error || "Error in fetching report",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handlePaymentMethodToggle = (method) => {
    const _paymentMethods = paymentMethods.includes(method)
      ? paymentMethods.filter((m) => m !== method)
      : [...paymentMethods, method];

    setPaymentMethods(_paymentMethods);
    sudoFilter({ types: _paymentMethods });
  };

  const handleReset = () => {
    setDateRange({ from: "", to: "" });
    setPaymentMethods(["cash", "online", "card", "partial"]);
    setPlatform("all");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle bill print
  const handleBillPrint = async (order) => {
    const orderData = await getBillInfo({ billNo: order.invoiceNo });
    order = { ...order, ...orderData };
    await Reprint(
      order.invoiceNo,
      order.order.discount,
      order.amount,
      order.items,
      { table: order.table.split(" ")[1] },
      order.orderDate
    );
  };

  // Handle view bill
  const handleBillView = async (order) => {
    const orderData = await getBillInfo({ billNo: order.invoiceNo });
    order = { ...order, ...orderData };
    SetViewBillSelectedItem(order)
    setIsViewBillOpen(true)
  };

  const filterLastNDays = (days) => {
    const today = new Date();
    const previousDate = new Date();
    previousDate.setDate(today.getDate() - days);
    setStartDate(previousDate);
    setEndDate(today);
    setPaymentMethods(allPaymentTypes);
    sudoFilter({
      start: previousDate,
      end: today,
      types: allPaymentTypes,
    });
  };

  const filterByDate = (date) => {
    setStartDate(date);
    setEndDate(date);
    sudoFilter({
      start: date,
      end: date,
    });
  };

  const sudoFilter = (filter = {}) => {
    const {
      types = paymentMethods,
      start = startDate,
      end = endDate,
      selectedDate = dateFilter,
      selectedPlatform = platform,
    } = filter;

    let _start = new Date(start).setHours(0, 0, 0, 0);
    let _end = new Date(end).setHours(23, 59, 59, 999);
    // data sorted
    let filteredOrders = orders.filter((order) => {
      return (
        adjustOrderDate(new Date(order.orderDate), CLOSING_HOURS) >= _start &&
        adjustOrderDate(new Date(order.orderDate), CLOSING_HOURS) <= _end
      );
    });
    // type sorted
    filteredOrders = filteredOrders.filter((order) =>
      types.includes(order.paymentmethod.toLowerCase())
    );
    // platform
    if (selectedPlatform !== "all") {
      filteredOrders = filteredOrders.filter((order) =>
        order.invoiceNo.toLowerCase().includes(selectedPlatform)
      );
    }

    setFilteredData(filteredOrders);
    setTotalAmount(calculateTotalAmount(filteredOrders));
  };

  // Clear all filters
  const clearFilters = () => {
    setPaymentMethods([]);
    setDateFilter('');
    setFilteredData(orders)
    setTotalAmount(calculateTotalAmount(orders));
  };

  const unHideRevenue = (credentialResponse) => {
    if (credentialResponse.password === PASSWORD) {
      setProtected(false);
      setShowPasswordDialogue(false);
    } else {
      toast.error("Wrong password");
    }
  };

  return (
    <>
      <PasswordDialog
        isOpen={showPasswordDialogue}
        onClose={() => setShowPasswordDialogue(false)}
        onSubmit={unHideRevenue}
      />
      <div className="bg-gray-50 ">
        <div className="max-w-[1400px] h-[calc(100vh)] w-[100vw] md:w-[calc(100vw-120px)] mx-auto p-4 box-border bg-gray-50 overflow-x-hidden relative hide-scrollbar">
          <div className="bg-white rounded-lg shadow-lg border border-gray-200 h-full overflow-x-scroll hide-scrollbar relative ">
            <div className="p-4 border-b border-gray-200 flex justify-between items-center rounded-t-lg  bg-white flex-wrap gap-2 sticky top-0">
              {loading ? (
                <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-blue-600" />
              ) : (
                <div className="relative">
                  <div className="flex gap-2">
                    <span> Revenue</span>
                    {isProtected ? (
                      <div className="h-8 w-8">
                        <EyeOff
                          className="cursor-pointer hover:stroke-blue-200"
                          onClick={() => setShowPasswordDialogue(true)}
                        />
                      </div>
                    ) : (
                      <div className="h-8 w-8 ">
                        <Eye
                          className="cursor-pointer hover:stroke-blue-200"
                          onClick={() => setProtected(true)}
                        />
                      </div>
                    )}
                  </div>
                  <h2 className="font-semibold text-lg text-gray-900">
                    {isProtected ? "*****" : totalAmount.toLocaleString("en-IN")}
                  </h2>
                  <p className="text-sm text-gray-500 mt-1">
                    {filteredData.length} Orders
                  </p>
                </div>
              )}

              <div className="flex gap-2">
                <div className="relative" ref={filterRef}>
                  <button
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                    className={`inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50  transition-colors ${isFilterOpen ||
                      paymentMethods.length > 0
                      ? "bg-blue-50 text-blue-700 border-blue-300 border-solid"
                      : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                      }`}
                  >
                    <Filter className="h-4 w-4 mr-2" />
                    Filters
                  </button>
                  {isFilterOpen && (
                    <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-10 border border-gray-200">
                      <div className="p-4 space-y-4">
                        {/* Date Range */}
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Date Range
                          </label>
                          <div className="flex gap-2 ">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                                setDateFilter("");
                                sudoFilter({
                                  start: date,
                                });
                              }}
                              className="p-1 border rounded w-[120px]"
                              dateFormat="dd MMM yyyy"
                            />

                            <DatePicker
                              selected={endDate}
                              onChange={(date) => {
                                setEndDate(date);
                                setDateFilter("");
                                sudoFilter({
                                  end: date,
                                });
                              }}
                              className="p-1 border rounded w-[120px]"
                              dateFormat="dd MMM yyyy"
                            />
                          </div>

                          {/* Quick Date Filters */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {[
                              {
                                label: "Today",
                                action: () => filterByDate(formatDate(new Date())),
                              },
                              {
                                label: "Yesterday",
                                action: () =>
                                  filterByDate(
                                    formatDate(
                                      new Date(
                                        new Date().setDate(new Date().getDate() - 1)
                                      )
                                    )
                                  ),
                              },
                              {
                                label: "Last 7 Days",
                                action: () => filterLastNDays(7),
                              },
                              {
                                label: "Last 30 Days",
                                action: () => filterLastNDays(30),
                              },
                            ].map(({ label, action }) => (
                              <button
                                key={label}
                                onClick={() => {
                                  setDateFilter(label);
                                  action();
                                }}
                                className={`px-2 py-1 text-xs
                            ${dateFilter === label
                                    ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                                  }
                            rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors`}
                              >
                                {label}
                              </button>
                            ))}
                          </div>
                        </div>

                        {/* Payment Methods */}
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Payment Method
                          </label>
                          <div className="flex flex-wrap gap-2">
                            {[...allPaymentTypes].map((method) => (
                              <button
                                key={method}
                                onClick={() =>
                                  handlePaymentMethodToggle(method.toLowerCase())
                                }
                                className={`px-3 py-1 text-sm rounded-md transition-colors ${paymentMethods.includes(method.toLowerCase())
                                  ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                                  } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                              >
                                {method === 'na' ? 'canceled' : method}
                              </button>
                            ))}
                          </div>
                        </div>

                        {/* Platform Dropdown */}
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Platform
                          </label>
                          <div className="relative">
                            <button
                              onClick={() =>
                                setIsPlatformDropdownOpen(!isPlatformDropdownOpen)
                              }
                              className="w-full px-3 py-2 text-left bg-white border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors hover:bg-gray-50"
                            >
                              <span className="flex items-center justify-between">
                                {platform === "all" ? "All Platforms" : platform}
                                <ChevronDown className="h-4 w-4 text-gray-400" />
                              </span>
                            </button>

                            {isPlatformDropdownOpen && (
                              <div className="absolute z-20 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
                                {["all", "zomato", "swiggy"].map((p) => (
                                  <button
                                    key={p}
                                    onClick={() => {
                                      setPlatform(p);
                                      setIsPlatformDropdownOpen(false);
                                      sudoFilter({
                                        selectedPlatform: p,
                                      });
                                    }}
                                    className="block w-full px-4 py-2 text-sm text-left hover:bg-gray-50 first:rounded-t-md last:rounded-b-md transition-colors"
                                  >
                                    {p === "all" ? "All Platforms" : p}
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {(paymentMethods.length > 0) && (
                  <button
                    onClick={clearFilters}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                  >
                    <X className="h-4 w-4 mr-2" />
                    Clear
                  </button>
                )}
                <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors">
                  {/*  */}

                  <CSVLink
                    data={[
                      ...(filteredData.length > 0 ? filteredData : orders),
                      {
                        invoiceNo: "",
                        customerName: "",
                        waiter: "",
                        table: "",
                        state: "",
                        orderDate: "",
                        amount: `Total: RS ${totalAmount}`,
                        paymentmethod: "",
                        tips: "",
                      },
                    ]}
                    headers={[
                      { label: "SI", key: "id" },
                      { label: "Invoice No", key: "invoiceNo" },
                      { label: "Customer Name", key: "customerName" },
                      { label: "Waiter", key: "waiter" },
                      { label: "Table", key: "table" },
                      { label: "State", key: "state" },
                      { label: "Order Date", key: "orderDate" },
                      { label: "Amount", key: "amount" },
                      { label: "Method", key: "paymentmethod" },
                      { label: "Tips", key: "tips" },
                    ]}
                    filename={"orders_with_total_amount.csv"}
                    className="flex gap-1"
                    onClick={(e) => {
                      setLoading(true);
                      setTimeout(() => {
                        // feels link loading
                        setLoading(false);
                      }, 1000);
                      return true;
                    }}
                  >
                    <Download className="h-4 w-4 mr-2" />
                    Download Report
                  </CSVLink>
                </button>
              </div>
            </div>
            <div className="overflow-x-scroll">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Invoice No
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Customer
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Waiter
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Table
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Method
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Tips
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Bill
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredData
                    .sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate))
                    .map((order, index) => (
                      <tr className="hover:bg-gray-50 transition-colors">
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate max-w-[6ch] cursor-default"
                          title={order.invoiceNo} // Tooltip for full invoiceNo
                        >
                          {order.invoiceNo}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate max-w-[15ch] cursor-default"
                          title={order.customerName} // Tooltip for full customerName
                        >
                          {order.customerName}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate max-w-[10ch] cursor-default"
                          title={order.waiter} // Tooltip for full waiter
                        >
                          {order.waiter}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-default max-w-[120px] text-wrap"
                          title={order.table} // Tooltip for full table
                        >
                          {order.table}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap cursor-default"
                          title={order.state} // Tooltip for full state
                        >
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${order.canceled
                              ? "bg-red-100 text-red-800"
                              : order.state.toLowerCase() !== "paid"
                                ? "bg-red-100 text-red-800"
                                : "bg-green-100 text-green-800"
                              } `}
                          >
                            {order.canceled ? "Canceled" : order.state}
                          </span>
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-default"
                          title={`Order time : ${new Date(
                            order.orderDate
                          ).toLocaleString()}`} // Tooltip for full orderDate
                        >
                          {dateToString(
                            adjustOrderDate(
                              new Date(order.orderDate),
                              CLOSING_HOURS
                            )
                          )}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-default"
                          title={order.amount} // Tooltip for full amount
                        >
                          <div>{parseInt(order.amount)}</div>
                          {order.paymentmethod === "partial" &&
                            order.partialAmoutsplit?.map((ele, idx) => (
                              <p
                                key={idx}
                                title={`${ele.name}: ${parseInt(ele.amount)}`}
                              >
                                {ele.name}: {parseInt(ele.amount)}
                              </p>
                            ))}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-default"
                          title={order.paymentmethod} // Tooltip for full paymentmethod
                        >
                          {order.paymentmethod}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-default"
                          title={order.tips} // Tooltip for full tips
                        >
                          {order.tips}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <FontAwesomeIcon icon={faEye} className="mr-3" onClick={() => handleBillView(order)} />
                          <FontAwesomeIcon
                            icon={faPrint}
                            className="mr-3"
                            onClick={() => handleBillPrint(order)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* View Bill Modal */}
        {isViewBillOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md  max-h-[80vh] overflow-y-auto relative">

              {viewBillSelectedItem.order.isCancle ? (
                <div className="text-center mb-4">
                  <span class="inline-block px-3 py-1 text-sm font-medium rounded-full bg-red-200 text-gray-800 ">
                    Cancelled
                  </span>
                  <p>Reason : {viewBillSelectedItem.cancelReason}</p>
                </div>
              ) : (
                <span class="inline-block px-3 py-1 text-sm font-medium rounded-full bg-gray-200 text-gray-800 absolute top-[5px] right-[5px]">
                  {viewBillSelectedItem.order.status}
                </span>
              )}

              <h2 className="text-xl mb-4">
                Order Items (Bill No: {viewBillSelectedItem.invoiceNo})
              </h2>

              {/* List of order items */}
              <ul className="mb-4">
                {viewBillSelectedItem?.items &&
                  viewBillSelectedItem?.items.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-3 py-2 border-b border-gray-100 text-gray-800"
                    >
                      <div className="pr-2">
                        {item?.name || "Unknown Item"}
                      </div>
                      <div className="text-center">{item?.quantity || "1"}</div>
                      <div className="text-right">{item?.price || "0.00"}</div>
                    </div>
                  ))}
                {viewBillSelectedItem?.discount >= 0 && (
                  <h3 className="mt-10 text-right">
                    Discount:-{" "}
                    <strong>{viewBillSelectedItem?.discount}</strong>
                  </h3>
                )}
                {viewBillSelectedItem?.tips >= 0 && (
                  <h3 className="mt-2 text-right">
                    Tips:- <strong>{viewBillSelectedItem?.tips}</strong>
                  </h3>
                )}
                <h3 className="mt-2 text-right">
                  Total Bill:-{" "}
                  <strong>{viewBillSelectedItem?.amount}</strong>
                </h3>
              </ul>

              {/* Print button */}

              {/* Close modal */}
              <button
                className="mt-6 w-full bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={() => {
                  setIsViewBillOpen(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
