import store  from "../store";
export const isAuthenticated = () => {
  const token = localStorage.getItem("authToken");
  if (!token) {
    return false;
  }

  const payload = JSON.parse(atob(token.split(".")[1]));
  const expirationTime = payload.exp * 1000;

  if (Date.now() >= expirationTime) {
    localStorage.removeItem("authToken");
    return false;
  }
  return true;
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function adjustOrderDate(orderDate, closingHours) {
  const orderDateObj = new Date(orderDate);
  orderDateObj.setHours(orderDateObj.getHours() - closingHours);
  return orderDateObj;
}

export function getDateDifference(date1, date2) {
  const diffTime = Math.abs(new Date(date2) - new Date(date1)); // Get the absolute difference in milliseconds
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  return diffDays;
}

export function isValidNumber(input, min = -Infinity, max = Infinity) {
  // Convert input to a number if it's a string and check if it's a valid number
  const number = typeof input === "string" ? Number(input) : input;

  // Check if the number is valid and within the specified range
  if (
    typeof number === "number" &&
    !isNaN(number) &&
    isFinite(number) &&
    number >= min &&
    number <= max
  ) {
    return true;
  }

  return false;
}


export function transformDigitalOrderItems({items}) {
  const { items: itemsData } = store
  const itemsMap = itemsData.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})

  return items.map((item) => {
    const { itemId, variationId} = item
    const variantData = itemsMap[parseInt(itemId)].variant.find(variantItem => variantItem.id == variationId) || false;
    const itemData = itemsMap[parseInt(itemId)];

    return {
      itemId: itemData.id,
      id: itemData.id,
      variantId: variantData ? variantData.id : '',
      name: itemData.name,
      variantName: variantData ? variantData.varientName : "",
      price: variantData ? variantData.varientAmount : itemData.amount,
      quantity: item.quantity,
      uniqueId: itemData.id + (variantData ? `_${variantData.id}` : "") + "_online",
      extra: false,
      isVariant: variantData ? true : false,
      variant:  variantData ? variantData.varientName : "",
      createdAt: new Date(),
      readyAt: false
    }    
  })

}