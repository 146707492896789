import React, { useState, useEffect } from 'react';

const Switch = ({ value, onChange, label }) => {
  const [isChecked, setIsChecked] = useState(value);

  // Sync state with the value prop
  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleToggle = (e) => {

    const newCheckedValue = !isChecked
    setIsChecked(newCheckedValue);
    if (onChange) {
      onChange(newCheckedValue);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      {label && <label htmlFor="toggle" className="text-gray-700">{label}</label>}
      <input 
        type="checkbox" 
        id="toggle" 
        checked={isChecked} 
        onChange={handleToggle} 
        className="toggle-checkbox hidden" 
      />
      <div 
        onClick={handleToggle}
        className={`toggle-container w-12 h-6 bg-gray-300 rounded-full flex items-center p-1 cursor-pointer transition-colors duration-200 ${isChecked ? 'bg-green-500' : 'bg-gray-300'}`}
      >
        <div 
          className={`toggle-circle w-4 h-4 bg-white rounded-full transition-transform duration-200 ${isChecked ? 'transform translate-x-6' : ''}`}
        ></div>
      </div>
    </div>
  );
}

export default Switch;
