import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";
const vapidKey = "BB8yTbZBoX62iMFJBd7lUIusK-DvyW_JTTiiMc_cKzEiQDjfISTS_SE-EuGnkX3PVpyuGurOJIMXqd2sBcu0oxQ";

export const requestFcmToken = async () => {
  return Notification.requestPermission()
    .then(async (permission) => {
      if (permission === "granted") {
        console.groupCollapsed("Requesting FCM token");
        console.log("Notification permission granted.");
        console.log("Requesting token...");
        const token  = await getToken(messaging, { vapidKey });
        console.log("Token received:", token);
        console.log("returning token");
        console.groupEnd();
        return token
      } else {
        throw new Error("Notification permission not granted");
      }
    })
    .catch((err) => {
      console.log("error in requestFcmToken");  
      console.error(err);
      console.groupEnd();
      throw err;
    });
};
