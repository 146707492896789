import React, { useState, useEffect } from "react";

const FullscreenComponent = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Function to enter fullscreen mode
  const enterFullscreen = () => {
    const element = document.documentElement; // Fullscreen for the entire document

    if (element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error("Error entering fullscreen:", err);
        });
    } else {
      alert("Fullscreen is not supported on your browser.");
    }
  };

  // Function to exit fullscreen mode
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error("Error exiting fullscreen:", err);
        });
    } else {
      console.log("Document is not in fullscreen mode.");
    }
  };

  // Sync state with fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      const isCurrentlyFullscreen = !!document.fullscreenElement;
      setIsFullscreen(isCurrentlyFullscreen);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <div>
      <button onClick={isFullscreen ? exitFullscreen : enterFullscreen}>
        <i className={`fas ${isFullscreen ? "fa-compress" : "fa-expand"}`} />
      </button>
    </div>
  );
};

export default FullscreenComponent;
