"use client"

import { useState, useEffect } from "react"
import { Clock } from "lucide-react";
import useOrder from "../../hooks/useOrder";
import store from "../../store";
import { observer } from "mobx-react-lite";

const RunningKOTStatus = ({ open, onClose }) => {
  const [orders, setOrders] = useState([])
  const {
    orders: allOrders,
    processOrder,
    getOnlineOrderBillNo,
    cancelOrder,
    rejectOnlineOrder,
    processManualOrder,
    getAllOrders,
    generateOrderBill,
    completeOrderBill,
    flushAllCompleteOrders,
    setKOTItemPreparedStatus
  } = useOrder();

  const fetchExistingOrder = async () => {
    const orders = await getAllOrders();
    setOrders(orders.map(order => {
      const timeDifference = new Date() - new Date(order.createdAt); // Time difference in milliseconds
      const time = Math.floor(timeDifference / (1000 * 60));
      const table = store.tables[order.tableVPA];
      order.printKOT.items = order.printKOT.items.map((item) => {
        if (item.preparedStatus === undefined || item.readyAt == false ) {
          item.preparedStatus = "pending"
          return item
        }
        return item
      })
      return { ...order.printKOT, time, table }
    }))
  };

  useEffect(() => {
    if (open) {
      fetchExistingOrder()
    }

  }, [open])


  const handleItemClick = async (billNo, uniqueId, table) => {
    console.log({ billNo, uniqueId, table })
    const vpa = table.qrpidVPA;
    let status = ''

    const _orders = orders.map((order) =>
      order.billNo === billNo ? {
        ...order,
        items: order.items.map((item) => {
          if (item.uniqueId === uniqueId) {
            status = item.preparedStatus === 'pending' ? 'ready' : 'pending';
            let readyAt =  item.preparedStatus === false ? false : new Date()
            return { ...item, preparedStatus: status, readyAt }
          }
          return item
        }
        ),
      }
        : order
    )

    setOrders(_orders)
    await setKOTItemPreparedStatus({ vpa, billNo, uniqueId, status })
  }

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const target = event.target
      if (open && !target.closest('[data-sidebar="kot-sidebar"]')) {
        onClose()
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [open, onClose])

  // Close sidebar when pressing Escape key
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && open) {
        onClose()
      }
    }

    if (open) {
      document.addEventListener("keydown", handleEscKey)
    }

    return () => {
      document.removeEventListener("keydown", handleEscKey)
    }
  }, [open, onClose])

  return (
    <div className="">
      {/* Overlay */}
      {open && <div className="fixed inset-0 bg-black/20 z-50" onClick={onClose} />}

      {/* Sidebar */}
      <div
        data-sidebar="kot-sidebar"
        className={`fixed top-0 right-0 h-screen w-[80%] md:w-[450px] bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${open ? "translate-x-0" : "translate-x-full"
          } overflow-y-auto hide-scrollbar`}
      >
        {/* Header */}
        <div className="p-4 border-b flex justify-between items-center">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect width="18" height="18" x="3" y="3" rx="2" />
              <path d="M3 9h18" />
              <path d="M9 21V9" />
            </svg>
            <span className="font-medium">Running KOTs</span>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        </div>

        {/* Orders */}
        <div className="p-4 space-y-4">
          {orders.map((order) => (
            <OrderCard key={order.id} order={order} onItemClick={handleItemClick} />
          ))}
        </div>
      </div>
    </div>
  )
}

// Order Card Component
function OrderCard({
  order,
  onItemClick,
}) {
  return (
    <div className="bg-blue-100 p-3 pt-4 rounded-md relative">
       <span className="text-xs absolute top-2 left-3">Room: {order.table.roomName}  Table : {order.table.tableNo}</span>
      <div className="flex justify-between items-center mb-2 mt-1">
        <span className="font-medium">Bill : {order.billNo}</span>
        <div className="flex items-center text-sm text-gray-600">
          <span>{order.time} Mins</span>
        </div>
      </div>
      <div className="space-y-1">
        {order.items.map((item, index) =>{ 
          
          const timeDifference = (item.preparedStatus == "ready" ? new Date(item.readyAt) : new Date()) - new Date(item.createdAt); // Time difference in milliseconds
          const time = Math.floor(timeDifference / (1000 * 60));          
          return  (
          <div
            key={index}
            className={`flex justify-between items-center cursor-pointer hover:bg-blue-200 p-1 rounded ${item.preparedStatus == "ready" ? "bg-green-500 text-white" : ""}`}
            onClick={() => onItemClick(order.billNo, item.uniqueId, order.table)}
          >
            <span className="text-sm">{item.name}</span>
            <div className="flex items-center gap-3">
              <span className="text-sm">Q: {item.quantity}</span>
              <span className="text-sm">{time} Mins</span>
              <div className={`w-3 h-3 rounded-full ${item.preparedStatus == "ready" ? "bg-green-500" : "bg-gray-300"}`}></div>
            </div>
          </div>
        )})}
      </div>
    </div>
  )
}

export default observer(RunningKOTStatus)