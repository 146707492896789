import React, { useEffect, useState, useRef } from "react";
import { SWIGGY, ZOMATO } from "../LocalServicesDyno/LocalApiStore";
import { OrderDetailsModal } from "./OrderDetailsModal";
import swiggyLogo from "../../../img/swiggy_image.jpg";
import zomatoLogo from "../../../img/zomato_logo.png";
import { SwiggyDetailsModel } from "./SwiggyDetailsModel";
import kotIcon from "../../../img/KOT-icon.svg";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import store from "../../../store";

const fetchWithRetry = async (fetchFunction, retries = 3, delay = 1000) => {
  let lastError;
  for (let i = 0; i < retries; i++) {
    try {
      return await fetchFunction();
    } catch (error) {
      lastError = error;
      console.error(`Attempt ${i + 1} failed: ${error.message}`);
      await new Promise((res) => setTimeout(res, delay)); // Delay before retrying
    }
  }
  throw lastError; // Throw error if all retries fail
};

// **Tab Navigation Component**
const TabNavigation = ({ orderDetails = true }) => {
  const containerRef = useRef(null); // Ref for the App component's root element
  const [isFullscreen, setIsFullscreen] = useState(false); // State to track fullscreen status

  // Enter fullscreen
  const enterFullscreen = () => {
    const elem = containerRef.current;
    if (elem.requestFullscreen) {
      elem
        .requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch(console.error);
    } else if (elem.webkitRequestFullscreen) {
      // Safari
      elem.webkitRequestFullscreen();
      setIsFullscreen(true);
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
      setIsFullscreen(true);
    }
  };

  // Exit fullscreen
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setIsFullscreen(false))
          .catch(console.error);
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        setIsFullscreen(false);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        setIsFullscreen(false);
      }
    }
  };



  return (
    <div className="flex items-center gap-2 border-b border-gray-300 p-2 px-6 max-w-[100vw] overflow-x-scroll hide-scrollbar">
      {/* Dine In Tab */}
      <NavLink
        to="/table-overview"
        className={({ isActive }) =>
          `text-sm flex items-center justify-center gap-2 rounded px-2 py-1 min-w-[125px]
              ${isActive
            ? "bg-[#16A34A] border-[0.5px] border-black  text-white"
            : "border-[0.5px] border-black"
          }`
        }
      >
        Dine In
        <span
          style={{ border: "1px solid black" }}
          className="text-xs text-center bg-green-100 text-black rounded-full h-5 w-5"
        >
          {store.runningKOTs?.length}
        </span>
      </NavLink>
      <NavLink
        to="/table-overview"
        onClick={() => {
          store.activeTab = 'Parcel';

        }}
        className={({ isActive }) =>
          `text-sm rounded  flex items-center justify-center gap-2 px-2 py-1 min-w-[125px]
              ${isActive
            ? "bg-[#16A34A] text-white border border-black"
            : "border border-gray-400 text-gray-800"
          }`
        }
      >
        Parcel
      </NavLink>

      {/* Other Delivery Tab */}
      <NavLink
        to="/order-parcel"
        className={({ isActive }) =>
          `text-sm  rounded flex items-center justify-center gap-2 px-2 py-1 min-w-[125px]
              ${isActive
            ? "bg-[#16A34A] text-white border border-black"
            : "border border-gray-400 text-gray-800"
          }`
        }
      >
        Other Delivery
        {/* {orderDetails && (
                <span className="relative">
                  <AiOutlineExclamationCircle
                    className="text-red-500"
                    size={18}
                  />
                  <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                    {1}
                  </span>
                </span>
              )} */}
      </NavLink>
    </div>
  );
};

const OrderListDiital = () => {
  const { login, getCurrentOrders, getOrderDetails, rejectOrder } = ZOMATO();
  const { getOrders } = SWIGGY();
  const [zomatoOrder, setZomatoOrder] = useState([]);
  const [zomatoid, setZomatoId] = useState();
  const [swiggyid, setSwiggyId] = useState();
  const [swiggyOrder, setSwiggyOrder] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        // Try to fetch Zomato orders with retry logic
        const data = await fetchWithRetry(getCurrentOrders);
        setZomatoOrder(data);
        setZomatoId(localStorage.getItem("zomato"));

        // Try to fetch Swiggy orders with retry logic
        const swiggyOrderD = await fetchWithRetry(getOrders);
        setSwiggyOrder(swiggyOrderD.restaurantData[0]?.orders);
        setSwiggyId(localStorage.getItem("swiggy"));

        console.log("Fetched Zomato orders:", data);
        console.log(
          "Fetched Swiggy orders:",
          swiggyOrderD.restaurantData[0]?.orders
        );
      } catch (error) {
        setError(error.message); // Set error if both fetches fail
        console.error("Failed to fetch orders:", error);
      }
    };

    fetchOrder();
  }, []); // Empty dependency array to run only o

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [orderData, setOrderData] = useState(null);
  const [buttonModel, setButton] = useState({});
  const [modelForSwiiggy, setModelForSwiggy] = useState(false);
  const [modelDataForSwiiggy, setmodelDataForSwiiggy] = useState({});

  const handleOpenModal = async (id, buttonsdata, type) => {
    const data = await getOrderDetails(id);
    setOrderData(data);
    setButton(buttonsdata);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseModalSwiggy = () => {
    setModelForSwiggy(false);
  };

  const handleOpenModalSwiggy = async (order, buttonsdata) => {
    console.log(order);
    setModelForSwiggy(true);
    setButton(buttonsdata);
    setmodelDataForSwiiggy(order);
  };
  // Function to calculate time difference
  const calculateTimeDifference = (timestamp) => {
    const updatedTime = new Date(timestamp);
    const currentTime = new Date();
    const diffInSeconds = Math.floor((currentTime - updatedTime) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);

    if (diffInMinutes < 1) {
      return "Just Now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute(s) ago`;
    } else {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour(s) ago`;
    }
  };

  return (
    <>
      {" "}
      {/* **Tab Navigation** */}
      <TabNavigation />
      <div className="p-6">
        {orderData && (
          <OrderDetailsModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            data={orderData}
            btns={buttonModel}
            zomatoid={zomatoid}
          />
        )}
        {modelForSwiiggy && (
          <SwiggyDetailsModel
            isOpen={modelForSwiiggy}
            onClose={handleCloseModalSwiggy}
            data={modelDataForSwiiggy}
            btns={buttonModel}
            swiggyId={swiggyid}
          />
        )}
        <h3 className="mb-5">Current Order</h3>

        {zomatoOrder[0] &&
          zomatoOrder[0].new_orders?.entities.map((ele, index) => (
            <div
              key={index}
              className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
            >
              {/* Zomato Logo */}
              <div className="flex items-center space-x-4">
                <img
                  src={zomatoLogo}
                  alt="Zomato Logo"
                  className="w-20 h-auto"
                />
                {/* Order Details */}
                <div className="flex flex-col">
                  <span className="text-lg font-medium">
                    Order ID: {ele.tab_id} -{" "}
                    <strong>{String(ele.tab_id)?.slice(-4)}</strong>
                  </span>
                  <span className="text-red-500 text-sm">
                    Last Updated:{" "}
                    {calculateTimeDifference(ele.updated_timestamp)}
                  </span>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    handleOpenModal(ele.tab_id, {});
                  }}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  View
                </button>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    handleOpenModal(ele.tab_id, {
                      accept: true,
                      reject: true,
                      printbill: true,
                    });
                  }}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Accept
                </button>
                <button
                  onClick={() => {
                    const sure = prompt(
                      "Are you sure order Rejecting ID",
                      ele.tab_id
                    );
                    if (sure != null) {
                      rejectOrder({
                        restaurant_id: zomatoid,
                        order_id: ele.tab_id,
                      });
                    }
                  }}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Reject
                </button>
              </div>
            </div>
          ))}

        {swiggyOrder &&
          swiggyOrder.map((order, index) => {
            if (order.status.placed_status == "unplaced") {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
                >
                  {/* Zomato Logo */}
                  <div className="flex items-center space-x-4">
                    <img
                      src={swiggyLogo}
                      alt="Zomato Logo"
                      className="w-20 h-auto"
                    />
                    {/* Order Details */}
                    <div className="flex flex-col">
                      <span className="text-lg font-medium">
                        Order ID: {order.order_id} -{" "}
                        <strong>{String(order.order_id).slice(-4)}</strong>
                      </span>
                      <span className="text-red-500 text-sm">
                        Last Updated:{" "}
                        {new Date(order.last_updated_time).toLocaleString()}
                      </span>
                      <span className="text-sm">
                        Customer: {order.customer.customer_name} (
                        {order.customer.customer_area})
                      </span>
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        handleOpenModalSwiggy(order, {
                          accept: true,
                          reject: true,
                          printbill: true,
                        });
                      }}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                      View
                    </button>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        handleOpenModalSwiggy(order, {
                          accept: true,
                          reject: true,
                          printbill: true,
                        });
                      }}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                      Accept
                    </button>
                    <button
                      disabled={true}
                      className="px-4 py-2 bg-red-500 text-white rounded opacity-50 cursor-not-allowed"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              );
            }
          })}

        {/* Preparing Orders */}
        <div>
          <h3 className="mb-5">Preparing Orders</h3>
          {zomatoOrder[1] &&
            zomatoOrder[1].preparing_orders?.entities.map((ele, index) => (
              <div
                key={index}
                className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
              >
                {/* Zomato Logo */}
                <div className="flex items-center space-x-4">
                  <img
                    src={zomatoLogo}
                    alt="Zomato Logo"
                    className="w-20 h-auto"
                  />
                  {/* Order Details */}
                  <div className="flex flex-col">
                    <span className="text-lg font-medium">
                      Order ID: {String(ele.tab_id)} -{" "}
                      <strong>{String(ele.tab_id)?.slice(-4)}</strong>
                    </span>
                    <span className="text-red-500 text-sm">
                      Last Updated:{" "}
                      {calculateTimeDifference(ele.updated_timestamp)}
                    </span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      handleOpenModal(ele.tab_id, {});
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    View
                  </button>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      handleOpenModal(ele.tab_id, {
                        printkot: true,
                        ready: true,
                        printbill: true,
                      });
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    Ready
                  </button>
                  <button
                    onClick={() => {
                      const sure = prompt(
                        "Are you sure order Rejecting ID",
                        ele.tab_id
                      );
                      if (sure != null) {
                        rejectOrder({
                          restaurant_id: zomatoid,
                          order_id: ele.tab_id,
                        });
                      }
                    }}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))}

          {swiggyOrder &&
            swiggyOrder.map((order, index) => {
              if (
                order.status.placed_status == "placed" &&
                order.status.order_status == "ordered" &&
                order.status.delivery_status == "arrived"
              ) {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
                  >
                    {/* Zomato Logo */}
                    <div className="flex items-center space-x-4">
                      <img
                        src={swiggyLogo}
                        alt="Zomato Logo"
                        className="w-20 h-auto"
                      />
                      {/* Order Details */}
                      <div className="flex flex-col">
                        <span className="text-lg font-medium">
                          Order ID: {order.order_id} -{" "}
                          <strong>{String(order.order_id).slice(-4)}</strong>
                        </span>
                        <span className="text-red-500 text-sm">
                          Last Updated:{" "}
                          {new Date(order.last_updated_time).toLocaleString()}
                        </span>
                        <span className="text-sm">
                          Customer: {order.customer.customer_name} (
                          {order.customer.customer_area})
                        </span>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex space-x-2">
                      <button
                        onClick={() => {
                          handleOpenModalSwiggy(order, {
                            printkot: true,

                            printbill: true,
                          });
                        }}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        View
                      </button>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => {
                          handleOpenModalSwiggy(order, {
                            printkot: true,
                            ready: true,
                            printbill: true,
                          });
                        }}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        Ready
                      </button>
                      <button
                        disabled={true}
                        className="px-4 py-2 bg-red-500 text-white rounded opacity-50 cursor-not-allowed"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                );
              }
            })}
        </div>

        {/* Ready Orders */}
        <div>
          <h3 className="mb-5">Ready Orders</h3>
          {zomatoOrder[2] &&
            zomatoOrder[2].ready_orders?.entities.map((ele, index) => (
              <div
                key={index}
                className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
              >
                {/* Zomato Logo */}
                <div className="flex items-center space-x-4">
                  <img
                    src={zomatoLogo}
                    alt="Zomato Logo"
                    className="w-20 h-auto"
                  />
                  {/* Order Details */}
                  <div className="flex flex-col">
                    <span className="text-lg font-medium">
                      Order ID: {ele.tab_id} -{" "}
                      <strong>{String(ele.tab_id)?.slice(-4)}</strong>
                    </span>
                    <span className="text-red-500 text-sm">
                      Last Updated:{" "}
                      {calculateTimeDifference(ele.updated_timestamp)}
                    </span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      handleOpenModal(ele.tab_id, {
                        printkot: true,
                        printbill: true,
                      });
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          {swiggyOrder &&
            swiggyOrder.map((order, index) => {
              if (
                order.status.placed_status == "placed" &&
                order.status.order_status == "ordered" &&
                order.status.delivery_status == "pickedup"
              ) {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
                  >
                    {/* Zomato Logo */}
                    <div className="flex items-center space-x-4">
                      <img
                        src={swiggyLogo}
                        alt="Zomato Logo"
                        className="w-20 h-auto"
                      />
                      {/* Order Details */}
                      <div className="flex flex-col">
                        <span className="text-lg font-medium">
                          Order ID: {order.order_id} -{" "}
                          <strong>{String(order.order_id).slice(-4)}</strong>
                        </span>
                        <span className="text-red-500 text-sm">
                          Last Updated:{" "}
                          {new Date(order.last_updated_time).toLocaleString()}
                        </span>
                        <span className="text-sm">
                          Customer: {order.customer.customer_name} (
                          {order.customer.customer_area})
                        </span>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex space-x-2">
                      <button
                        onClick={() => {
                          handleOpenModalSwiggy(order, {
                            printbill: true,
                          });
                        }}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        View
                      </button>
                    </div>
                  </div>
                );
              }
            })}
        </div>

        {/* Dispatched Orders */}
        <div>
          <h3 className="mb-5">Dispatched Orders</h3>
          {zomatoOrder[3] &&
            zomatoOrder[3].dispatched_orders?.entities.map((ele, index) => (
              <div
                key={index}
                className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
              >
                {/* Zomato Logo */}
                <div className="flex items-center space-x-4">
                  <img
                    src={zomatoLogo}
                    alt="Zomato Logo"
                    className="w-20 h-auto"
                  />
                  {/* Order Details */}
                  <div className="flex flex-col">
                    <span className="text-lg font-medium">
                      Order ID: {ele.tab_id} -{" "}
                      <strong>{String(ele.tab_id)?.slice(-4)}</strong>
                    </span>
                    <span className="text-red-500 text-sm">
                      Last Updated:{" "}
                      {calculateTimeDifference(ele.updated_timestamp)}
                    </span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      handleOpenModal(ele.tab_id, {
                        printkot: true,
                        printbill: true,
                      });
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}

          {swiggyOrder &&
            swiggyOrder.map((order, index) => {
              if (
                order.status.placed_status == "placed" &&
                order.status.order_status == "delivered" &&
                order.status.delivery_status == "delivered"
              ) {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
                  >
                    {/* Zomato Logo */}
                    <div className="flex items-center space-x-4">
                      <img
                        src={swiggyLogo}
                        alt="Zomato Logo"
                        className="w-20 h-auto"
                      />
                      {/* Order Details */}
                      <div className="flex flex-col">
                        <span className="text-lg font-medium">
                          Order ID: {order.order_id} -{" "}
                          <strong>{String(order.order_id).slice(-4)}</strong>
                        </span>
                        <span className="text-red-500 text-sm">
                          Last Updated:{" "}
                          {new Date(order.last_updated_time).toLocaleString()}
                        </span>
                        <span className="text-sm">
                          Customer: {order.customer.customer_name} (
                          {order.customer.customer_area})
                        </span>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex space-x-2">
                      <button
                        onClick={() => {
                          handleOpenModalSwiggy(order, {
                            printbill: true,
                          });
                        }}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        View
                      </button>
                    </div>
                  </div>
                );
              }
            })}
        </div>

        {/* Returned Orders */}
        <div>
          <h3 className="mb-5">Returned Orders</h3>
          {zomatoOrder[4] &&
            zomatoOrder[4].returned_orders?.entities.map((ele, index) => (
              <div
                key={index}
                className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
              >
                {/* Zomato Logo */}
                <div className="flex items-center space-x-4">
                  <img
                    src={zomatoLogo}
                    alt="Zomato Logo"
                    className="w-20 h-auto"
                  />
                  {/* Order Details */}
                  <div className="flex flex-col">
                    <span className="text-lg font-medium">
                      Order ID: {ele.tab_id} -{" "}
                      <strong>{String(ele.tab_id)?.slice(-4)}</strong>
                    </span>
                    <span className="text-red-500 text-sm">
                      Last Updated:{" "}
                      {calculateTimeDifference(ele.updated_timestamp)}
                    </span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      handleOpenModal(ele.tab_id, {
                        printkot: true,
                        printbill: true,
                      });
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}

          {swiggyOrder &&
            swiggyOrder.map((order, index) => {
              if (order.status.placed_status == "cancelled") {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 shadow-sm"
                  >
                    {/* Zomato Logo */}
                    <div className="flex items-center space-x-4">
                      <img
                        src={swiggyLogo}
                        alt="Zomato Logo"
                        className="w-20 h-auto"
                      />
                      {/* Order Details */}
                      <div className="flex flex-col">
                        <span className="text-lg font-medium">
                          Order ID: {order.order_id} -{" "}
                          <strong>{String(order.order_id).slice(-4)}</strong>
                        </span>
                        <span className="text-red-500 text-sm">
                          Last Updated:{" "}
                          {new Date(order.last_updated_time).toLocaleString()}
                        </span>
                        <span className="text-sm">
                          Customer: {order.customer.customer_name} (
                          {order.customer.customer_area})
                        </span>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex space-x-2">
                      <button
                        onClick={() => {
                          handleOpenModalSwiggy(order, {
                            printbill: true,
                          });
                        }}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        View
                      </button>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

export default OrderListDiital;
