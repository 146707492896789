
export const PrintBills = (
  cartItems,
  hotelData,
  roomData,
  billNo,
  billDetails,
  customer
) => {
  // Open a new window for printing
  // console.log(greenItems);

  const WinPrint = window.open("", "", "width=auto,height=auto");
  const date = new Date();
  const day = date.getDate(); // Day of the month (1-31)
  const month = date.getMonth() + 1; // Month (0-11, so we add 1 to get 1-12)
  const year = date.getFullYear();
  const time = date.toLocaleTimeString();

  // const discounts = totalBillAmount-subTotal;
  // console.log(greenItems);

  // Construct the HTML string for the bill


  let customerDetailsTemplate = ''
  if(roomData.room === 'Parcel')
  {
    customerDetailsTemplate = `
        ${ customer.name != 'NA' &&  customer.name != ''  ? '<hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />' : ''}
        <div class="customer-info" style="font-size: 0.8rem;">
        ${ (customer.name != 'NA' &&  customer.name != '')   ? `<div><span id="customer-name"><strong>Customer:</strong> ${customer.name} </span></div>` : ''}
        ${( customer.phone != 0 &&  customer.phone != 'NA')  ? `<div><span class="info-label">Ph:</span> <span id="customer-phone">${customer.phone}</span></div>` : ''}
        ${ (customer.address != 'NA' &&  customer.address != '')  ? `<div><span class="info-label">Add:</span> <span id="customer-address">${customer.address}</span></div>` : ''}
        </div>
    `
  }else {
    customerDetailsTemplate = `
    ${ customer.name != 'NA' &&  customer.name != ''  ? '<hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />' : ''}
    <div class="customer-info" style="font-size: 0.8rem;">
    ${ (customer.name != 'NA' &&  customer.name != '')   ? `<div><span id="customer-name"><strong>Customer:</strong> ${customer.name} </span></div>` : ''}
    </div>
    `
  }




  const billHtml = `
      <div style="padding: 0; margin:0; background-color: white; font-family: Arial, sans-serif; width: 100%;">
        <h2 style="text-align: center; font-size: 1rem; font-weight: bold; margin-bottom: 1rem;">${
          hotelData.name
        }</h2>
        <p style="text-align: center; font-size: 0.7rem; line-height: 1rem;">${
          hotelData.address
        }</p>
        <p style="text-align: center; font-size: 0.7rem; line-height: 0.5rem;"> ${hotelData.adminContact} </p>
        ${
          billDetails.gst
            ? `<p style="font-size: 0.7rem ; margin:0px; padding:0px;">
         GSTN: ${hotelData.gstn}
        </p>`
            : ``
        }     
        ${
          hotelData.fassi_no !== ''
            ? `<p style="font-size: 0.7rem; line-height: 0.5rem; padding:0px: margin:0px; margin-bottom:5px">
         FSSAI: ${hotelData.fassi_no }
        </p>`
            : ``
        }
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="font-size: 0.7rem; margin: 0.3rem 0;"><strong>Date:</strong> ${`${day} / ${month} / ${year} : ${time}`} </p>
        <p style="font-size: 0.9rem; margin: 0.5rem 0;"><strong>Table:</strong>  ${
          roomData.table
        }<strong>      Bill No: </strong> ${billNo}</p>

        ${
          customerDetailsTemplate
        }
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <div>
          <table style="width: 100%; font-size: 0.7rem; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left;">Item</th>
                <th style="text-align: right;">Qty.</th>
                <th style="text-align: right;">Price</th>
                <th style="text-align: right;">Amount</th>
                
              </tr>
            </thead>
            <tbody>
              ${cartItems
                .map(
                  (item) =>
                    `
                      <tr>
                        <td style="padding: 0.25rem 0;"><strong>${  item.name } 
                          ${ item.isVariant ? "- " + item.variant : '' }
                          </strong>
                        </td>
                        <td style="text-align: right;">${item.quantity}</td>
                        <td style="text-align: right;">${item.price}</td>
                        <td style="text-align: right;">${
                          parseInt(item.quantity) * parseInt(item.price)
                        }</td>
                      </tr>
                    `
                )
                .join("")}
            </tbody>
          </table>
        </div>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: right; font-size: 0.8rem; margin: 0.5rem 0;"><strong>Sub Total:</strong> ₹${
          billDetails.subTotal
        }.00</p>
        ${
        billDetails.discountFlat > 0
           ? ` <p style="text-align: right; font-size: 0.8rem; margin: 0.5rem 0;"><strong>Flat discount: </strong> ₹${billDetails.discountFlat} </p>`
           : ""
       } 
       ${
        billDetails.discountPercent > 0
           ? ` <p style="text-align: right; font-size: 0.8rem; margin: 0.5rem 0;"><strong>Discount: </strong> ${billDetails.discountPercent}%</p>`
           : ""
       } 
        ${
          billDetails.gst
            ? ` <p style="text-align: right; font-size: 0.5rem; margin: 0.5rem 0;"><strong>CGST ${billDetails.cgstPercent}%:</strong> ₹${billDetails.cgst}</p>
        <p style="text-align: right; font-size: 0.5rem; margin: 0.4rem 0;"><strong>SGST ${billDetails.sgstPercent}%:</strong> ₹${billDetails.sgst}</p>
`
            : ``
        }
        <p style="text-align: right; font-size: 1.1rem; margin: 0.5rem 0;"><strong>Grand Total: ₹${
          billDetails.total
        }</strong></p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
${
  billDetails.gst && !billDetails.gstExcluded
    ? `    <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">GST Already Included in Item Price </p>`
    : ``
}
        <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">Powered by QRapid</p>
      </div>
    `;

  // Write the HTML to the new window
  WinPrint.document.write(billHtml);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
};
