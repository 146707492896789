import React, { useEffect, useRef, useState } from "react";
import AcceptOrder from "./AcceptOrder/AcceptOrder.main";
import {
  GetTableData,
  RequestedKOTData,
  TableData,
} from "./services/table.Services";
import { FaEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { playRing } from "./PlaySound";
import kotIcon from "../img/KOT-icon.svg";
import previewIcon from "../img/preview.svg";
import transferIcon from "../img/arrow-left-right.svg";
import mergeIcon from "../img/arrows-collapse.svg";
import clockIcon from "../img/clock.svg";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";

import store from "../store/index";
import { observer } from "mobx-react-lite";
import useOrder from "../hooks/useOrder";
import { useCloud } from "../hooks/useCloud";
import Switchtable from "./Switchtable";
import RunningKOTStatus from "./RunningKOTStatus/index"

const TableCard = ({
  table,
  handleOpenPrompt,
  ordertables,
  printedKOT,
  printedKOTAll,
  room,
}) => {

  const [tableStatus, setTableStatus] = useState("VACANT");
  const [elapsedTime, setElapsedTime] = useState("");
  const [orderAmount, setOrderAmount] = useState(0);
  const [customerName, setCustomerName] = useState('')
  const [isOrderRunning, setIsOrderRunning] = useState(false)
  const [update, setUpdate] = useState(0)
  const fetchTableStatus = async () => {
    const order = store.runningVPA[table.qrpidVPA];
    if (order) {
      const now = new Date();
      const diffMs = now - new Date(order.createdAt);
      const diffMinutes = Math.floor(diffMs / 60000);
      const diffHours = Math.floor(diffMinutes / 60);
      const remainingMinutes = diffMinutes % 60;
      const timeString =
        diffHours > 0
          ? `${diffHours}h ${remainingMinutes}m`
          : `${diffMinutes}m`;

      setElapsedTime(timeString);
      setTableStatus(order.status);
      setOrderAmount(order.amount);
      let customerName = order.username === 'NA' ? '' : order.username.split(' ')[0]
      setCustomerName(customerName);
      setIsOrderRunning(true)
    } else {
      setElapsedTime(0);
      setTableStatus('VACANT');
      setOrderAmount(0);
      setCustomerName('');
      setIsOrderRunning(false)
    }
    setUpdate(prev => prev + 1)
  };
  useEffect(() => {
    fetchTableStatus();
  }, [
    store.runningVPA
  ]);



  return (
    <div
      onClick={() => handleOpenPrompt(table.tableNo, table.qrpidVPA, room)}
      key={table.tableNo + update}
      className={`w-[120px] min-h-24 cursor-pointer rounded-lg p-2 flex flex-col gap-2 justify-between relative
      ${tableStatus === "VACANT" && "bg-gray-300"}
      ${tableStatus === "KOTBillGenerated" && "bg-purple-400 text-white"}
      ${tableStatus === "KOTPrint" && "bg-green-500 text-white"}
      ${tableStatus === "KOTUpdated" && "bg-green-500 text-white"}
      ${tableStatus === "RequestKOT" && "bg-blue-500  text-white"}`}
    >
      <div className="flex justify-between items-center text-md font-bold">
        {room != "Parcel" ? (
          <span className="text-lg">T {table.tableNo}</span>
        ) : (
          <span className="text-lg">P {table.tableNo}</span>
        )}
        {
          tableStatus != 'VACANT' && (<div className="flex items-center gap-1 text-sm">
            <img src={clockIcon} alt="Clock Icon" />
            {elapsedTime || "0m"}
          </div>)
        }

      </div>
      {isOrderRunning && (
        <div className="text-sm font-normal">
          <>
            <p className="font-bold">₹{" "}
              {parseFloat(orderAmount).toFixed(2)} </p>
            <p>
              {
                customerName
              }
            </p>

            {room != "Parcel" && (
              <button className="absolute bottom-0 right-0 pl-1 pt-1 bg-white rounded-tl-lg rounded-none rounded-br-none rounded-bl-none flex items-center justify-center">
                <span className="bg-[#F3F9FF] p-2 rounded-md">
                  <img src={previewIcon} alt="Preview Icon" />
                </span>
              </button>
            )}
          </>
        </div>
      )}
    </div>
  );
};

const Legend = ({
  isPopoverOpen,
  togglePopover,
  resData,
  handleClosePromptBYSwitch,
}) => (
  <>
    <div className="fixed bottom-0 md:bottom-5 left-0 sm:right-0 md:left-1/2 md:transform md:-translate-x-1/2 border-t sm:border-t-0 z-40 hidden md:block" style={{ zIndex: 40 }}>
      <div className="flex items-center md:flex-nowrap justify-center shadow p-2 bg-white md:rounded-full w-full gap-4 flex-wrap text-sm ">
        <span className="inline-flex items-center gap-2">
          <span className="w-2 h-2 bg-[#E1E1E1] inline-block rounded-full"></span>{" "}
          Blank Table
        </span>

        <span className="inline-flex items-center gap-2">
          <span className="w-2 h-2 bg-blue-500 inline-block rounded-full"></span>{" "}
          Customer Request
        </span>

        <span className="inline-flex items-center gap-2">
          <span className="w-2 h-2 bg-green-500 inline-block rounded-full"></span>{" "}
          KOT Printed
        </span>

        <span className="inline-flex items-center gap-2">
          <span className="w-2 h-2 bg-purple-400 inline-block rounded-full"></span>{" "}
          Bill Printed
        </span>
      </div>
      {/* <div>
        <button
          className="p-1 md:py-2 md:px-4 text-xs md:text-sm rounded-md bg-gray-800 text-white"
          onClick={togglePopover}
        >
          Switch Table
        </button>
      </div> */}
    </div>
    {isPopoverOpen && (
      <Switchtable
        close={() => {
          togglePopover();
        }}
        table={resData}
        handleClosePromptBYSwitch={handleClosePromptBYSwitch}
      />
    )}
  </>
);

const Rooms = ({
  activeRoom,
  handleOpenPrompt,
  ordertables,
  printedKOT,
  printedKOTAll,
  activeTab
}) =>
  activeRoom &&
  activeRoom?.filter(room => {
    if (activeTab === 'Parcel' && room.roomName == 'Parcel') {
      return true
    } else if (activeTab === 'DineIn' && room.roomName != 'Parcel') {
      return true
    } else {
      return false
    }

  }).map((eachroom) => (
    <TableList
      tablesroom={eachroom}
      handleOpenPrompt={handleOpenPrompt}
      ordertables={ordertables}
      printedKOT={printedKOT}
      printedKOTAll={printedKOTAll}
      activeTab={activeTab}
    />
  ));

const TableList = ({
  tablesroom,
  handleOpenPrompt,
  ordertables,
  printedKOT,
  printedKOTAll,
  activeTab
}) => (
  <div className="w-full mb-8" key={tablesroom?.roomName}>
    <div className="mb-1">
      <div className="p-1 border-[0.92px] border-[#16A34A] text-[#16A34A] font-semibold rounded text-center mb-2 inline-block" style={{ minWidth: "150px" }}>
        {tablesroom?.roomName}
      </div>
      <div className="flex-1"></div>
      {/* Parcel Label */}
    </div>
    <div className="flex flex-wrap gap-4">
      {tablesroom &&
        tablesroom?.tableData.map((table) => (
          <TableCard
            key={table.tableNo}
            table={table}
            handleOpenPrompt={handleOpenPrompt}
            ordertables={ordertables}
            printedKOT={printedKOT}
            printedKOTAll={printedKOTAll}
            room={tablesroom.roomName}
          />
        ))}
    </div>
  </div>
);

const TabNavigation = ({ orderDetails = true, setShowRunningKOT, activeTab, setActiveTab }) => {
  const containerRef = useRef(null); // Ref for the App component's root element
  const [isFullscreen, setIsFullscreen] = useState(false); // State to track fullscreen status

  // Enter fullscreen
  const enterFullscreen = () => {
    const elem = containerRef.current;
    if (elem.requestFullscreen) {
      elem
        .requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch(console.error);
    } else if (elem.webkitRequestFullscreen) {
      // Safari
      elem.webkitRequestFullscreen();
      setIsFullscreen(true);
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
      setIsFullscreen(true);
    }
  };

  // Exit fullscreen
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setIsFullscreen(false))
          .catch(console.error);
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        setIsFullscreen(false);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  return (

    <div className="flex items-center gap-2 border-b border-gray-300 p-2 px-6 max-w-[100vw] overflow-x-scroll hide-scrollbar">
      {/* Dine In Tab */}
      <NavLink
        to="/table-overview"
        onClick={() => setActiveTab('DineIn')}
        className={({ isActive }) =>
          `text-sm flex items-center justify-center gap-2 px-2 py-1 min-w-[125px]
              ${activeTab === 'DineIn'
            ? "bg-[#16A34A] border-[0.5px] border-black rounded text-white"
            : "border-[0.5px] border-black rounded"
          }`
        }
      >
        Dine In
        <span
          style={{ border: "1px solid black" }}
          className="text-xs text-center bg-green-100 text-black rounded-full h-5 w-5"
        >
          {store.runningKOTs?.length}
        </span>
      </NavLink>


      <button
        onClick={() => {
          setActiveTab('Parcel')
        }}
        className={`text-sm px-2 py-1 border-[0.5px] border-black rounded flex items-center justify-center gap-2 block min-w-[125px] ${activeTab === 'Parcel' ? 'bg-[#16A34A] border-[0.5px] border-black rounded text-white' : ''}`}
      >
        Parcel
      </button>

      {/* Other Delivery Tab */}
      <NavLink
        onClick={() => setActiveTab('Other')}
        to="/order-parcel"
        className={({ isActive }) =>
          `text-sm flex px-2 py-1 items-center justify-center gap-2 rounded min-w-[125px]
                ${activeTab === 'Other'
            ? "bg-[#16A34A] text-white border border-black"
            : "border border-gray-400 text-gray-800"
          }`
        }
      >
        Other Delivery
      </NavLink>
      
      <div className="flex-1"></div>
      <button
        onClick={() => store.setShowRunningKOT(true)}
        className="text-sm px-2 py-1 border-[0.5px] border-black rounded flex items-center justify-center gap-2 block min-w-[135px] max-w-[135px]"
      >
        <img src={kotIcon} alt="Running KOTs" />
        Running KOTs
      </button>

    </div>
  );
};

const TableOverview = () => {
  const { getAllCompletedOrders, flushAllCompleteOrders, getAllOrders } = useOrder();
  const { syncCloud } = useCloud();
  const [activeRoom, setActiveRoom] = useState([]);
  const [tablesroom, setTablesRoom] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [clickedTable, setClickedTable] = useState(null);
  const [ordertables, setOrdersTable] = useState([]);
  const [printedKOT, setPrintedKOT] = useState([]);
  const [printedKOTAll, setPrintedKOTAll] = useState([]);
  const [address, setAddress] = useState();
  const [selectedPrintedKOTAll, setselectedPrintedKOTAll] = useState([]);
  const [requestKOTAll, setrequestKOTAll] = useState([]);
  const [selectedrequestKOTAll, setselectedrequestKOTAll] = useState([]);
  const [tableStatuses, setTableStatuses] = useState({});
  const [roomsData, setRoomsData] = useState([]);
  const [activeTab, setActiveTab] = useState("DineIn");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [cloudSync, setCloudSync] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // FOR FORCEFULL RE-RENDER
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showRunningKOT, setShowRunningKOT] = useState(false)

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleOpenPrompt = async (id, qrpidVPA, room) => {
    console.log(room);

    setClickedTable({ id, qrpidVPA, room });
    const totalPr = printedKOTAll?.reduce((acc, ele) => {
      if (ele.tableVPA === qrpidVPA) {
        acc.push(ele);
      }
      return acc;
    }, []);
    setselectedPrintedKOTAll(totalPr);

    const kot = requestKOTAll?.reduce((acc, ele) => {
      if (ele.tableVPA === qrpidVPA) {
        acc.push(ele);
      }
      return acc;
    }, []);
    setselectedrequestKOTAll(kot);
    setShowPrompt(true);
  };

  const handleClosePrompt = () => {
    // setShowPrompt(true);
    setActiveTab("parcel");
    // navigate("/table-overview");
    navigate("/order-parcel");
    // const rooms = store.rooms;
    setTimeout(() => {
      setActiveTab("dine-in");
      navigate("/table-overview");
    }, 10); // 1-second delay

    //   setActiveTab("parcel")
    //   // navigate("/table-overview");
    //   navigate("/order-parcel");
    //   setTimeout(() => {
    //     setActiveTab("dine-in");
    //     navigate("/table-overview");
    // }, 100); // 1-second delay

    // setTablesRoom(rooms);

    setShowPrompt(false);
  };

  const handleClosePromptBYSwitch = () => {
    // setShowPrompt(true);
    navigate("/report");
    setTimeout(() => {
      setActiveTab("parcel");
      // navigate("/table-overview");
      navigate("/order-parcel");
    }, 10);

    // const rooms = store.rooms;
    setTimeout(() => {
      setActiveTab("dine-in");
      navigate("/table-overview");
    }, 100); // 1-second delay

    //   setActiveTab("parcel")
    //   // navigate("/table-overview");
    setShowPrompt(false);
  };

  useEffect(() => {
    if (store.activeTab) {
      setActiveTab(store.activeTab)
    }
  }, [])

  useEffect(() => {
    const fetchAllOrders = async () => {
      console.log("called")
      const orders = await getAllOrders()
      store.runningVPA = orders.reduce((acc, order) => {
        acc[order.tableVPA] = order
        return acc
      }, {})
      console.log(store.runningVPA)
    }

    setTimeout(() => fetchAllOrders(), 100)
    window.addEventListener('ORDER_DONE', fetchAllOrders)
    window.addEventListener("KOTUpdated", fetchAllOrders);
    window.addEventListener("digitalOrderProcessed", fetchAllOrders);

    return () => {
      window.removeEventListener('ORDER_DONE', fetchAllOrders)
      window.removeEventListener("KOTUpdated", fetchAllOrders);
      window.removeEventListener("digitalOrderProcessed", fetchAllOrders)
    }
  }, [])

  useEffect(() => {
    store.activeTab = activeTab
  }, [activeTab])

  useEffect(() => {
    setAddress({
      name: localStorage.getItem("name"),
      address: localStorage.getItem("address"),
    });
  }, [store.reload]);

  useEffect(() => {
    const rooms = store.rooms;
    if (rooms && rooms.length > 0) {
      setRoomsData(rooms);
      setActiveRoom(rooms);
      setTablesRoom(rooms);
    }
  }, [store.rooms.length, store.reload]);

  useEffect(() => {
    const printedKOTs = store.printedKOTs;
    if (printedKOTs && printedKOTs.length > 0) {
      setPrintedKOTAll(printedKOTs);
    } else {
      setPrintedKOTAll([]);
    }

    // update printed bill
    if (selectedPrintedKOTAll.length > 0) {
      const tableVPA = selectedPrintedKOTAll[0].tableVPA;
      const totalPr = printedKOTAll?.reduce((acc, ele) => {
        if (ele.tableVPA === tableVPA) {
          acc.push(ele);
        }
        return acc;
      }, []);
      setselectedPrintedKOTAll(totalPr);
    }
  }, [store.printedKOTs.length, store.totalBillAmount, store.reload]);

  useEffect(() => {
    const printedKOT_VPA = store.printedKOT_VPA;
    if (printedKOT_VPA && printedKOT_VPA.length > 0) {
      const printedKOT = printedKOT_VPA.map((ele) => ele.tableVPA);
      setPrintedKOT(printedKOT);
    } else {
      setPrintedKOT([]);
    }
  }, [store.printedKOT_VPA.length, store.reload]);

  useEffect(() => {
    setTimeout(() => {
      console.log(navigator.onLine);
      setIsOnline(navigator.onLine);
    }, 1000);
  }, [navigator.onLine]);

  useEffect(() => {
    setOrdersTable(store.digitalOrderVPAs || []);
  }, [store.digitalOrderVPAs?.length, showPrompt, store.reload]);


  // useEffect(() => {
  //   const fetchTables = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       const requestKOT = await RequestedKOTData(token);
  //       setrequestKOTAll(requestKOT.data.requestKOTAmoutntItem);

  //       const setRequestKOT = [];
  //       requestKOT.data.requestKot.forEach((ele) => {
  //         setRequestKOT.push(ele.tableVPA);
  //       });
  //       setOrdersTable(setRequestKOT);

  //       const datas = await GetTableData(token);
  //       setPrintedKOTAll(datas.data.kotPrintedItem);

  //       const printedKOT = [];
  //       datas.data.vpaKotPrinted.forEach((ele) => {
  //         printedKOT.push(ele.tableVPA);
  //       });
  //       setPrintedKOT(printedKOT);

  //       setAddress({
  //         name: localStorage.getItem("name"),
  //         address: localStorage.getItem("address"),
  //       });
  //       const fetch = async () => {
  //         const key = await requestFcmToken();
  //         console.log(key, "...............");
  //       };
  //       fetch();
  //     } catch (error) {
  //       console.error("Failed to fetch tables", error);
  //     }
  //   };

  //   fetchTables();
  // }, []);

  // const handleRoomChange = (room) => {
  //   setActiveRoom(room.roomName);
  //   setTablesRoom(room.tableData);
  // };

  const handleKOTGenerate = () => {
    if (clickedTable) {
      setTableStatuses((prevStatuses) => ({
        ...prevStatuses,
        [clickedTable]: "printed",
      }));
    }
  };

  const handleBillGenerate = () => {
    if (clickedTable) {
      setTableStatuses((prevStatuses) => ({
        ...prevStatuses,
        [clickedTable]: "blank",
      }));
    }
  };

  const handleCloudSync = async () => {
    setCloudSync(true);

    try {
      const orders = await getAllCompletedOrders();
      await syncCloud(orders);
    } catch (err) {
      console.log(err);
    } finally {
      setCloudSync(false);
    }
  };

  // ref
  store.setShowRunningKOT = setShowRunningKOT;


  return (
    <>
      <RunningKOTStatus open={showRunningKOT} onClose={() => setShowRunningKOT(false)} />
      {cloudSync && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 mb-4"></div>
            <span className="text-white text-xl">Cloud Sync in progress !</span>
          </div>
        </div>
      )}
      {isOnline && (
        <button
          className="fixed bottom-4 right-4 bg-blue-500 text-white py-2 px-4 rounded-full flex items-center shadow-lg z-300" style={{ zIndex: 300 }}
          onClick={handleCloudSync}
        >
          <span className="mr-2">Sync Cloud</span>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 15a4 4 0 01.88-7.906A5.5 5.5 0 0112 3.5a5.5 5.5 0 014.12 9.594A4 4 0 1115 19H6a4 4 0 01-3-4z"
            ></path>
          </svg>
        </button>
      )}
      <TabNavigation
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        navigate={navigate}
        setShowRunningKOT={setShowRunningKOT}
      />
      {!showPrompt && (
        <div className="bg-white rounded-lg px-6 py-3">
          <Legend
            isPopoverOpen={isPopoverOpen}
            togglePopover={togglePopover}
            resData={roomsData}
            handleClosePromptBYSwitch={handleClosePromptBYSwitch}
          />
          <div className="relative">
            {activeRoom && (
              <Rooms
                activeRoom={activeRoom}
                handleOpenPrompt={handleOpenPrompt}
                ordertables={ordertables}
                printedKOT={printedKOT}
                printedKOTAll={printedKOTAll}
                activeTab={activeTab}
              />
            )}

            <div className="absolute top-0 right-0 flex flex-col gap-5 hidden lg:flex">
              <button
                className="flex flex-col gap-2 items-center justify-center"
                onClick={togglePopover}
              >
                <img
                  className="bg-green-100 p-3 shadow-lg rounded-full"
                  src={transferIcon}
                />{" "}
                Transfer
              </button>
            </div>
          </div>
        </div>
      )}

      {showPrompt && (
        <AcceptOrder
          handleClosePrompt={handleClosePrompt}
          setLoading={setIsLoading}
          data={{
            table: clickedTable.id,
            tableVPA: clickedTable.qrpidVPA,
            room: clickedTable.room,
          }}
          printed={selectedPrintedKOTAll}
          address={address}
          onKOTGenerate={handleKOTGenerate}
          onBillGenerate={handleBillGenerate}
          syncCloud={handleCloudSync}
        />
      )}
    </>
  );
};

export default observer(TableOverview);
