import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ManagerForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false); // For create password
  const [reenterPasswordVisible, setReenterPasswordVisible] = useState(false); // For re-enter password
  const [manager, setManager] = useState({
    restaurantName: "",
    address: "",
    description: "",
    timingFrom: "",
    timingTo: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (field, value) => {
    setManager({ ...manager, [field]: value });
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    // Save the data in localStorage
    const managerData = {
      name: manager.restaurantName,
      address: manager.address,
      description: manager.description,
      timingFrom: manager.timingFrom,
      timingTo: manager.timingTo,
      email: manager.email,
      password: manager.password,
      adminContact: manager.contact,
      adminContact: manager.contact,
    };

    localStorage.setItem("managerData", JSON.stringify(managerData));
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/4 bg-gradient-to-b from-[#16A34A] to-[#035722] text-white p-8">
        <h1 className="text-2xl font-bold mb-4">Step 2</h1>
        <p className="mb-8">Create Manager's Profile</p>
        <ul className="space-y-6">
          <li className="flex items-center">
            <div className="w-6 h-6 rounded-full bg-white text-green-600 flex items-center justify-center font-bold">
              1
            </div>
            <span className="ml-4">Admin detail</span>
          </li>
          <li className="flex items-center">
            <div className="w-6 h-6 rounded-full bg-white text-green-600 flex items-center justify-center font-bold">
              2
            </div>
            <span className="ml-4">Manager's data</span>
          </li>
          <div className="ml-6 px-4">
            <p className="text-sm">Manager 1</p>
            <p className="text-sm py-3">Manager 2</p>
            <p className="text-sm">Manager 3</p>
          </div>
        </ul>
      </div>

      {/* Manager 1 Form */}

      <div className="w-full md:w-3/4 bg-white p-8">
        <h3 className="font-semibold text-gray-600 mb-2">Manager 1:</h3>
        <form>
          {/* First Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="restaurantName"
                className="block mb-1 text-gray-600 font-light"
              >
                Restaurant name
              </label>
              <input
                id="restaurantName"
                type="text"
                className="w-full p-2 border rounded"
                value={manager.restaurantName}
                onChange={(e) => handleChange("restaurantName", e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block mb-1 text-gray-600 font-light"
              >
                Address
              </label>
              <div className="flex gap-2 items-center">
                <input
                  id="address"
                  type="text"
                  className="flex-1 p-2 border rounded"
                  value={manager.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="description"
                className="block mb-1 text-gray-600 font-light"
              >
                Description
              </label>
              <input
                id="description"
                type="text"
                className="w-full p-2 border rounded"
                value={manager.description}
                onChange={(e) => handleChange("description", e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="timingFrom"
                className="block mb-1 text-gray-600 font-light"
              >
                Timing
              </label>
              <div className="flex gap-4">
                <div className="flex flex-col w-full">
                  <input
                    id="timingFrom"
                    type="time"
                    className="w-full p-2 border rounded"
                    value={manager.timingFrom}
                    onChange={(e) => handleChange("timingFrom", e.target.value)}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <input
                    id="timingTo"
                    type="time"
                    className="w-full p-2 border rounded"
                    value={manager.timingTo}
                    onChange={(e) => handleChange("timingTo", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Third Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="email"
                className="block mb-1 text-gray-600 font-light"
              >
                Email
              </label>
              <input
                id="email"
                type="text"
                className="w-full p-2 border rounded"
                value={manager.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="contact"
                className="block mb-1 text-gray-600 font-light"
              >
                Contact
              </label>
              <input
                id="contact"
                type="number"
                className="w-full p-2 border rounded"
                value={manager.contact}
                onChange={(e) => handleChange("contact", e.target.value)}
              />
            </div>
          </div>

          {/* Fourth Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="relative">
              <label
                htmlFor="createPassword"
                className="block mb-1 text-gray-600 font-light"
              >
                Create password
              </label>
              <input
                id="createPassword"
                type={passwordVisible ? "text" : "password"}
                className="w-full p-2 border rounded"
                value={manager.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <button
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="absolute top-1/2 right-2 transform text-gray-500"
              >
                <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
              </button>
            </div>
            <div className="relative">
              <label
                htmlFor="reenterPassword"
                className="block mb-1 text-gray-600 font-light"
              >
                Re-enter password
              </label>
              <input
                id="reenterPassword"
                type={reenterPasswordVisible ? "text" : "password"}
                className="w-full p-2 border rounded"
                value={manager.confirmPassword}
                onChange={(e) =>
                  handleChange("confirmPassword", e.target.value)
                }
              />
              <button
                type="button"
                onClick={() =>
                  setReenterPasswordVisible(!reenterPasswordVisible)
                }
                className="absolute top-1/2 right-2 transform text-gray-500"
              >
                <FontAwesomeIcon
                  icon={reenterPasswordVisible ? faEye : faEyeSlash}
                />
              </button>
            </div>
          </div>

          {/* Footer Buttons */}
          <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-0 mt-16">
            <button
              type="button"
              className="px-4 py-2 bg-[#979797] rounded-full text-white font-extrabold hover:bg-[#b0b0b0] hover:text-white w-full md:w-auto"
            >
              Back
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-green-600 text-white font-extrabold rounded-full hover:bg-green-700 w-full md:w-auto"
            >
              NEXT STEP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManagerForm;
