import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from "../utils/common";

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const isAuth = isAuthenticated();
  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return <Element {...rest} />;
};

export default ProtectedRoute;