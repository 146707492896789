import React, { useState, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
    const [isMobileSideBarOpen, setIsMobileSideBarOpen] = useState(false);
    const [isMobileView, setIsMobileVew] = useState(false) 
    const location = useLocation();
    const [enabledPaths] = useState(['/table-overview','/order-parcel'])
    const currentPath = location.pathname;

    const toggleSideBarOpen = () => {
        setIsMobileSideBarOpen(prev => !prev)
    }
    useEffect(() => {
        const handleResize = () => {
            setIsMobileVew(window.innerWidth <= 768); // Corrected function name
            console.log(window.innerHeight)
        };
        
        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    return (
        <div className='md:flex-row flex-col flex'>
            <Sidebar isOpen={isMobileSideBarOpen}  toggleSidebar={toggleSideBarOpen} />
            <main className='flex-1 w-[100vw] md:w-[unset] h-[calc(100vh)]'>
                { (isMobileView || enabledPaths.includes(currentPath) )&& <Header toggleSidebar={toggleSideBarOpen} /> }
                <Outlet />
            </main>
        </div>
    ); 
};

export default Layout;
