import toast from "react-hot-toast";

export const printBillPartner = ({ items ,subtotal,total ,orderId}) => {
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const formattedTime = date.toLocaleTimeString();
  
    const kot = `
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 10px;
          }
          h2 {
            text-align: center;
            margin: 0 0 10px 0;
            font-size: 18px;
          }
          .details {
            margin-bottom: 10px;
            font-size: 14px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            font-size: 14px;
          }
          th, td {
            padding: 8px;
            
          }
          th {
            text-align: left;
            background-color: #f2f2f2;
          }
          td {
            vertical-align: top;
          }
          .customisation {
            padding-left: 10px;
            font-style: italic;
          }
        </style>
      </head>
      <body>
        <h2>Bill </h2>
        <div class="details">
          <p><strong>Date:</strong> ${formattedDate}</p>
          <p><strong>Time:</strong> ${formattedTime}</p>
          <p><strong>Order Id: ${String(orderId).slice(-4)} </strong></p>
        </div>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Customisation</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            ${items
              .map((item) => {
                if (item.customisations && item.customisations.length > 0) {
                  return item.customisations
                    .map(
                      (customisation) => `
                        <tr>
                          <td>${item.name}</td>
                          <td class="customisation">${customisation.name}</td>
                          <td>${customisation.quantity}</td>
                        </tr>
                      `
                    )
                    .join('');
                } else {
                  return `
                    <tr>
                      <td>${item.name}</td>
                      <td>-</td>
                      <td>${item.quantity}</td>
                    </tr>
                  `;
                }
              })
              .join('')}
          </tbody>
        </table>
        <div>SubTotal: ${subtotal}</div>
         <div>Total: ${total}</div>
      </body>
    </html>
    `;
  
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(kot);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    return printWindow.close();
  };
  