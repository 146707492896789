import { useEffect, useState } from "react";

const Managment = () => {
  const [link, setLink] = useState("");
  const [saveLocat , setLoacl] = useState("");
  // https://docs.google.com/spreadsheets/d/1NiTqC0x60IY-zjZOCUfC9jEDDY61-3lmrObUqhWnjH4/edit?usp=sharing
  useEffect(() => {
    const links = localStorage.getItem("excleLink");
    if (links) {
      setLink(links);
    }
  }, [])

  const saveLink = () => {
    localStorage.setItem("excleLink", saveLocat);
    setLink(saveLocat);

  }
  const removeLink = ()=>{
    localStorage.removeItem("excleLink");
    setLink("");
  }

  return <>
    {link && <>
      <button
            onClick={removeLink}
            className="w-full py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300"
          >
            Remove 
          </button>
      <div style={{ width: '100%', height: '80vh', overflow: 'hidden' }}>
      <iframe
        src={link}
        width="100%"
        height="100%"
        frameBorder="0"
        title="Google Spreadsheet"
      ></iframe>
    </div>
    </>}
    {!link &&  (
        <div className="bg-white p-8 rounded-lg shadow-md w-96">
          <h1 className="text-lg font-semibold mb-4 text-gray-700">
            Embed Google Spreadsheet
          </h1>
          <div>
            <label htmlFor="excel-link" className="block text-sm font-medium text-gray-600 mb-2">
              Google Spreadsheet Link
            </label>
            <input
              id="excel-link"
              type="text"
              value={saveLocat}
              onChange={(e) => setLoacl(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
              placeholder="Enter your Google Spreadsheet link"
            />
          </div>
          <button
            onClick={saveLink}
            className="w-full py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300"
          >
            Save
          </button>
        </div>
      )}
  </>
}

export default Managment;