export const ZOMATOAPIBASEEND = "http://localhost:32567";
// export const ZOMATOAPIBASEEND="http://localhost:3006";

// export const ZOMATOAPIBASEEND="https://yunica.co/v1/";

export const ZOMATOAPI = {
  LOGIN: "/api/v1/zomato/login",
  GET_CURRENT_ORDERS: "/api/v1/zomato/orders/current",
  GET_ORDER_DETAILS: "/api/v1/zomato/order/details",
  ACCEPT_NEW_ORDER: "/api/v1/zomato/orders/accept_order",
  ACCEPT_NEW_ORDER_RESTAURANT: "/api/v1/zomato/orders/accept_order/{res_id}",
  MARK_ORDER_READY: "/api/v1/zomato/orders/mark_ready",
  MARK_ORDER_READY_RESTAURANT: "/api/v1/zomato/orders/mark_ready/{res_id}",
  GET_ITEMS: "/api/v1/zomato/items",
  GET_ITEMS_RESTAURANT: "/api/v1/zomato/items/{res_id}",
  MARK_ITEM_IN_STOCK: "/api/v1/zomato/items/in_stock",
  MARK_ITEM_IN_STOCK_RESTAURANT: "/api/v1/zomato/items/in_stock/{res_id}",
  MARK_CATEGORY_IN_STOCK: "/api/v1/zomato/category/in_stock",
  MARK_CATEGORY_IN_STOCK_RESTAURANT:
    "/api/v1/zomato/category/in_stock/{res_id}",
  MARK_ITEM_OUT_OF_STOCK: "/api/v1/zomato/items/out_of_stock",
  REJECT_ORDER: "/api/v1/zomato/orders/reject",
  CLOSE_OUTLET: "/api/v1/zomato/close_outlet",
};

export const SWIGGYAPI = {
  INDEX: "/api/v1/swiggy/",
  LOGIN: "/api/v1/swiggy/login",
  GET_ORDERS: "/api/v1/swiggy/orders",
  ACCEPT_NEW_ORDER: "/api/v1/swiggy/orders/accept",
  ACCEPT_NEW_ORDER_RESTAURANT: (res_id) =>
    `/api/v1/swiggy/orders/accept/${res_id}`,
  MARK_ORDER_READY: "/api/v1/swiggy/orders/ready",
  MARK_ORDER_READY_RESTAURANT: (res_id) =>
    `/api/v1/swiggy/orders/ready/${res_id}`,
  GET_ITEMS: "/api/v1/swiggy/items",
  GET_ITEMS_RESTAURANT: (res_id) => `/api/v1/swiggy/items/${res_id}`,
  MARK_ITEM_IN_STOCK: "/api/v1/swiggy/items/instock",
  MARK_ITEM_IN_STOCK_RESTAURANT: (res_id) =>
    `/api/v1/swiggy/items/instock/${res_id}`,
  MARK_CATEGORY_IN_STOCK: "/api/v1/swiggy/category/instock",
  MARK_CATEGORY_IN_STOCK_RESTAURANT: (res_id) =>
    `/api/v1/swiggy/category/instock/${res_id}`,
  MARK_ITEM_OUT_OF_STOCK: "/api/v1/swiggy/items/outofstock",
  MARK_ITEM_OUT_OF_STOCK_RESTAURANT: (res_id) =>
    `/api/v1/swiggy/items/outofstock/${res_id}`,
  MARK_CATEGORY_OUT_OF_STOCK: "/api/v1/swiggy/category/outofstock",
  MARK_CATEGORY_OUT_OF_STOCK_RESTAURANT: (res_id) =>
    `/api/v1/swiggy/category/outofstock/${res_id}`,
  CLOSE_OUTLET: "/api/v1/swiggy/close_outlet",
  CLOSE_OUTLET_RESTAURANT: (res_id) => `/api/v1/swiggy/close_outlet/${res_id}`,
  GET_ORDER_HISTORY: "/api/v1/swiggy/orderHistory",
  UPDATE_ITEM_PRICE: (item_id) => `/api/v1/swiggy/item/price/${item_id}`,
  DELETE_ITEM: (id) => `/api/v1/swiggy/items/delete?id=${id}`,
};
