export const printKOT = ({
  type,
  cartItems,
  roomData,
  extraCartItems,
  cancelItems = [],
  comment,
  billNo,
  customer
}
) => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const time = date.toLocaleTimeString();
  let customerDetailsTemplate = ''
  if(type === 'Parcel KOT')
  {
    customerDetailsTemplate = `
        ${ customer.name != 'NA' &&  customer.name != ''  ? '<hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />' : ''}
        <div class="customer-info" style="font-size: 0.8rem;">
        ${ (customer.name != 'NA' &&  customer.name != '')   ? `<div><span id="customer-name"><strong>Customer:</strong> ${customer.name} </span></div>` : ''}
        ${( customer.phone != 0 &&  customer.phone != 'NA')  ? `<div><span class="info-label">Ph:</span> <span id="customer-phone">${customer.phone}</span></div>` : ''}
        ${ (customer.address != 'NA' &&  customer.address != '')  ? `<div><span class="info-label">Add:</span> <span id="customer-address">${customer.address}</span></div>` : ''}
        </div>
    `
  }else {
    customerDetailsTemplate = `
    ${ customer.name != 'NA' &&  customer.name != ''  ? '<hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />' : ''}
    <div class="customer-info" style="font-size: 0.8rem;">
    ${ (customer.name != 'NA' &&  customer.name != '')   ? `<div><span id="customer-name"><strong>Customer:</strong> ${customer.name} </span></div>` : ''}
    </div>
    `
  }

  const KOT = `
      <div style="padding: 0; margin:0; background-color: white; font-family: Arial, sans-serif; width: 100%; max-width: 300px;">
        <h2 style="text-align: center; font-size: 1rem; font-weight: bold; margin-bottom: 1rem;">${type}</h2>
        
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="font-size: 0.8rem; margin: 0.4rem 0;"><strong>${`${day} / ${month} / ${year} : ${time}`}</strong> </p>
        <p style="font-size: 0.8rem; margin: 0.5rem 0;"><strong>Table:</strong> ${roomData.table}/${roomData.room} <strong>   Bill No: ${billNo}</strong> </p>
       
        ${customerDetailsTemplate}

        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        
        <div>
          <table style="width: 100%; font-size: 0.8rem; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left;">Item</th>
                
                <th style="text-align: right;">Qty.</th>
              </tr>
            </thead>
            <tbody>
              ${cancelItems?.map((item) =>
      `
                <tr style="text-decoration: line-through red dashed;">
                  <td style="padding: 0.25rem 0;"><strong> ${item.name} ${item.isVariant ? "- " + item.variant : ''
      } </strong></td>
                  <td style="text-align: right;">${item.quantity}</td>
                </tr>
              `
              )?.join("")
              }
              ${cartItems.map(
      (item) => `
                <tr>
                  <td style="padding: 0.25rem 0;"><strong> ${item.name} ${item.isVariant ? "- " + item.variant : ''
        } </strong></td>
                  <td style="text-align: right;">${item.quantity}</td>
                </tr>
                ${item.comment ? `<tr> <td style="padding: 0.25rem 0; font-size:0.7rem"> <svg xmlns="http://www.w3.org/2000/svg" width="0.7rem" height="0.7rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-corner-down-right"><polyline points="15 10 20 15 15 20"/><path d="M4 4v7a4 4 0 0 0 4 4h12"/></svg> ${item.comment} </td></tr>` : ''}
              `
              ).join("")}
            </tbody>
            </table>
            </br>
            ${comment && `<div>Comment :<i>${comment}</i></div>`}
        </div>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">Powered by QRapid</p>
      </div>
    `;

  const WinPrint = window.open("", "", "width=800,height=600");
  WinPrint.document.write(KOT);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  // changeKOTStatus({billNo:billNo})
  WinPrint.close();
};
