// // src/firebase.js

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAEX2tRyqj-w6t5yD7gDr40ggDZGTO_IAE",
  authDomain: "qrapid-notifications.firebaseapp.com",
  projectId: "qrapid-notifications",
  storageBucket: "qrapid-notifications.firebasestorage.app",
  messagingSenderId: "169470520155",
  appId: "1:169470520155:web:ba9248363b343f3a16887e"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export { firebaseApp, messaging };
