import React, { useEffect, useState, useRef } from "react";
import useOrder from "../hooks/useOrder";
import { useCloud } from "../hooks/useCloud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint } from "@fortawesome/free-solid-svg-icons";
import { Filter, X, Search, RefreshCcwDot } from "lucide-react";
import { Reprint } from "../Component/Print/ReprintBill"


const Orders = ({ onSort = () => { } }) => {
    const { getCurrentDayOrders, changePaymentMethod, getBillByBillNo, syncCloud } = useCloud();
    const { getAllCompletedOrders } = useOrder();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBillNo, setSelectedBillNo] = useState(null);
    const [selectedOrderItems, setSelectedOrderItems] = useState({
        items: [],
        order: { amount: "", discount: "", tips: "", status: "", isCancle: false },
    });
    const [paymentMethod, setPaymentMethod] = useState("");
    const [error, setError] = useState("");
    const [isCancleOrder, setIsCancleOrder] = useState(false);

    // Search and filter states
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [activeFilters, setActiveFilters] = useState({
        paymentMethods: [],
        statuses: [],
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const filterRef = useRef(null);

    // Handle opening the modal for cancellation
    const handleOpenCancelModal = (billNo) => {
        setSelectedBillNo(billNo);
        setIsCancleOrder(true);
        setError("");
    };

    // Handle closing the modal for cancellation
    const handleCloseModal = () => {
        setIsCancleOrder(false);
        setPaymentMethod("");
        setError("");
    };

    // Handle form submission (Cancel order)
    const handleChangeMethod = async () => {
        if (!paymentMethod) {
            setError("Select Payment Method.");
            return;
        }
        try {
            const { event, message } = await changePaymentMethod({
                billNo: selectedBillNo,
                paymentMethodtyps: paymentMethod,
            });

            if (event) {
                handleCloseModal();
                const updatedOrders = orders.map((order) =>
                    order.id === selectedBillNo
                        ? { ...order, paymentMethod: paymentMethod, status: 'Paid' }
                        : order
                );
                setOrders(updatedOrders)
            } else {
                setError("Something went wrong, please try again");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
        }
    };

    // Available payment methods and statuses for filtering
    const availablePaymentMethods = ["cash", "online", "card", "pending"];
    const availableStatuses = ["Paid", "Pending", "Cancelled"];

    // Toggle status filter
    const toggleStatusFilter = (status) => {
        setActiveFilters((prev) => {
            const statuses = prev.statuses.includes(status)
                ? prev.statuses.filter((s) => s !== status)
                : [...prev.statuses, status];

            return { ...prev, statuses };
        });
    };

    // Toggle payment method filter
    const togglePaymentMethodFilter = (method) => {
        setActiveFilters((prev) => {
            const methods = prev.paymentMethods.includes(method)
                ? prev.paymentMethods.filter((m) => m !== method)
                : [...prev.paymentMethods, method];

            return { ...prev, paymentMethods: methods };
        });
    };

    // Clear all filters
    const clearFilters = () => {
        setSearchTerm("");
        setActiveFilters({
            paymentMethods: [],
            statuses: [],
        });
    };

    // Handle opening the modal for viewing order items
    const handleBillView = async (order) => {
        try {
            if (!order?.itemsforView) {
                const orderData = await getBillByBillNo({
                    billNo: order.id,
                });
                if (orderData?.event) {
                    setSelectedOrderItems(orderData);
                }
            } else {
                setSelectedOrderItems({
                    event: true,
                    items: order.itemsforView,
                    order: {
                        amount: order.amount,
                        billNo: order.id,
                        discount: order.discount,
                        tableVPA: order.tableVPA,
                        tips: order.tips,
                        status: order.status,
                        isCancle: order.isCancle || false,
                    },
                });
            }

            setSelectedBillNo(order.id);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching order items:", error);
        }
    };

    // Handle printing
    const handleBillPrint = async (order) => {
        try {
            const hotelData = {
                name: localStorage.getItem("name"),
                address: localStorage.getItem("address"),
            };

            const roomData = {
                table: order?.tableVPA ? order?.tableVPA.match(/t(\d+)@/)[1] : "Na",
            };

            if (!order.itemsforView) {
                const orderdata = await getBillByBillNo({
                    billNo: order.id,
                });
                if (orderdata?.event) {
                    Reprint(
                        order.id,
                        orderdata.order?.discount,
                        orderdata.order?.amount,
                        orderdata.items,
                        hotelData,
                        roomData
                    );
                }
            } else {
                Reprint(
                    order.id,
                    order?.discount,
                    order?.amount,
                    order.itemsforView,
                    hotelData,
                    roomData
                );
            }
        } catch (error) {
            console.error("Error printing order:", error);
        }
    };

    const transformToLocalOrder = (orders) => {
        return orders.map((order) => {
            const tableNumberMatch = order?.tableVPA?.match(/t(\d+)@/) || "";
            const tableNumber = tableNumberMatch ? tableNumberMatch[1] : "";

            let orderStatus = "Paid";
            if (order.status == "BILLCancelled") orderStatus = "Cancelled";
            if (
                order.payment?.methods?.length == 1 &&
                order.payment?.methods[0].name == "pending"
            ) {
                orderStatus = "Pending";
            }

            let paymentMethod = "N/A";
            if (order.payment?.methods?.length > 0) {
                paymentMethod =
                    order.payment?.methods?.length > 1
                        ? "Partial"
                        : order.payment?.paymentname;
            }

            return {
                amount: order.amount || 0,
                discount: order.billDetails?.discount || 0,
                id: order.billNo || "N/A",
                items: order.printKOT.items.map((item) => item.name),
                partial:
                    order.payment?.methods?.length > 1 ? order.payment.methods : [],
                paymentMethod: paymentMethod,
                status: orderStatus,
                table: "T " + tableNumber,
                time: order.createdAt || "N/A",
                tips: order.payment?.tips || 0,
                itemsforView: order.printKOT.items,
                tableVPA: order.tableVPA,
                tips: order?.tips || 0,
                isCancel: order.status == "BILLCancelled",
                isSynced: false,
            };
        });
    };

    const fetch = async () => {
        setLoading(true)
        clearFilters()
        try {
            await syncCloud()
            const local_orders = transformToLocalOrder(await getAllCompletedOrders());
            const synced_orders = await getCurrentDayOrders();
            let allOrders = [...local_orders, ...synced_orders].reduce(
                (acc, order) => {
                    if (!acc[order.id]) {
                        acc[order.id] = order;
                    }
                    return acc;
                },
                {}
            );
            allOrders = Object.values(allOrders);
            setOrders(allOrders);
            setFilteredOrders(allOrders);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        let result = orders;

        // Apply search term
        if (searchTerm) {
            const lowercasedSearch = searchTerm.toLowerCase();
            result = result.filter(
                (order) =>
                    order.id.toString().includes(lowercasedSearch) ||
                    order.table.toLowerCase().includes(lowercasedSearch) ||
                    order.paymentMethod.toLowerCase().includes(lowercasedSearch) ||
                    order.status.toLowerCase().includes(lowercasedSearch) ||
                    order.time.toLowerCase().includes(lowercasedSearch)
            );
        }

        // Apply payment method filters
        if (activeFilters.paymentMethods.length > 0) {
            result = result.filter((order) =>
                activeFilters.paymentMethods.includes(order.paymentMethod.toLowerCase())
            );
        }

        // Apply status filters
        if (activeFilters.statuses.length > 0) {
            result = result.filter((order) =>
                activeFilters.statuses.includes(order.status)
            );
        }

        setFilteredOrders(result);
    }, [searchTerm, activeFilters]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsFilterOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="bg-gray-50 ">
            <div className="max-w-[1400px] h-[calc(100vh)] w-[100vw] md:w-[calc(100vw-120px)] mx-auto p-4 box-border bg-gray-50 overflow-x-hidden relative hide-scrollbar">
                <div className="bg-white rounded-lg shadow-lg border border-gray-200 h-full overflow-x-scroll hide-scrollbar relative ">
                    <div className="p-4 border-b border-gray-200 rounded-t-lg  bg-white flex-wrap gap-2 sticky top-0 flex flex-col gap-4 rounded-t-lg bg-white">
                        <div className="flex justify-between items-center">
                            <div>
                                <h2 className="font-semibold text-lg text-gray-900">
                                    Today's Order
                                </h2>
                                <p className="text-sm text-gray-500 mt-1">
                                    {filteredOrders.length} Orders
                                </p>
                            </div>

                            <div className="flex gap-2">
                                {/* Refresh */}
                                <button onClick={fetch} className="inline-flex items-center px-3 py-2 border rounded-md text-sm font-medium transition-colors hover:bg-gray-50" >
                                    <RefreshCcwDot className="h-4 w-4 mr-2 text-stroke-700 cursor-pointer hover:text-green-500" />
                                    Refresh
                                </button>
                                {/* Filter */}
                                <button
                                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                                    className={`inline-flex items-center px-3 py-2 border rounded-md text-sm font-medium transition-colors ${isFilterOpen ||
                                        activeFilters.paymentMethods.length > 0 ||
                                        activeFilters.statuses.length > 0
                                        ? "bg-blue-50 text-blue-700 border-blue-300"
                                        : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                                        }`}
                                >
                                    <Filter className="h-4 w-4 mr-2" />
                                    Filters
                                    {(activeFilters.paymentMethods.length > 0 ||
                                        activeFilters.statuses.length > 0) && (
                                            <span className="ml-1 bg-blue-100 text-blue-800 text-xs font-semibold px-2 py-0.5 rounded-full">
                                                {activeFilters.paymentMethods.length +
                                                    activeFilters.statuses.length}
                                            </span>
                                        )}
                                </button>

                                {/* Filter count */}
                                {(searchTerm ||
                                    activeFilters.paymentMethods.length > 0 ||
                                    activeFilters.statuses.length > 0) && (
                                        <button
                                            onClick={clearFilters}
                                            className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                                        >
                                            <X className="h-4 w-4 mr-2" />
                                            Clear
                                        </button>
                                    )}
                            </div>
                        </div>

                        <div className="flex items-center">
                            {/* Search bar */}
                            <div className="relative flex-1">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Search className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search by bill number, table, payment method..."
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        {/* Filter panel */}
                        {isFilterOpen && (
                            <div
                                ref={filterRef}
                                className="bg-white space-y-2 p-4 rounded-md border border-gray-200 mt-2 absolute top-[60px] right-4  shadow-lg shadow-md-bottom"
                            >
                                {/* Payment Method Filters */}
                                <div>
                                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                                        Payment Method
                                    </h3>
                                    <div className="flex flex-wrap gap-2">
                                        {availablePaymentMethods.map((method) => (
                                            <button
                                                key={method}
                                                onClick={() => togglePaymentMethodFilter(method)}
                                                className={`px-3 py-1 text-sm rounded-md transition-colors ${activeFilters.paymentMethods.includes(method)
                                                    ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                                                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                                                    } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                                            >
                                                {method.charAt(0).toUpperCase() + method.slice(1)}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                {/* Order Status Filters */}
                                <div>
                                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                                        Order Status
                                    </h3>
                                    <div className="flex flex-wrap gap-2">
                                        {availableStatuses.map((status) => (
                                            <button
                                                key={status}
                                                onClick={() => toggleStatusFilter(status)}
                                                className={`px-3 py-1 text-sm rounded-md transition-colors ${activeFilters.statuses.includes(status)
                                                    ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                                                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                                                    } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                                            >
                                                {status.charAt(0).toUpperCase() + status.slice(1)}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {loading ? <TableSkeleton rows={5} columns={10} hasHeader={true} /> :
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-gray-50 border-b border-gray-200">
                                        <th
                                            onClick={() => onSort("id")}
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        >
                                            Bill No
                                        </th>
                                        <th
                                            onClick={() => onSort("table")}
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        >
                                            Table
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Payment Type
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            My Amount
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Discount
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Grand Total
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Tips
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Status
                                        </th>
                                        <th
                                            onClick={() => onSort("time")}
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        >
                                            Date/Time
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            View/Print
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {filteredOrders
                                        .sort((a, b) => b.id - a.id)
                                        .map((order, index) => (
                                            <tr
                                                key={order.billNo}
                                                className="hover:bg-gray-50 transition-colors"
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    <div className="flex gap-1 items-center">
                                                        {order.isSynced !== undefined && !order?.isSynced && (
                                                            <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
                                                        )}
                                                        <span>{order.id}</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {order.table}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    <p className="font-medium">{order.paymentMethod}</p>
                                                    {order.paymentMethod === "Partial" &&
                                                        order?.partial && (
                                                            <div>
                                                                {order.partial.map(
                                                                    (ele, idx) =>
                                                                        ele.amount > 0 && (
                                                                            <p
                                                                                key={idx}
                                                                                className="text-xs text-gray-600"
                                                                            >
                                                                                {ele.name}: {ele.amount}
                                                                            </p>
                                                                        )
                                                                )}
                                                            </div>
                                                        )}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {Number.parseFloat(order.amount) +
                                                        Number.parseFloat(order.discount)}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {Number.parseFloat(order.discount).toFixed(2)}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {Number.parseFloat(order.amount)}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {order.tips}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span
                                                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full  ${order.isCancle
                                                            ? "bg-red-100 text-red-800"
                                                            : order.status === "Paid"
                                                                ? "bg-green-100 text-green-800"
                                                                : "bg-yellow-100 text-yellow-800 cursor-pointer"
                                                            }`}
                                                        onClick={() => {
                                                            if (order.status === 'Pending') {
                                                                handleOpenCancelModal(order.id);
                                                            }
                                                        }}
                                                    >
                                                        {order.isCancle ? "Cancelled" : order.status}
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 space-x-2">
                                                    <span>
                                                        {new Date(order.time).toLocaleTimeString([], {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: true,
                                                        })}
                                                    </span>
                                                    <span>
                                                        {
                                                            formateDate(order.time)
                                                        }
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-pointer">
                                                    <FontAwesomeIcon
                                                        icon={faEye}
                                                        className="mr-3"
                                                        onClick={() => handleBillView(order)}
                                                    />
                                                    {!order.isCancle && (
                                                        <FontAwesomeIcon
                                                            icon={faPrint}
                                                            className="mr-3"
                                                            onClick={() => handleBillPrint(order)}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                {/* View Order Items Modal */}
                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md max-h-[80vh] overflow-y-auto  relative">
                            {selectedOrderItems.order && selectedOrderItems.order.isCancle ? (
                                <div className="text-center mb-4">
                                    <span className="inline-block px-3 py-1 text-sm font-medium rounded-full bg-red-200 text-red-800">
                                        Cancelled
                                    </span>
                                    <p className="mt-2 text-gray-700">
                                        Reason: {selectedOrderItems.cancelReason || "Not specified"}
                                    </p>
                                </div>
                            ) : (
                                <span className="inline-block px-3 py-1 text-sm font-medium rounded-full bg-gray-200 text-gray-800 absolute top-3 right-3">
                                    {selectedOrderItems.order?.status || "Unknown"}
                                </span>
                            )}

                            <h2 className="text-xl font-semibold text-gray-900 mb-4">
                                Order Items (Bill No: {selectedBillNo})
                            </h2>

                            <div className="mb-6">
                                <div className="grid grid-cols-3 font-medium text-gray-700 mb-2 border-b pb-2">
                                    <div>Item</div>
                                    <div className="text-center">Qty</div>
                                    <div className="text-right">Price</div>
                                </div>

                                {selectedOrderItems?.items &&
                                    selectedOrderItems.items.length > 0 ? (
                                    selectedOrderItems.items.map((item, index) => (
                                        <div
                                            key={index}
                                            className="grid grid-cols-3 py-2 border-b border-gray-100 text-gray-800"
                                        >
                                            <div className="pr-2">
                                                {item?.name || "Unknown Item"}
                                            </div>
                                            <div className="text-center">{item?.quantity || "1"}</div>
                                            <div className="text-right">{item?.price || "0.00"}</div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="py-4 text-center text-gray-500">
                                        No items found
                                    </div>
                                )}
                            </div>

                            <div className="space-y-2 text-right">
                                {selectedOrderItems.order?.discount && (
                                    <div className="text-gray-700">
                                        Discount:{" "}
                                        <span className="font-semibold">
                                            {selectedOrderItems.order.discount}
                                        </span>
                                    </div>
                                )}
                                {selectedOrderItems.order?.tips && (
                                    <div className="text-gray-700">
                                        Tips:{" "}
                                        <span className="font-semibold">
                                            {selectedOrderItems.order.tips}
                                        </span>
                                    </div>
                                )}
                                <div className="text-gray-900 font-medium text-lg">
                                    Total:{" "}
                                    <span className="font-bold">
                                        {selectedOrderItems.order?.amount || "0.00"}
                                    </span>
                                </div>
                            </div>

                            <button
                                className="mt-6 w-full bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}

                {/* Change Payment Method Modal */}
                {isCancleOrder && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
                            <h3 className="text-lg font-semibold text-gray-900 mb-4">
                                Change Payment Method
                            </h3>

                            {error && (
                                <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded-md">
                                    {error}
                                </div>
                            )}

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Payment Method
                                </label>
                                <select
                                    value={paymentMethod}
                                    onChange={(e) => {
                                        setPaymentMethod(e.target.value);
                                        setError("");
                                    }}
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                                >
                                    <option value="">Select Payment Method</option>
                                    <option value="cash">Cash</option>
                                    <option value="online">Online</option>
                                    <option value="card">Card</option>
                                </select>
                            </div>

                            <div className="flex justify-end space-x-3">
                                <button
                                    className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    onClick={handleChangeMethod}
                                >
                                    Change Method
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Orders;


function TableSkeleton({ rows = 5, columns = 4, hasHeader = true }) {
    return (
        <div className="overflow-x-auto">
            <table className="w-full">
                {hasHeader && (
                    <thead>
                        <tr className="bg-gray-50 text-left text-xs uppercase tracking-wide text-gray-500">
                            {Array(columns)
                                .fill(null)
                                .map((_, index) => (
                                    <th key={`header-${index}`} className="px-4 py-3">
                                        <div className="h-2.5 w-24 animate-pulse rounded-full bg-gray-200"></div>
                                    </th>
                                ))}
                        </tr>
                    </thead>
                )}
                <tbody className="divide-y divide-gray-100 bg-white">
                    {Array(rows)
                        .fill(null)
                        .map((_, rowIndex) => (
                            <tr key={`row-${rowIndex}`} className="text-gray-700">
                                {Array(columns)
                                    .fill(null)
                                    .map((_, colIndex) => (
                                        <td key={`cell-${rowIndex}-${colIndex}`} className="px-4 py-3">
                                            <div
                                                className={`h-2.5 animate-pulse rounded-full bg-gray-200 ${colIndex === 0 ? "w-32" : "w-24"}`}
                                            ></div>
                                        </td>
                                    ))}
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    )
}

const formateDate = (time) => {
    const orderTime = new Date(time);
    const day = String(orderTime.getDate()).padStart(2, '0');
    const month = String(orderTime.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = orderTime.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate

}

