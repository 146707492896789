import { useEffect } from "react";
import { useCloud } from "./useCloud";
import store from "../store/index"; // Adjust the path as necessary
import useOrder from "./useOrder";
import useNotifications from "./useNotifications";
import { transformDigitalOrderItems } from "../utils/common"

export const useStore = () => {
  const { processOrder, getLatestBillNumber } = useOrder();
  const { registerToken } = useNotifications();
  const {
    getAllItems,
    allCategories,
    tableData,
    getTableData,
    requestedKOTData,
    getWaiterList,
    getAllCategoryAndItem,
    getOrderHistory,
    getDigitalOrders,
    getRestaurantInfo,
  } = useCloud();

  const transformDigitalOrders = (order) => {
    const formatedOrder = order.items.map((newOrder) => ({
      itemId: newOrder.itemId,
      quantity: parseInt(newOrder.quantity),
      varientid:
        newOrder.variationId != "NA" ? newOrder.variationId : undefined,
    }));
    const items = transformDigitalOrderItems(order)
    const rawOrder = items.map((newOrder) => ({
      uniqueId: newOrder.uniqueId,
      itemId: newOrder.itemId,
      id: newOrder.id,
      name: newOrder.name,
      price: newOrder.price || "0",
      variant: newOrder.variant,
      quantity: parseInt(newOrder.quantity),
      variantId: newOrder.variantId,
      variantName: newOrder.variantName,
      isVariant: newOrder.isVariant,
      extra: false,
      createdAt: new Date(),
      readyAt: false
    }));
    return {
      rawOrder,
      formatedOrder,
      tableVPA: order.tableVPA,
      cancleOrders: [],
      extraOrders: [],
      username: order.name,
      usermobile: order?.mobileNo || "NA",
      orderStatus: "RequestKOT",
      onlineBillNo: order.onlineCode,
      replaceItems: "RequestKOT",
      isOnline: true,
    };
  };
  const getCloudSync = async () => {
    try {
      registerToken();

      const restaurantInfo = await getRestaurantInfo();
      store.restaurantInfo = restaurantInfo.data;

      getLatestBillNumber( parseInt(restaurantInfo.data.lastbillNo) )

      const { event, requestKOTAmoutntItem, requestKot } =
        await getDigitalOrders();

      const { data: completeMenu } = await getAllCategoryAndItem();
      store.updateCompleteMenu(completeMenu);

      const { menuItems: items } = await getAllItems();
      store.addItems(items);

      const { categories } = await allCategories();
      store.addCategories(categories);

      const { data: rooms } = await tableData();
      store.addRooms(rooms);

      // const { kotPrintedItem: printedKOTs, vpaKotPrinted: printedKOT_VPA } =
      //   await getTableData();

      const { waiters = [] } = await getWaiterList();
      store.addWaiters(waiters);

      const digitalOrders = requestKOTAmoutntItem.map(transformDigitalOrders);
      const digitalOrdersTables = requestKot.map((table) => table.tableVPA);

      getOrderHistory().then((orders) => {
        const { event, data } = orders;
        if(event)
        {
          store.updateOrderHistory(orders);
          let latestBillNo = data.reduce((acc, bill) => {
            if(parseInt(bill.invoiceNo) > acc)
              return parseInt(bill.invoiceNo)
            return acc
          }, 0)
          getLatestBillNumber(latestBillNo)
        }
      });

      // store initialization

      store.setDigitalOrderVPA(digitalOrdersTables);

      setTimeout(() => {
        digitalOrders.map(async (order) => {
          await processOrder(order);
          const digitalOrderProcessed = new CustomEvent(
            "digitalOrderProcessed",
            {}
          );
          window.dispatchEvent(digitalOrderProcessed);
        });
      }, 1000);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  useEffect(() => {
    const fetchOnline = async () => {
      await getCloudSync();
    };
    fetchOnline();
  }, []);

  return { store, getCloudSync };
};

export default useStore;
