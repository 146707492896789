import { useState, useEffect, useRef, useCallback } from "react";
import { ChevronDown, Filter, EyeOff, Eye } from "lucide-react";
import DatePicker from "react-datepicker";
import {
  formatDate,
  adjustOrderDate,
  getDateDifference,
} from "../utils/common";
import useOrder from "../hooks/useOrder";
import { useCloud } from "../hooks/useCloud";
import store from "../store/index";
import PasswordDialog from "../Component/PasswordInput";
import toast, { Toaster } from "react-hot-toast";

export default function Dashboard() {
  const CLOSING_HOURS = 6;
  const FILTER_PLATFORM_ENABLE = false;
  const FILTER_PAYMENT_ENABLE = false;
  const PASSWORD = "12345";
  const { getOrderHistory } = useOrder();
  const { getOrderHistory: getOrderHistoryCloud } = useCloud();
  const [orders, setOrders] = useState([]);
  const [timeFilter, setTimeFilter] = useState("today");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [platform, setPlatform] = useState("all");
  const [dateFilter, setDateFilter] = useState("");
  const filterRef = useRef(null);
  const [paymentMethods, setPaymentMethods] = useState([
    "cash",
    "online",
    "card",
    "partial",
    "pending",
    "NA",
  ]);
  const [pendingBills, setPendingBills] = useState({})
  const [filteredData, setFilteredData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isPlatformDropdownOpen, setIsPlatformDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, SetError] = useState(null);
  const [analytics, setAnalytics] = useState({});
  const [isProtected, setProtected] = useState(false);
  const [showPasswordDialogue, setShowPasswordDialogue] = useState(false);
  const [wasPasswordEntered, setPasswordEntered] = useState(false);
  const [otherPaymentMethodOrders, setOtherPaymentMethodsOrders] = useState([]);
  const [otherPaymentTotal, setOtherPaymentTotal] = useState(0)

  // Utility function for calculating the total amount
  const calculateTotalAmount = (orders) => {
    return orders
      .filter(
        (order) =>
          order.paymentmethod !== "order cancle" && order.paymentmethod !== "NA"
      )
      .reduce((acc, order) => acc + order.amount, 0);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePaymentMethodToggle = (method) => {
    const _paymentMethods = paymentMethods.includes(method)
      ? paymentMethods.filter((m) => m !== method)
      : [...paymentMethods, method];

    setPaymentMethods(_paymentMethods);
    sudoFilter({ types: _paymentMethods });
  };

  const filterLastNDays = (days) => {
    const today = new Date();
    const previousDate = new Date();
    previousDate.setDate(today.getDate() - days);
    setStartDate(previousDate);
    setEndDate(today);
    setPaymentMethods(["cash", "online", "card", "partial", "pending", "NA"]);
    sudoFilter({
      start: previousDate,
      end: today,
      types: ["cash", "online", "card", "partial", "pending", "NA"],
    });
  };
  const filterByDate = (date) => {
    setStartDate(date);
    setEndDate(date);
    sudoFilter({
      start: date,
      end: date,
    });
  };
  const sudoFilter = (filter = {}) => {
    const {
      types = paymentMethods,
      start = startDate,
      end = endDate,
      selectedDate = dateFilter,
      selectedPlatform = platform,
    } = filter;

    let _start = new Date(start).setHours(0, 0, 0, 0);
    let _end = new Date(end).setHours(23, 59, 59, 999);

    if (!wasPasswordEntered && getDateDifference(_start, new Date()) > 1) {
      setProtected(true);
    }

    // data sorted
    let filteredOrders = orders.filter((order) => {
      return (
        adjustOrderDate(new Date(order.orderDate), CLOSING_HOURS) >= _start &&
        adjustOrderDate(new Date(order.orderDate), CLOSING_HOURS) <= _end
      );
    });
    // type sorted
    let validPaymentMethodOrders = filteredOrders.filter((order) =>
      types.includes(order.paymentmethod)
    );
    const otherPaymentMethodOrders = filteredOrders.filter((order) =>
      !types.includes(order.paymentmethod)
    );

    // platform
    if (selectedPlatform !== "all") {
      filteredOrders = filteredOrders.filter((order) =>
        order.invoiceNo.toLowerCase().includes(selectedPlatform)
      );
    }
    setFilteredData(validPaymentMethodOrders);
    setTotalAmount(calculateTotalAmount(validPaymentMethodOrders));
    getAnalytics(validPaymentMethodOrders);
    getOtherPaymentAnalytics(otherPaymentMethodOrders)
    return validPaymentMethodOrders;
  };

  const getAllOrders = async () => {
    const allOrders = await getOrderHistory();
    setFilteredData(allOrders);
    setTotalAmount(calculateTotalAmount(allOrders));
    return allOrders;
  };

  const getAnalytics = (orders) => {
    const canceledOrders = [];
    const discountedOrders = [];
    const pendingOrders = [];

    const payments = orders.reduce((acc, order) => {
      if (order.canceled) {
        canceledOrders.push(order);
        return acc;
      }
      if(order.paymentmethod === "pending")
      {
        pendingOrders.push(order);
        return acc;
      }
      if (order.discount > 0) {
        discountedOrders.push(order);
      }
      acc.push(
        ...order.partialAmoutsplit.map((bill) => ({
          ...bill,
          table: order.table,
          tips: order.tips,
        }))
      );
      return acc;
    }, []);
    console.log(payments)
    const totalBills = payments.length;
    const totalAmount = payments.reduce(
      (acc, bill) => acc + parseInt(bill.amount),
      0
    );
    const partners = {
      zomato: {
        bills: [],
        total: 0,
        totalbills: 0,
      },
      swiggy: {
        bills: [],
        total: 0,
        totalbills: 0,
      },
      parcel: {
        bills: [],
        total: 0,
        totalbills: 0,
      },
      tips: {
        bills: [],
        total: 0,
        totalbills: 0,
      },
      discount: {
        bills: [],
        total: 0,
        totalbills: 0,
      },
      canceled: {
        bills: [],
        total: 0,
        totalbills: 0,
      },
    };
    const paymentReduced = payments.reduce((acc, payment) => {
      if (payment.tips > 0) {
        partners["tips"].bills.push(payment);
        partners["tips"].total += parseInt(payment.tips);
        partners["tips"].totalbills = partners["parcel"].bills.length;
      }

      if (payment.billNo.toLowerCase().includes("zomato")) {
        partners["zomato"].bills.push(payment);
        partners["zomato"].total += parseInt(payment.amount);
        partners["zomato"].totalbills = partners["zomato"].bills.length;
        return acc;
      }

      if (payment.billNo.toLowerCase().includes("swiggy")) {
        partners["swiggy"].bills.push(payment);
        partners["swiggy"].total += parseInt(payment.amount);
        partners["swiggy"].totalbills = partners["swiggy"].bills.length;
        return acc;
      }

      if (payment.table.toLowerCase().includes("parcel")) {
        partners["parcel"].bills.push(payment);
        partners["parcel"].total += parseInt(payment.amount);
        partners["parcel"].totalbills = partners["parcel"].bills.length;
      }

      if (!acc[payment.name]) {
        acc[payment.name] = {
          bills: [],
          total: 0,
          totalbills: 0,
        };
      }

      acc[payment.name].bills.push(payment);
      acc[payment.name].total += parseInt(payment.amount);
      acc[payment.name].totalbills = acc[payment.name].bills.length;
      return acc;
    }, {});
    partners["discount"] = discountedOrders.reduce(
      (acc, order) => {
        acc.bills.push(order);
        acc.total += parseInt(order.amount);
        acc.totalbills = acc.bills.length;
        return acc;
      },
      {
        bills: [],
        total: 0,
        totalbills: 0,
      }
    );
    partners["canceled"] = canceledOrders.reduce(
      (acc, order) => {
        acc.bills.push(order);
        acc.total += parseInt(order.amount);
        acc.totalbills = acc.bills.length;
        return acc;
      },
      {
        bills: [],
        total: 0,
        totalbills: 0,
      }
    );
    const unsettled = pendingOrders.reduce((acc, payment) => {
      acc.bills.push(payment);
      acc.total += parseInt(payment.amount);
      acc.totalbills = acc.bills.length;
      return acc
    }, {
      bills: [],
      total: 0,
      totalbills: 0
    })
    console.log({ ...partners, ...paymentReduced, unsettled });
    setPendingBills(unsettled)
    console.log(unsettled)
    setAnalytics({ ...paymentReduced, unsettled, totalBills, totalAmount, partners });
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getOtherPaymentAnalytics = (orders) => {
    const canceledOrders = [];
    const discountedOrders = [];
    let totalAmount = 0;

    const payments = orders.reduce((acc, order) => {
      if (order.canceled) {
        canceledOrders.push(order);
        return acc;
      }
      if (order.discount > 0) {
        discountedOrders.push(order);
      }
      acc.push(
        ...order.partialAmoutsplit.map((bill) => ({
          ...bill,
          table: order.table,
          tips: order.tips,
        }))
      );
      return acc;
    }, []);
    const paymentReduced = payments.reduce((acc, payment) => {
      if (!acc[payment.name]) {
        acc[payment.name] = {
          name: capitalizeFirstLetter(payment.name),
          bills: [],
          total: 0,
          totalbills: 0,
        };
      }
      acc[payment.name].bills.push(payment);
      acc[payment.name].total += parseInt(payment.amount);
      acc[payment.name].totalbills = acc[payment.name].bills.length;
      totalAmount += parseInt(payment.amount);
      return acc
    }, {})
    console.log(paymentReduced)
    setOtherPaymentMethodsOrders(Object.values(paymentReduced));
    setOtherPaymentTotal(totalAmount);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cloudOrderHistory = await getOrderHistoryCloud();
        store.updateOrderHistory(cloudOrderHistory);
        const orders = await getAllOrders();
        orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
        setOrders(orders);
        setFilteredData(orders);

        if (new Date().getHours() < CLOSING_HOURS) {
          setDateFilter("Yesterday");
          filterByDate(
            formatDate(new Date(new Date().setDate(new Date().getDate() - 1)))
          );
        } else {
          setDateFilter("Today");
          filterByDate(formatDate(new Date()));
        }
      } catch (e) {
        console.groupCollapsed("Error in loading reports");
        console.log(e);
        console.groupEnd();
        SetError({
          message: e?.response?.data?.error || "Error in fetching report",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (orders.length > 0) {
      sudoFilter({});
    }
  }, [orders.length]);

  const unHideRevenue = (credentialResponse) => {
    if (credentialResponse.password === PASSWORD) {
      setProtected(false);
      setShowPasswordDialogue(false);
      setPasswordEntered(true);
    } else {
      toast.error("Wrong password");
    }
  };

  return (
    <div className="bg-gray-50 ">
      <PasswordDialog
        isOpen={showPasswordDialogue}
        onClose={() => setShowPasswordDialogue(false)}
        onSubmit={unHideRevenue}
      />

      <div className="max-w-[1400px] h-[calc(100vh)] w-[100vw] md:w-[calc(100vw-120px)] mx-auto p-4 box-border bg-gray-50 overflow-x-hidden relative hide-scrollbar">
        <div className="rounded-lg  h-full overflow-x-scroll hide-scrollbar relative space-y-2">
          {/* Header */}
          <div className="bg-white rounded-lg border border-gray-200 p-2 flex justify-between align-center">
            <div>
              <span className="text-[#ccc]"> Reporting Cycle </span>
              <h3 className="text-2xl">6:00 AM - 5:59 AM</h3>
            </div>

            {/* Date Filter */}
            <div className="relative" ref={filterRef}>
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
              >
                <Filter className="h-4 w-4 mr-2" />
                Filters
              </button>
              {isFilterOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-10 border border-gray-200">
                  <div className="p-4 space-y-4">
                    {/* Date Range */}
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Date Range
                      </label>
                      <div className="flex gap-2 ">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => {
                            console.log("chagened happed");
                            setStartDate(date);
                            setDateFilter("");
                            sudoFilter({
                              start: date,
                            });
                          }}
                          className="p-1 border rounded w-[120px]"
                          dateFormat="dd MMM yyyy"
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                            setDateFilter("");
                            sudoFilter({
                              end: date,
                            });
                          }}
                          className="p-1 border rounded w-[120px]"
                          dateFormat="dd MMM yyyy"
                        />
                      </div>

                      {/* Quick Date Filters */}
                      <div className="flex flex-wrap gap-2 mt-2">
                        {[
                          {
                            label: "Today",
                            action: () => filterByDate(formatDate(new Date())),
                          },
                          {
                            label: "Yesterday",
                            action: () =>
                              filterByDate(
                                formatDate(
                                  new Date(
                                    new Date().setDate(new Date().getDate() - 1)
                                  )
                                )
                              ),
                          },
                          {
                            label: "Last 7 Days",
                            action: () => filterLastNDays(7),
                          },
                          {
                            label: "Last 30 Days",
                            action: () => filterLastNDays(30),
                          },
                        ].map(({ label, action }) => (
                          <button
                            key={label}
                            onClick={() => {
                              setDateFilter(label);
                              action();
                            }}
                            className={`px-2 py-1 text-xs
                            ${dateFilter === label
                                ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                              }
                            rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors`}
                          >
                            {label}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Payment Methods */}
                    {FILTER_PAYMENT_ENABLE && <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Payment Method
                      </label>
                      <div className="flex flex-wrap gap-2">
                        {["Cash", "Online", "Card", "Partial", "Pending"].map(
                          (method) => (
                            <button
                              key={method}
                              onClick={() =>
                                handlePaymentMethodToggle(method.toLowerCase())
                              }
                              className={`px-3 py-1 text-sm rounded-md transition-colors ${paymentMethods.includes(method.toLowerCase())
                                  ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                                } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                            >
                              {method}
                            </button>
                          )
                        )}
                      </div>
                    </div>
                    }

                    {/* Platform Dropdown */}
                    {FILTER_PLATFORM_ENABLE && (
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Platform
                        </label>
                        <div className="relative">
                          <button
                            onClick={() =>
                              setIsPlatformDropdownOpen(!isPlatformDropdownOpen)
                            }
                            className="w-full px-3 py-2 text-left bg-white border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors hover:bg-gray-50"
                          >
                            <span className="flex items-center justify-between">
                              {platform === "all" ? "All Platforms" : platform}
                              <ChevronDown className="h-4 w-4 text-gray-400" />
                            </span>
                          </button>

                          {isPlatformDropdownOpen && (
                            <div className="absolute z-20 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
                              {["all", "zomato", "swiggy"].map((p) => (
                                <button
                                  key={p}
                                  onClick={() => {
                                    setPlatform(p);
                                    setIsPlatformDropdownOpen(false);
                                    sudoFilter({
                                      selectedPlatform: p,
                                    });
                                  }}
                                  className="block w-full px-4 py-2 text-sm text-left hover:bg-gray-50 first:rounded-t-md last:rounded-b-md transition-colors"
                                >
                                  {p === "all" ? "All Platforms" : p}
                                </button>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Dashboard stats */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-2">
            {/* Main Stats Card - takes 2 columns and 2 rows */}
            <div className="bg-white rounded-2xl border border-gray-200 p-4  col-span-2 md:col-span-3 lg:col-span-3  row-span-2 max-h-[200px]">
              <div className="space-y-4 flex flex-col justify-between h-full relative">
                <div>
                  {loading ? (
                    <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-blue-600 absolute top-[0px] right-[0px]" />
                  ) : isProtected ? (
                    <div className="h-8 w-8 absolute top-[0px] right-[0px]  ">
                      <EyeOff
                        className="cursor-pointer hover:stroke-blue-200"
                        onClick={() => setShowPasswordDialogue(true)}
                      />
                    </div>
                  ) : (
                    <div className="h-8 w-8  absolute top-[0px] right-[0px]">
                      <Eye
                        className="cursor-pointer hover:stroke-blue-200"
                        onClick={() => setProtected(true)}
                      />
                    </div>
                  )}
                  <h2 className="text-2xl font-medium">Total</h2>
                  <div className="text-4xl font-bold">
                    {!isProtected
                      ? ((analytics["totalAmount"] || 0) + otherPaymentTotal)?.toLocaleString("en-IN") ||
                      "0.00"
                      : "*****"}
                  </div>
                </div>

                <div className="grid grid-cols-4 gap-4 text-gray-600">
                  <div className="bg-gray-50 p-2 rounded-xl text-center">
                    <div className="text-2xl">
                      {!isProtected
                        ? analytics["cash"]?.total?.toLocaleString("en-IN") ||
                        "0.00"
                        : "*****"}
                    </div>
                    <div className="text-xl">cash</div>
                  </div>
                  <div className="bg-gray-50 p-2 rounded-xl text-center">
                    <div className="text-2xl">
                      {!isProtected
                        ? analytics["card"]?.total?.toLocaleString("en-IN") ||
                        "0.00"
                        : "*****"}
                    </div>
                    <div className="text-xl">card</div>
                  </div>
                  <div className="bg-gray-50 p-2 rounded-xl text-center">
                    <div className="text-2xl">
                      {!isProtected
                        ? analytics["online"]?.total?.toLocaleString("en-IN") ||
                        "0.00"
                        : "*****"}
                    </div>
                    <div className="text-xl">online</div>
                  </div>
                  <div className="bg-red-50 p-2 rounded-xl text-center">
                    <div className="text-2xl">
                      {!isProtected
                        ? pendingBills?.total?.toLocaleString("en-IN") ||
                        "0.00"
                        : "*****"}
                    </div>
                    <div className="text-xl">pending</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Location Stats - takes 1 column */}
            <div className="bg-white rounded-2xl border border-gray-200 p-6 flex items-center gap-3 relative col-span-2">
              <span class="inline-block px-3 py-1 text-sm font-medium rounded-full bg-gray-200 text-gray-800 absolute top-[5px] right-[5px]">
                Swiggy
              </span>
              <div className="w-8 h-8 rounded-full bg-orange-500 flex items-center justify-center">
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-xs">
                  Orders: {analytics["partners"]?.["swiggy"].totalbills || 0}
                </p>
                <span className="text-2xl font-medium">
                  {!isProtected
                    ? analytics["partners"]?.["swiggy"].total.toLocaleString(
                      "en-IN"
                    ) || "0.00"
                    : "*****"}
                </span>
              </div>
            </div>

            {/* Zomato Stats - takes 1 column */}
            <div className="bg-white rounded-2xl border border-gray-200 p-6 flex items-center gap-3 relative col-span-2">
              <span class="inline-block px-3 py-1 text-sm font-medium rounded-full bg-gray-200 text-gray-800 absolute top-[5px] right-[5px]">
                Zomato
              </span>
              <div className="w-8 h-8 bg-red-500 rounded-lg flex items-center justify-center">
                <span className="text-white text-xs font-bold">Z</span>
              </div>
              <div>
                <p className="text-xs">
                  Orders: {analytics["partners"]?.["zomato"].totalbills || 0}
                </p>
                <span className="text-2xl font-medium">
                  {" "}
                  {!isProtected
                    ? analytics["partners"]?.["zomato"].total.toLocaleString(
                      "en-IN"
                    ) || "0.00"
                    : "*****"}
                </span>
              </div>
            </div>

            {/* Total canceled - takes 2 column */}
            <div className="bg-white rounded-2xl border border-gray-200 p-4 col-span-1 relative">
              <span class="inline-block px-2 py-1 text-xs font-medium rounded-full bg-red-50 text-gray-800 absolute bottom-[5px] right-[5px]">
                orders : {analytics["partners"]?.["canceled"].totalbills}
              </span>
              <div className="space-y-1">
                <div className="text-sm text-gray-600">Canceled</div>
                <div className="text-2xl font-medium">
                  {!isProtected
                    ? analytics["partners"]?.["canceled"].total.toLocaleString(
                      "en-IN"
                    ) || "0.00"
                    : "*****"}
                </div>
              </div>
            </div>

            {/* Take away Stats - takes 1 column */}
            <div className="bg-white rounded-2xl border border-gray-200 p-6 flex items-center gap-3 relative col-span-2">
              <span class="inline-block px-3 py-1 text-sm font-medium rounded-full bg-gray-200 text-gray-800 absolute top-[5px] right-[5px]">
                Parcel
              </span>
              <div className="w-8 h-8 bg-red-500 rounded-lg flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="white"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-package-open"
                >
                  <path d="M12 22v-9" />
                  <path d="M15.17 2.21a1.67 1.67 0 0 1 1.63 0L21 4.57a1.93 1.93 0 0 1 0 3.36L8.82 14.79a1.655 1.655 0 0 1-1.64 0L3 12.43a1.93 1.93 0 0 1 0-3.36z" />
                  <path d="M20 13v3.87a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13" />
                  <path d="M21 12.43a1.93 1.93 0 0 0 0-3.36L8.83 2.2a1.64 1.64 0 0 0-1.63 0L3 4.57a1.93 1.93 0 0 0 0 3.36l12.18 6.86a1.636 1.636 0 0 0 1.63 0z" />
                </svg>
              </div>
              <div>
                <p className="text-xs">
                  Orders: {analytics["partners"]?.["parcel"].totalbills || 0}
                </p>
                <span className="text-2xl font-medium">
                  {" "}
                  {!isProtected
                    ? analytics["partners"]?.["parcel"].total.toLocaleString(
                      "en-IN"
                    ) || "0.00"
                    : "*****"}
                </span>
              </div>
            </div>

            {/* Total Orders - takes 1 column */}
            <div className="bg-white rounded-2xl border border-gray-200 p-4 col-span-1">
              <div className="space-y-1">
                <div className="text-sm text-gray-600">Total Orders</div>
                <div className="text-2xl font-medium">
                  {analytics["totalBills"] || "0"}
                </div>
              </div>
            </div>
            {/* Total Tips - takes 1 column */}
            <div className="bg-white rounded-2xl border border-gray-200 p-4 col-span-1">
              <div className="space-y-1">
                <div className="text-sm text-gray-600">Tips</div>
                <div className="text-2xl font-medium">
                  {analytics["partners"]?.["tips"].total.toLocaleString(
                    "en-IN"
                  ) || "0.00"}
                </div>
              </div>
            </div>
            {/* Total Discounts - takes 2 column */}
            <div className="bg-white rounded-2xl border border-gray-200 p-4 col-span-1">
              <div className="space-y-1">
                <div className="text-sm text-gray-600">Discounts</div>
                <div className="text-2xl font-medium">
                  {!isProtected
                    ? analytics["partners"]?.["discount"].total.toLocaleString(
                      "en-IN"
                    ) || "0.00"
                    : "*****"}
                </div>
              </div>
            </div>
            {/* other payments */}
            {
              otherPaymentMethodOrders.map((payment) => (
                <div className="bg-white rounded-2xl border border-gray-200 p-4 col-span-1 relative">
                  <span class="inline-block px-3 py-1 text-sm font-medium rounded-full bg-gray-200 text-gray-800 absolute bottom-[5px] right-[5px]">
                    {payment.totalbills}
                  </span>
                  <div className="space-y-1">

                    <div className="text-sm text-gray-600">{payment.name}</div>
                    <div className="text-2xl font-medium">
                      {!isProtected ? (payment.total || "0") : "*****"}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

          {/* Payment Tables */}
          <div className="grid md:grid-cols-3 gap-4">
            {["card", "online", "cash"].map((type) => (
              <div
                key={type}
                className="bg-white rounded-lg border border-gray-200 max-h-[400px] overflow-hidden overflow-y-scroll hide-scrollbar scrollbar-none"
              >
                <div className="p-4 border-b border-gray-200 flex justify-between sticky top-0 bg-white">
                  <h3 className="font-medium capitalize">{type} payment</h3>
                  <h3 className="font-medium capitalize">
                    {" "}
                    {analytics[type]?.totalbills || 0}{" "}
                  </h3>
                </div>
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-gray-200 sticky top-[57px] bg-white">
                      <th className="text-left px-4 py-2 text-sm font-medium text-gray-600">
                        Bill No
                      </th>
                      <th className="text-right px-4 py-2 text-sm font-medium text-gray-600">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {analytics[type]?.bills.map((bill) => (
                      <tr className="text-sm text-gray-600">
                        <td className="px-4 py-2">{bill.billNo}</td>
                        <td className="px-4 py-2 text-right">
                          {!isProtected ? bill.amount : "*****"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          {/* Sales Dashboard */}
          {/* <div className="bg-white rounded-2xl">
          <ItemWiseSell />
        </div> */}
        </div>
      </div>
    </div>
  );
}
