import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import FullscreenComponent from "../FullscreenComponent";

const Header = ({ toggleSidebar, orderDetails = true, setShowRunningKOT }) => {
  const name = localStorage.getItem("name");

  const loggedInitials = 'QR';

  return (
    <>
      <header className="m-auto px-6 flex items-center justify-between md:justify-end border-b border-[#D9D9D9] bg-white w-full p-1 h-[50px] transition-all duration-300 z-50">
        <button
          className="text-2xl text-gray-800 md:hidden"
          onClick={toggleSidebar}
        >
          ☰
        </button>
        <div className="flex justify-end items-center gap-[20px]">
          <FullscreenComponent />
          <span className="text-white bg-[#16a34a] py-1 px-1 rounded-md">
            {loggedInitials}
          </span>
        </div>
      </header>
    </>
  );
};

export default Header;