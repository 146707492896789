import React, { useEffect, useState } from "react";
import KOTSection from "./KOTSection";
import {
  getAllCategoryAndItem,
  getPublicItemBYVPA,
  getWaiterList,
  orderDone,
} from "./Service/KotStatusChange.service";
import OrderPayment from "./PaymentComponent/OrderPayment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import CategorySelection from "./OrderHandle/OrderHandle";
import store from "../../store";
import { observer } from "mobx-react-lite";
import useOrder from "../../hooks/useOrder";
import OrderMenu from "../../pages/OrderMenu";

const OrderHeader = ({
  data,
  handleClosePrompt,
  setEditOrder,
  onlineOrderKot,
}) => (
  <div className="flex items-center justify-between py-4 px-6 bg-gray-100">
    <h1 className="text-2xl font-semibold">
      {" "}
      {data.room}-Table-{data.table}
    </h1>
    <button
      className="flex items-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-200 ease-in-out"
      onClick={() => {
        if (onlineOrderKot[0]?.Kotitem.length > 0) {
          alert("Please Accept Digital Order !");
          return;
        }
        setEditOrder(true);
      }}
    >
      <FontAwesomeIcon icon={faEdit} className="mr-2" />
      Edit Order
    </button>
    <button
      className="text-gray-600 hover:text-red-600 bg-transparent p-3 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-300"
      onClick={handleClosePrompt}
    >
      <FontAwesomeIcon icon={faTimes} size="2x" />
    </button>
  </div>
);

const OrderEditHeader = ({ data, setEditOrder }) => (
  <div className="flex items-center justify-between py-4 px-6 bg-gray-100">
    <h1 className="text-2xl font-semibold">
      Table {data.table}, {data.room}
    </h1>
    <button
      className="text-gray-600 hover:text-red-600 bg-transparent p-3 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-300"
      onClick={() => setEditOrder(false)}
    >
      <FontAwesomeIcon icon={faTimes} size="2x" />
    </button>
  </div>
);

const OrderEditSection = ({
  menuListForOrder,
  data,
  printed,
  closeEdit,
  onlineOrderKot,
}) => (
  <CategorySelection
    menu={menuListForOrder}
    data={data}
    bookedOrder={printed[0] ? printed[0].items : ""}
    closeEdit={closeEdit}
    billNo={printed[0] ? printed[0].billNo : ""}
    oldKOTNotPrinted={onlineOrderKot[0]?.Kotitem}
  />
);

const OrderList = ({
  setLoading,
  onlineOrderKot,
  data,
  hotelData,
  DoneOrderBYbillNo,
  handleClosePrompt,
  userName,
  userMobile,
}) => (
  <div className="col-span-2">
    {onlineOrderKot &&
      onlineOrderKot.map((order, index) => (
        <KOTSection
          key={index}
          billNo={order.billNo}
          name={userName}
          items={order.Kotitem}
          orderId={order.id}
          oldOrder={order.oldOrder}
          Amount={order.TotalAmount}
          roomData={data}
          hotelData={hotelData}
          DoneOrderBYbillNo={DoneOrderBYbillNo}
          vpa={data.tableVPA}
          setLoading={setLoading}
          handleClosePrompt={handleClosePrompt}
        />
      ))}
  </div>
);

const OrderPaymentSection = ({
  onlineOrderKot,
  data,
  hotelData,
  DoneOrderBYbillNo,
  waiterlist,
  userName,
  userMobile,
  setUserName,
  setUserMobile,
}) => (
  <div className="bg-white shadow rounded">
    <OrderPayment
      order={onlineOrderKot}
      roomData={data}
      hotelData={hotelData}
      DoneOrderBYbillNo={DoneOrderBYbillNo}
      vpa={data.tableVPA}
      waiterlist={waiterlist}
      userName={userName}
      userMobile={userMobile}
      setUserName={setUserName}
      setUserMobile={setUserMobile}
    />
  </div>
);

const AcceptOrder = ({
  handleClosePrompt,
  data,
  printed,
  address,
  setLoading,
  syncCloud,
}) => {
  const { updateOrderNameMobile } = useOrder();
  const [onlineOrderKot, setOnlineOrderKot] = useState([]);
  const [waiterlist, setWaiterList] = useState([]);
  const [editOrder, setEditOrder] = useState(false);
  const [menuListForOrder, setMenuListForOrder] = useState();
  const [hotelData] = useState(address);
  const [availableItems, setAvailableItems] = useState();
  const [userName, setUserName] = useState();
  const [userMobile, setUserMobile] = useState();
  const setInputName = (username) => {
    setUserName(username);
    updateOrderNameMobile({ username, tableVPA: data.tableVPA }, "name");
  };
  const setInputMobile = (usermobile) => {
    setUserMobile(usermobile);

    updateOrderNameMobile({ usermobile, tableVPA: data.tableVPA }, "mobile");
  };

  const getUpdatedPrint = () => {
    const users = [];
    const tableInfo = data;
    const { tableVPA } = tableInfo;
    const { printedKOTsByTableVPA } = store;
    const printedKOT = printedKOTsByTableVPA[tableVPA];
    if (printedKOT) {
      const itemKOT = [];
      const printedItem = [];
      printedKOT.items.forEach((item) => {
        if (item.status === "RequestKOT") {
          itemKOT.push({
            id: item.itemId,
            name: item.name,
            price: item.price,
            quantity: parseInt(item.quantity),
            uniqueId: item.uniqueId,
            iskot: false,
            TotalAmount: printedKOT.amount,
          });
        } else if (item.status === "KOTPrint") {
          printedItem.push({
            id: item.itemId,
            name: item.name,
            price: item.price,
            quantity: parseInt(item.quantity),
            uniqueId: item.uniqueId,
            iskot: false,
            TotalAmount: printedKOT.amount,
          });
        }
      });
      users.push({
        name: printedKOT.name,
        mobile: printedKOT?.mobile,
        billNo: printedKOT.billNo,
        amount: printedKOT.amount,
        tableVPA: printedKOT.tableVPA,
        oldOrder: printedItem,
        Kotitem: itemKOT,
      });
    }

    setOnlineOrderKot(users);
    setUserName(users[0]?.name == "NA" ? "" : users[0]?.name);
    setUserMobile(users[0]?.mobile == "NA" ? "" : users[0]?.mobile);
    if (users.length <= 0) {
      setEditOrder(true);
    }
  };

  useEffect(() => {
    getUpdatedPrint();
    setAvailableItems(store.items);
    setWaiterList([...store.waiters]);
    setMenuListForOrder(store.completeMenu);
  }, [store.reload]);

  useEffect(() => {
    window.addEventListener("KOTBillGenerated", getUpdatedPrint);
    return () => {
      window.removeEventListener("KOTBillGenerated", getUpdatedPrint);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("KOTUpdated", getUpdatedPrint);
    return () => {
      window.removeEventListener("KOTUpdated", getUpdatedPrint);
    };
  }, [store.reload]);

  const DoneOrderBYbillNo = async (billNo) => {
    setOnlineOrderKot((prevOrders) =>
      prevOrders.filter((order) => order.billNo !== billNo)
    );
    handleClosePrompt();
    setTimeout(() => {
      syncCloud();
    }, 2000);
  };

  const closeEdit = () => {
    setEditOrder(false);
    handleClosePrompt();
  };

  return (
    <div>
      {/* {!editOrder && (
        <OrderHeader
          data={data}
          handleClosePrompt={handleClosePrompt}
          setEditOrder={setEditOrder}
          onlineOrderKot={onlineOrderKot}
        />
      )} */}
      {editOrder && <OrderEditHeader data={data} setEditOrder={setEditOrder} />}

        <OrderMenu
          menuListForOrder={menuListForOrder}
          data={data}
          printed={printed}
          closeEdit={closeEdit}
          onlineOrderKot={onlineOrderKot}
        />
      
      {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-2">
        <OrderList
          onlineOrderKot={onlineOrderKot}
          data={data}
          setLoading={setLoading}
          hotelData={hotelData}
          DoneOrderBYbillNo={DoneOrderBYbillNo}
          handleClosePrompt={handleClosePrompt}
          userName={userName}
          userMobile={userMobile}
        />
        {onlineOrderKot[0] && (
          <OrderPaymentSection
            onlineOrderKot={onlineOrderKot}
            data={data}
            hotelData={hotelData}
            DoneOrderBYbillNo={DoneOrderBYbillNo}
            waiterlist={waiterlist}
            userName={userName}
            userMobile={userMobile}
            setUserName={setInputName}
            setUserMobile={setInputMobile}
          />
        )}
      </div> */}
    </div>
  );
};

export default observer(AcceptOrder);
