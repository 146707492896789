import React from "react";
import logo from "../../img/logo.png";
import tableIcon from "../../img/tableIcon.svg";
import dashboardIcon from "../../img/dashboardIcon.svg";
import menuIcon from "../../img/menuIcon.svg";
import orderIcon from "../../img/orderIcon.svg";
import reportsIcon from "../../img/reportsIcon.svg";
import settingIcon from "../../img/settingIcon.svg";
import { NavLink } from "react-router-dom";
import { useCloud } from "../../hooks/useCloud";
import { useNavigate } from 'react-router-dom';
import { CircleX } from "lucide-react"


const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const { syncCloud } = useCloud();

  const handleLogOut = async () => {
    await syncCloud
    localStorage.removeItem("authToken");
    navigate('/login');

  }

  const renderSidebarContent = () => {
    return (
      <>
        <div className="">
          <NavLink to="/" className="flex flex-col items-center py-6" onClick={toggleSidebar}>
            <img src={logo} alt="Logo" className="h-10 mb-2" />
            <h1 className="text-xl font-bold text-green-600">QRAPID</h1>
          </NavLink>
        </div>
        <nav className="qrapid-sidebar p-4 md:p-0 space-y-2 md:space-y-0 flex flex-col gap-[5px] text-md">
          <NavLink
            to="/table-overview"
            onClick={toggleSidebar}
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-table"></i> */}
            <img src={tableIcon} alt="table icon" />
            <span>Table</span>
          </NavLink>
          <NavLink
            to="/dashboard"
            onClick={toggleSidebar}
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-chart-line"></i> */}
            <img src={dashboardIcon} alt="dashboard icon" />
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            to="/menu-managment"
            onClick={toggleSidebar}
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-utensils"></i> */}
            <img src={menuIcon} alt="menu icon" />
            <span>Menu</span>
          </NavLink>
          <NavLink
            to="/order-main"
            onClick={toggleSidebar}
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-receipt"></i> */}
            <img src={orderIcon} alt="order icon" />
            <span>Orders</span>
          </NavLink>
          <NavLink
            to="/order-list-lifetime"
            onClick={toggleSidebar}
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-file-alt"></i> */}
            <img src={reportsIcon} alt="reports icon" />
            <span>Reports</span>
          </NavLink>
          <NavLink
            to="/setting"
            onClick={toggleSidebar}
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-cog"></i> */}
            <img src={settingIcon} alt="setting icon" />
            <span>Settings</span>
          </NavLink>
        </nav>
        <div className="flex items-center flex-col justify-center mt-8">
          <button
            onClick={handleLogOut}
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
          >
            Logout
          </button>
        </div>

      </>
    )
  }

  return (
    <>
      {/* Mobile Sidebar (Drawer) */}
      <aside
        id="mobile-sidebar"
        className={`fixed md:hidden w-[80%] h-screen bg-white border-r border-[#D9D9D9] text-black z-100 transition-transform duration-300 ${isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        style={{
          zIndex: 500
        }}
      >
        <div className="relative">
          <button
            className="text-2xl text-gray-800 md:hidden absolute top-2 right-2"
            onClick={toggleSidebar}
          >
            <CircleX className="stroke-gray-300" />
          </button>
        </div>
        {renderSidebarContent()}
      </aside>

      {/* Desktop Sidebar */}
      <aside className="hidden md:block w-[120px] h-screen bg-white border-r border-[#D9D9D9] text-black z-100" style={{
        zIndex: 500
      }}>
        {renderSidebarContent()}
      </aside>
    </>
  );
};

export default Sidebar;