import { useState, useEffect, useRef, useCallback } from "react";
import { Search, MoveLeft, UserRoundPen, ChevronDownIcon, ReceiptText, Play, TriangleAlert } from "lucide-react";
import store from "../store/index";
import { observer } from "mobx-react-lite";
import { isValidNumber } from "../utils/common";
import useOrder from "../hooks/useOrder";
import { useCloud } from "../hooks/useCloud.js";
import toast from "react-hot-toast";
import { printKOT } from "../Component/Print/PrintKOT.js";
import { PrintBills } from "../Component/Print/PrintBill.js";
import PasswordDialog from "../Component/PasswordInput";
import WarningDialog from "../Component/WarningDialog";
import CategoryChefIcon from "../assets/images/category-chef.svg";
import CategoryChilliIcon from "../assets/images/category-chilli.svg";
import CategoryNonvegIcon from "../assets/images/category-nonveg.png";
import CategoryVegIcon from "../assets/images/category-veg.png";
import CategoryBestSellerIcon from "../assets/images/category-bestseller.svg";
import CategoryStarIcon from "../assets/images/category-star.svg";
import { json } from "react-router-dom";

const OrderMenu = ({
  data,
  isEditOrder = false,
  printed,
  closeEdit,
  onlineOrderKot,
}) => {
  const { completeMenu: menuListForOrder, orderCategories, products } = store;

  const {
    processOrder,
    getOnlineOrderBillNo,
    cancelOrder,
    rejectOnlineOrder,
    processManualOrder,
    orders,
    getAllOrders,
    generateOrderBill,
    completeOrderBill,
    flushAllCompleteOrders,
  } = useOrder();

  const [productDetailsMap] = useState(() =>
    store.items.reduce((acc, product) => {
      acc[product.id] = product;
      return acc
    }, {})
  )

  const { syncCloud, orderReject, changeKOTStatus } = useCloud();
  const [categories, setCategories] = useState(orderCategories);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [cart, setCart] = useState([]);
  const [KOTPendingItems, setKOTPendingItems] = useState([]);
  const [KOTPrintedItems, setKOTPrintedItems] = useState([]);
  const [KOTRequestedItems, setKOTRequestedItems] = useState([])
  const [KOTCancelledItems, setKOTCancelledItems] = useState([])
  const [printedKOTExtend, setPrintedKOTExtend] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const [showExtra, setShowAddExtra] = useState(false);
  const [extraItemName, setExtraItemName] = useState("");
  const [extraItemPrice, setExtraItemPrice] = useState('');
  const [extraItemQuantity, setExtraItemQuantity] = useState('');

  const [categoryIndex, setCategoryIndex] = useState(null);
  const [productIndex, setProductIndex] = useState(null);
  const [variantIndex, setVariantIndex] = useState(null);

  const [showCartDetails, setShowCartDetails] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [flatDiscount, setFlatDiscount] = useState(0);
  const [percentageDiscount, setPercentageDiscount] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [lastAddedItem, setLastAddedItem] = useState(null);
  const [isGST] = useState(store.restaurantInfo.isGST);
  const [gstItemType] = useState(store.restaurantInfo.gstItemType);
  const [sgst] = useState(store.restaurantInfo.sgst);
  const [cgst] = useState(store.restaurantInfo.cgst);
  const [serviceCharge] = useState(store.restaurantInfo.serviceCharge);
  const [showCustomerEditForm, setShowCustomerEditForm] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("")
  const [billNo, setBillNo] = useState("");
  const [tip, setTip] = useState('');
  const [tipErr, setTipErr] = useState(false);
  const [showAdminControlPasswordDialogue, setShowAdminControlPasswordDialogue] =
    useState(false);
  const [adminControl, setAdminControl] = useState(false);
  const [adminControlTimer, setAdminControlTimer] = useState(60);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [orderCGST, setOrderCGST] = useState(0);
  const [orderSGST, setOrderSGST] = useState(0);
  const [orderServiceTax, setOrderServiceTax] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderBillAmount, setOrderBillAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [amountPaid, setAmountPaid] = useState('');

  const [orderReturnChange, setOrderReturnChange] = useState(0);
  const [orderWaiter, setOrderWaiter] = useState('none')
  const [orderPaymentMethod, setOrderPaymentMethod] = useState("");
  const [orderOtherPaymentMethod, setOrderOtherPaymentMethod] = useState('Zomato Dining/District')
  const [partialOnlinePayment, setPartialOnlinePayment] = useState('');
  const [partialCardPayment, setPartialCardPayment] = useState('');
  const [partialCashPayment, setPartialCashPayment] = useState('');
  const [partialOnlinePaymentErr, setPartialOnlinePaymentErr] = useState(false);
  const [partialCardPaymentErr, setPartialCardPaymentErr] = useState(false);
  const [partialCashPaymentErr, setPartialCashPaymentErr] = useState(false);
  const [selectedCartItem, setSelectedCartItem] = useState(null);
  const [backWarning, setBackWarning] = useState(false)

  const partialOnlinePaymentRef = useRef(partialOnlinePayment || 0);
  const partialCardPaymentRef = useRef(partialCashPayment || 0);
  const partialCashPaymentRef = useRef(partialCashPayment || 0);

  const [percentageDiscountErr, setPercentageDiscountErr] = useState(false);
  const [flatDiscountErr, setFlatDiscountErr] = useState(false);
  const [forceCalculation, setForceCalculation] = useState('');
  const [orderStatus, setOrderStatus] = useState("");

  const filteredProductsRef = useRef(filteredProducts);
  const categoriesRef = useRef(categories);
  const searchInputRef = useRef(null);
  const menuRef = useRef(null);
  const showCustomerEditFormRef = useRef(
    showCustomerEditForm ? "open" : "close"
  );
  const showExtraRef = useRef(showExtra ? "open" : "close");
  const selectedProductRef = useRef(selectedProduct);
  const variantIndexRef = useRef(variantIndex);
  const cartRef = useRef(cart);
  const KOTPendingItemsRef = useRef(KOTPendingItems);
  const KOTRequestedItemsRef = useRef(KOTRequestedItems);
  const KOTCancelledItemsRef = useRef({})
  const productIndexRef = useRef(productIndex);
  const orderStatusRef = useRef(orderStatus);
  const adminControlRef = useRef(adminControl)
  const callBackRef = useRef(null)
  const cancelOrderReasonRef = useRef(false)


  const fetchExistingOrder = () => {
    const orders = store.runningKOTs
    const runningOrder = orders.find(
      (order) => order.tableVPA === data.tableVPA
    );
    if (runningOrder) {
      const {
        usermobile,
        username,
        userAddress,
        printKOT,
        cancleOrders,
        billNo,
        status,
        billDetails,
      } = runningOrder;
      setCustomerName(username);
      setCustomerPhone(usermobile);
      setCustomerAddress(userAddress);
      const cartItems = JSON.parse(JSON.stringify(printKOT.items))
      setCart(cartItems);
      console.log(cartItems)
      setKOTPrintedItems(JSON.parse(JSON.stringify(cartItems.filter(item => item.status == "KOTPrint"))));
      setKOTRequestedItems(JSON.parse(JSON.stringify(cartItems.filter(item => item.status == "RequestKOT"))));
      setBillNo(billNo);
      setOrderStatus(status);
      orderStatusRef.current = status

      if (status === "KOTBillGenerated") {
        setIsExpanded(true);
      }

      if (billDetails) {
        const {
          gst,
          gstNo,
          cgstPercent,
          sgstPercent,
          cgst,
          sgst,
          gstExcluded,
          service,
          total,
          subTotal,
          discount,
          discountPercent,
          discountFlat,
          tip,
        } = billDetails;

        if (isGST) {
          setOrderCGST(cgst);
          setOrderSGST(sgst);
          setOrderServiceTax(service);
        }

        setOrderBillAmount(total);
        setSubTotal(subTotal);
        setFlatDiscount(discountFlat);
        setPercentageDiscount(discountPercent);
        setTotalDiscount(parseInt(discount));
        setTip(tip);
      }
    }
  };
  useEffect(() => {
    fetchExistingOrder();
  }, []);

  const handleNewDigitalOrderReceived = async () => {
    const orders = await getAllOrders();
    const runningOrder = orders.find(
      (order) => order.tableVPA === data.tableVPA
    );
    if (runningOrder) {
      const {
        printKOT,
      } = runningOrder;

      const cartItems = JSON.parse(JSON.stringify([printKOT.items, ...KOTPendingItems]))
      setCart(cartItems);
      setKOTPrintedItems(cartItems.filter(item => item.status == "KOTPrint"));
      setKOTRequestedItems(cartItems.filter(item => item.status == "RequestKOT"));
    }
  }
  useEffect(() => {
    productIndexRef.current = productIndex;
  }, [productIndex])

  useEffect(() => {
    window.addEventListener("DIGITAL_ORDER_RECEIVED", handleNewDigitalOrderReceived)
    return window.removeEventListener("DIGITAL_ORDER_RECEIVED", handleNewDigitalOrderReceived)
  })
  useEffect(() => {
    if (isEditOrder) {
      console.log("this is the editing order");
    }
  });

  useEffect(() => {
    showCustomerEditFormRef.current = showCustomerEditForm ? "open" : "close";
  }, [showCustomerEditForm]);

  useEffect(() => {
    showExtraRef.current = showExtra ? "open" : "close";
  }, [showExtra]);

  useEffect(() => {
    setFilteredProducts((prev) => {
      return products.filter((product) => {
        const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase()) || product.id.toString().includes(searchQuery.toLowerCase());
        if (searchQuery.length > 0) {
          console.log(searchQuery)
          return matchesSearch
        }
        const matchesCategory = product.category === selectedCategory;
        return matchesSearch && matchesCategory;
      });
    });
  }, [categoryIndex, selectedCategory, products, searchQuery]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    categoriesRef.current = categories;
  }, [categories, categories.length]);

  useEffect(() => {
    filteredProductsRef.current = filteredProducts;
  }, [filteredProducts, filteredProducts.length]);

  useEffect(() => {
    if (lastAddedItem) {
      const itemElement = document.getElementById(
        `cart-item-${lastAddedItem.id}-${lastAddedItem.variantId}`
      );
      if (itemElement) {
        itemElement.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => setLastAddedItem(null), 300);
      }
    }
  }, [lastAddedItem]);

  useEffect(() => {
    selectedProductRef.current = selectedProduct;
  }, [selectedProduct]);

  useEffect(() => {
    cartRef.current = cart
  }, [cart.length])

  useEffect(() => {
    cartRef.current = cart;
    const {
      subtotal,
      calculated_cgst,
      calculated_sgst,
      serviceTax,
      total,
      discount,
      orderBillAmount,
    } = getBillDetails();

    setSubTotal(subtotal);
    setOrderCGST(calculated_cgst);
    setOrderSGST(calculated_sgst);
    setOrderServiceTax(serviceTax);
    setOrderTotal(total);

    setTotalDiscount(parseInt(discount));
    setOrderBillAmount(orderBillAmount);

    if (
      orderPaymentMethod === "Partial" &&
      partialCardPayment + partialCashPayment + partialOnlinePayment !=
      orderBillAmount
    ) {
      setPartialCardPaymentErr(true);
      setPartialCashPaymentErr(true);
      setPartialOnlinePaymentErr(true);
    }
  }, [cart.length, flatDiscount, percentageDiscount, tip, forceCalculation]);

  useEffect(() => {
    if (
      orderPaymentMethod === "Partial" &&
      partialCardPayment + partialCashPayment + partialOnlinePayment !=
      orderBillAmount
    ) {
      setPartialCardPaymentErr(true);
      setPartialCashPaymentErr(true);
      setPartialOnlinePaymentErr(true);
    } else {
      setPartialCardPaymentErr(false);
      setPartialCashPaymentErr(false);
      setPartialOnlinePaymentErr(false);
    }
  }, [orderPaymentMethod]);

  useEffect(() => {
    if (!adminControl) return;
    if (adminControlTimer <= 0) return;

    const intervalId = setInterval(() => {
      setAdminControlTimer((prev) => {
        if (prev === 0) {
          setAdminControl(false);
          adminControlRef.current = false
          clearInterval(intervalId);
        }
        return (prev -= 1);
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [adminControl]);

  useEffect(() => {
    KOTPendingItemsRef.current = KOTPendingItems
  }, [KOTPendingItems])

  useEffect(() => {
    KOTRequestedItemsRef.current = KOTRequestedItems
  }, [KOTRequestedItems])

  const getBillDetails = () => {
    const cart = cartRef.current;
    const subtotal = cart.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    let calculated_cgst = 0;
    let calculated_sgst = 0;
    let serviceTax = 0
    if (isGST) {
      calculated_cgst = Math.round((subtotal * parseFloat(cgst || 0)) / 100);
      calculated_sgst = Math.round((subtotal * parseFloat(sgst || 0)) / 100);
      serviceTax =
        parseInt(serviceCharge || 0) > 0 &&
        Math.round(subtotal * (parseFloat(serviceCharge) / 100));
    }

    let total = subtotal + serviceTax;

    if (gstItemType === "Exclude") {
      total += + calculated_cgst + calculated_sgst;
    }

    const discount = flatDiscount + total * (percentageDiscount / 100);
    let orderBillAmount = Math.round(total - discount);
    orderBillAmount += tip;



    setSubTotal(subtotal);
    setOrderCGST(calculated_cgst);
    setOrderSGST(calculated_sgst);
    setOrderServiceTax(serviceTax);
    setOrderTotal(total);

    setTotalDiscount(parseInt(discount));
    setOrderBillAmount(orderBillAmount);


    return {
      subtotal,
      calculated_cgst,
      calculated_sgst,
      serviceTax,
      total,
      discount,
      orderBillAmount,
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      if (showExtraRef.current == 'open') {
        setShowAddExtra(false);
        return
      }

      if (showCustomerEditFormRef.current == 'open') {
        setShowCustomerEditForm(false);
        return;
      }

      if (selectedProductRef.current) {
        setSelectedProduct(null);
        setVariantIndex(0);
        variantIndexRef.current = 0;
        return
      }

      handleCloseOrderMenu()

    }

    if (showAdminControlPasswordDialogue) {
      return;
    }
    if (orderStatus === "KOTBillGenerated") {
      return;
    }


    if (
      showCustomerEditFormRef.current === "open" ||
      showExtraRef.current === "open"
    ) {
      return e;
    }
    if (selectedProductRef.current?.variant.length > 0) {
      if (
        e.key === "ArrowDown" ||
        e.key === "ArrowUp" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight"
      ) {
        const key = e.key;
        const numColumns = 2;
        const productLength = selectedProductRef.current?.variant.length;
        let currentIndex = variantIndexRef.current;

        switch (key) {
          case "ArrowDown":
            // Move to the next row (ensure it stays within limits)
            if (currentIndex + numColumns < productLength) {
              currentIndex += numColumns;
            } else {
              currentIndex = productLength - 1;
            }
            break;
          case "ArrowUp":
            // Move to the previous row (ensure it stays within limits)
            if (currentIndex - numColumns >= 0) {
              currentIndex -= numColumns;
            } else {
              currentIndex = 0;
            }
            break;
          case "ArrowLeft":
            // Move to the previous column (ensure it stays within limits)
            if (currentIndex % numColumns > 0) {
              currentIndex -= 1;
            }
            break;
          case "ArrowRight":
            // Move to the next column (ensure it stays within limits)
            if (
              currentIndex % numColumns < numColumns - 1 &&
              currentIndex + 1 < productLength
            ) {
              currentIndex += 1;
            }
            break;
          default:
            break;
        }

        setVariantIndex(currentIndex);
        variantIndexRef.current = currentIndex;
      }

      if (e.key === "Enter") {
        setSelectedVariants((prev) => {
          if (prev.length == 0) {
            const variant = selectedProductRef.current.variant[variantIndexRef.current];
            addToCart(selectedProductRef.current, [variant]);
          } else {
            addToCart(selectedProductRef.current, prev);
          }
          setVariantIndex(0);
          variantIndexRef.current = 0;
          return prev;
        });
      }

      return;
    }
    if (e.shiftKey) {
      if (e.key === "ArrowDown") {
        setCategoryIndex((prev) => {
          if (prev < categoriesRef.current.length - 1) prev += 1;
          setSelectedCategory(categoriesRef.current[prev]);
          return prev;
        });
      }
      if (e.key === "ArrowUp") {
        setCategoryIndex((prev) => {
          if (prev > 0) prev -= 1;
          setSelectedCategory(categoriesRef.current[prev]);
          return prev;
        });
      }
      setProductIndex(0);
      return;
    }

    if (
      e.key === "ArrowDown" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight"
    ) {
      const key = e.key;

      const numColumns = 4;
      setProductIndex((prev) => {
        let currentIndex = prev;
        console.log(filteredProductsRef.current);
        const productLength = filteredProductsRef.current.length;
        console.log({
          productLength,
          numColumns,
          currentIndex,
        });

        switch (key) {
          case "ArrowDown":
            // Move to the next row (ensure it stays within limits)
            if (currentIndex + numColumns < productLength) {
              currentIndex += numColumns;
            } else {
              currentIndex = productLength - 1;
            }
            break;
          case "ArrowUp":
            // Move to the previous row (ensure it stays within limits)
            if (currentIndex - numColumns >= 0) {
              currentIndex -= numColumns;
            } else {
              currentIndex = 0;
            }
            break;
          case "ArrowLeft":
            // Move to the previous column (ensure it stays within limits)
            if (currentIndex % numColumns > 0) {
              currentIndex -= 1;
            }
            break;
          case "ArrowRight":
            // Move to the next column (ensure it stays within limits)
            if (
              currentIndex % numColumns < numColumns - 1 &&
              currentIndex + 1 < productLength
            ) {
              currentIndex += 1;
            }
            break;
          default:
            break;
        }

        return currentIndex;
      });

      return;
    }

    if (e.key === "Enter" && productIndexRef.current !== null) {
      setProductIndex((prev) => {
        const productIndex = prev;
        if (productIndex < filteredProductsRef.current.length) {
          const product = filteredProductsRef.current[productIndex];
          if (product?.variant.length > 0) {
            setSelectedProduct(product);
            selectedProductRef.current = product;
          } else {
            addToCart(product);
          }
        }
        return prev;
      });
      return;
    }

    if (e.ctrlKey) {
      if (e.key === "s") {
        searchInputRef.current.focus();
      }
    }
  };
  const trackKOTPendingItems = (product, increment, remove = false) => {
    console.log({
      product,
      increment,
      remove,
    });

    // delete from pending KOT
    if (remove) {
      setKOTPendingItems((prev) => {
        return prev.filter((item) => {
          if (item.id === product.id && product.variantId === item.variantId) {
            return false;
          }
          return true;
        });
      });
      return;
    }

    // update quantity if exit or add to pending list
    setKOTPendingItems((prev) => {
      const isExist = prev.find(
        (item) => item.id === product.id && product.variantId === item.variantId
      );
      if (isExist) {
        prev.forEach((item) => {
          if (item.id === product.id && product.variantId === item.variantId) {
            item.quantity = increment
              ? item.quantity + 1
              : Math.max(1, item.quantity - 1);
          }
        });
      } else {
        return [...prev, { ...product, status: "" }];
      }
      return prev;
    });
    setPrintedKOTExtend(false);
    console.log(KOTPendingItems);
  };
  const addToCart = (_product, _variants = []) => {

    const product = JSON.parse(JSON.stringify(_product))
    const variants = JSON.parse(JSON.stringify(_variants))

    return new Promise((resolve, reject) => {
      if (!adminControlRef.current && orderStatusRef.current === "KOTBillGenerated") {
        toast.error("Access denied, Bill is generated. Use admin control to update");
        setShowAdminControlPasswordDialogue(true)
        callBackRef.current = {
          func: addToCart,
          parameters: [_product, _variants]
        }
        resolve(false);
        return;
      }

      setCart((prev) => {
        if (variants.length === 0) {
          setLastAddedItem({
            id: product.id,
            variantId: "",
          });
          const existingItem = prev.find(
            (item) => item.id === product.id && !item.variant
          );
          if (existingItem) {
            return prev.map((item) => {
              if (item.id === product.id && !item.variant) {
                trackKOTPendingItems({ ...item, quantity: 1 }, true);
                return { ...item, quantity: item.quantity + 1, readyAt: false };
              }
              return item;
            });
          }

          const item = {
            id: product.id,
            name: product.name,
            quantity: product.quantity || 1,
            price: product.price,
            variantId: "",
            extra: product.extra || false,
            canceled: false,
            isVariant: false,
            uniqueId: `${product.id}`,
            createdAt: new Date(),
            readyAt: false
          };
          trackKOTPendingItems(item, false);
          return [...prev, item];
        } else {
          const newItems = [];
          variants.forEach((_variant) => {
            setLastAddedItem({
              id: product.id,
              variantId: _variant.id,
            });

            const index = prev.findIndex(
              (item) => item.id === product.id && item.variantId === _variant.id
            );

            if (index != -1) {
              trackKOTPendingItems({ ...prev[index], quantity: 1 }, true);
              prev[index].quantity += 1;
              prev[index].readyAt = false
            } else {
              const item = {
                id: product.id,
                name: product.name,
                variant: _variant.varientName,
                variantId: _variant.id,
                quantity: 1,
                price: _variant.varientAmount,
                extra: product.extra || false,
                canceled: false,
                isVariant: true,
                uniqueId: `${product.id}_${_variant.id}`,
                createdAt: new Date(),
                readyAt: false
              };
              trackKOTPendingItems(item, false);
              newItems.push(item);
            }
          });
          return [...prev, ...newItems];
        }
      });
      setSelectedProduct(null);
      setSelectedVariants([]);
      setShowAddExtra(false);
      setExtraItemName("");
      setExtraItemPrice(0);
      setForceCalculation((prev) => prev + 1);
      resolve(true);
    });
  };
  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    addToCart(selectedProduct, selectedVariants);
  };
  const updateQuantity = (item, increment) => {
    if (!adminControlRef.current && !increment) {
      if (orderStatus === "KOTBillGenerated") {
        toast.error("Access denied : Bill is generated. Use admin control to update");
        callBackRef.current = {
          func: updateQuantity,
          parameters: [item, increment]
        }
        setShowAdminControlPasswordDialogue(true)
        return;
      }

      if (item.status === "KOTPrint") {
        toast.error("Access denied: KOT already printed for this Item. Use admin control to update");
        callBackRef.current = {
          func: updateQuantity,
          parameters: [item, increment]
        }
        setShowAdminControlPasswordDialogue(true)
        return;
      }
    }

    // RULE if quantity is 1 and still decremented it should be removed
    if (item.quantity == 1 && !increment) {
      removeItem(item)
      return;
    }

    setCart(
      cart.map((_item) => {
        if (_item.id === item.id && _item.variantId == item.variantId) {
          if (!increment && item.status === 'KOTPrint') {
            trackCancelledItems(item)
          } else
            trackKOTPendingItems({ ..._item, quantity: 1 }, increment);
          return {
            ..._item,
            readyAt: false,
            quantity: increment
              ? item.quantity + 1
              : Math.max(1, item.quantity - 1),
          };
        } else {
          return _item;
        }
      })
    );
    if (orderStatus != "") {
      setKOTPrintedItems((prev) => {
        return prev.map((_item) => {
          if (_item.id === item.id && _item.variantId == item.variantId) {
            return {
              ..._item,
              readyAt: false,
              quantity: increment
                ? item.quantity + 1
                : Math.max(1, item.quantity - 1),
            };
          } else {
            return _item;
          }
        });
      });
    }
    setForceCalculation((prev) => prev + 1);
  };
  const removeItem = (item) => {
    if (!adminControlRef.current) {
      if (orderStatus === "KOTBillGenerated") {
        toast.error("Access denied, Bill is generated, Use admin control to update");
        callBackRef.current = {
          func: removeItem,
          parameters: [item]
        }
        setShowAdminControlPasswordDialogue(true)
        return;
      }

      if (item.status === "KOTPrint") {
        toast.error("Access denied : Can't remove, KOT already printed for this Item, Use admin control to update");
        callBackRef.current = {
          func: removeItem,
          parameters: [item]
        }
        setShowAdminControlPasswordDialogue(true)
        return;
      }
    }

    setCart((prev) => {
      const filtered = prev.filter((_item) => {
        if (_item.id == item.id && _item.variantId == item.variantId) {
          if (item.status === 'KOTPrint')
            trackCancelledItems(item)
          return false;
        }
        return true;
      });
      return filtered;
    });

    if (orderStatus != "") {
      setKOTPrintedItems((prev) => {
        const filtered = prev.filter((_item) => {
          if (_item.id == item.id && _item.variantId == item.variantId) {
            return false;
          }
          return true;
        });
        return filtered;
      });
    }

    trackKOTPendingItems(item, false, true);

    setForceCalculation((prev) => prev + 1);
  };
  const handleSaveCustomerDetails = () => {
    setShowCustomerEditForm(false);
    saveManualOrder()
  };
  const handlePercentageDiscountChange = (e) => {
    const value = e.target.value || 0;
    // Validate if the value is a valid number between 0 and 100
    if (isValidNumber(value, 0, 100)) {
      setPercentageDiscount(parseInt(value));
    } else {
      setPercentageDiscountErr(true);
    }
    setTimeout(() => {
      setPercentageDiscountErr(false);
    }, 500);
  };
  const handleFlatDiscountChange = (e) => {
    const value = e.target.value || 0;
    // Validate if the value is a valid number between 0 and 100
    if (isValidNumber(value, 0, orderTotal)) {
      setFlatDiscount(parseInt(value));
    } else {
      setFlatDiscountErr(true);
    }
    setTimeout(() => {
      setFlatDiscountErr(false);
    }, 500);
  };
  const handleTipChange = (e) => {
    const value = e.target.value || 0;
    // Validate if the value is a valid number between 0 and 100
    if (isValidNumber(value, 0, orderTotal)) {
      setTip(parseInt(value));
    } else {
      setTipErr(true);
    }
    setTimeout(() => {
      setTipErr(false);
    }, 500);
  };
  const handlePartialPaymentChange = (e, type) => {
    let payment = e.target.value || 0;
    payment = parseInt(payment);

    switch (type) {
      case "Card":
        if (
          isValidNumber(
            payment +
            partialCashPaymentRef.current +
            partialOnlinePaymentRef.current,
            0,
            orderBillAmount
          )
        ) {
          setPartialCardPayment(e.target.value);
          partialCardPaymentRef.current = payment;
        } else {
          setPartialCardPaymentErr(true);
        }
        break;
      case "Cash":
        if (
          isValidNumber(
            partialCardPaymentRef.current +
            payment +
            partialOnlinePaymentRef.current,
            0,
            orderBillAmount
          )
        ) {
          setPartialCashPayment(e.target.value);
          partialCashPaymentRef.current = payment;
        } else {
          setPartialCashPaymentErr(true);
        }
        break;
      case "Online":
        if (
          isValidNumber(
            partialCardPaymentRef.current +
            partialCashPaymentRef.current +
            payment,
            0,
            orderBillAmount
          )
        ) {
          setPartialOnlinePayment(e.target.value);
          partialOnlinePaymentRef.current = payment;
        } else {
          setPartialOnlinePaymentErr(true);
        }
        break;
    }

    if (
      partialCardPaymentRef.current +
      partialCashPaymentRef.current +
      partialOnlinePaymentRef.current !=
      orderBillAmount
    ) {
      setPartialOnlinePaymentErr(true);
      setPartialCardPaymentErr(true);
      setPartialCashPaymentErr(true);
    } else {
      setPartialOnlinePaymentErr(false);
      setPartialCardPaymentErr(false);
      setPartialCashPaymentErr(false);
    }
  };
  const handleSaveKOT = async () => {
    if (cart.length === 0) {
      toast.error("KOT is empty");
      return
    }
    const billNo = await saveManualOrder();
    if (billNo) {
      handleCloseOrderMenu();
    }
    setKOTPendingItems([]);
    setKOTCancelledItems([])
    KOTPendingItemsRef.current = [];
    KOTCancelledItemsRef.current = {};
  };
  const handlePrintKOT = async (reprint = false, parcel = data.room.toLowerCase() == 'parcel') => {
    if (cartRef.length === 0) {
      toast.error("KOT is empty");
      return
    }

    const billNo = await saveManualOrder();
    const customer = {
      name: customerName,
      phone: customerPhone,
      address: customerAddress
    }
    const printDetails = {
      cartItems: cartRef.current,
      roomData: data,
      extraCartItems: [],
      cancelItems: Object.values(KOTCancelledItemsRef.current) || [],
      comment: '',
      billNo,
      customer
    }

    if (parcel) {
      printKOT({ ...printDetails, type: "Parcel KOT" });
      setKOTPendingItems([]);
      setKOTCancelledItems([])
      KOTPendingItemsRef.current = [];
      KOTCancelledItemsRef.current = {};;
      return
    }

    if (reprint) {
      printKOT({ ...printDetails, type: "Reprint KOT" });
      setKOTPendingItems([]);
      setKOTCancelledItems([])
      KOTPendingItemsRef.current = [];
      KOTCancelledItemsRef.current = {};;
      return
    }

    if (billNo) {
      if (Object.values(KOTCancelledItemsRef.current).length > 0 && KOTPendingItemsRef.current.length > 0) {
        printKOT({ ...printDetails, type: "Updated KOT" });
      } else if (Object.values(KOTCancelledItemsRef.current).length > 0) {
        printKOT({ ...printDetails, type: "Cancelled KOT", cartItems: [] });
      } else {
        printKOT({ ...printDetails, type: "Check In", cancelItems: [], cartItems: KOTPendingItemsRef.current });
      }
      setKOTPendingItems([]);
      setKOTCancelledItems([]);
      KOTPendingItemsRef.current = [];
      KOTCancelledItemsRef.current = {};;
      handleCloseOrderMenu();
    }
  };
  const handleReprintKOT = () => {
    printKOT(" Reprint KOT", cart, data, [], "", billNo);
  };
  const handlePrintBill = async () => {
    let _billNo = await saveManualOrder();
    const {
      subtotal,
      calculated_cgst,
      calculated_sgst,
      serviceTax,
      total,
      discount,
      orderBillAmount,
    } = getBillDetails()

    const billDetails = {
      gst: isGST,
      gstNo: "",
      cgstPercent: cgst,
      sgstPercent: sgst,
      cgst: calculated_cgst,
      sgst: calculated_sgst,
      gstExcluded: gstItemType == "Exclude",
      service: orderServiceTax,
      total: orderBillAmount,
      beforeDiscountTotal: total,
      subTotal: subtotal,
      discount: discount,
      discountPercent: percentageDiscount,
      discountFlat: flatDiscount,
      tip: tip,
    };

    const customer = {
      name: customerName,
      phone: customerPhone,
      address: customerAddress
    }

    await generateOrderBill({
      tableVPA: data.tableVPA,
      waiterid: orderWaiter !== 'none' ? orderWaiter : 'NA',
      discount: totalDiscount,
      totalAmount: orderBillAmount,
      name: customerName,
      mobile: customerPhone,
      billDetails,
    });

    PrintBills(cart, store.restaurantInfo, data, _billNo, billDetails, customer);
    setOrderStatus("KOTBillGenerated");
    setKOTPendingItems([]);
    setKOTCancelledItems([])
    KOTPendingItemsRef.current = [];
    KOTCancelledItemsRef.current = {};;
    orderStatusRef.current = "KOTBillGenerated";
    setIsExpanded(true);
  };
  const DoneOrderBYbillNo = async (billNo) => {
    const ORDER_DONE_EVENT = new CustomEvent('ORDER_DONE');
    window.dispatchEvent(ORDER_DONE_EVENT);
    handleCloseOrderMenu();
    syncCloud();
  };
  const handleDoneOrder = async () => {
    await saveManualOrder()
    if (
      partialOnlinePaymentErr ||
      partialCardPaymentErr ||
      partialCashPaymentErr ||
      tipErr
    ) {
      toast.error("Verify order fields");
      return;
    }

    if (orderPaymentMethod === "") {
      toast.error("Select Payment Method");
      return;
    }

    if (orderPaymentMethod === 'Other' && orderOtherPaymentMethod === '') {
      toast.error("Please add other Payment Method");
      return;
    }

    const methods = [];
    const paymentname = orderPaymentMethod.toLowerCase();
    const tableVPA = data.tableVPA;
    if (paymentname === "partial") {
      methods.push(
        ...[
          {
            name: "card",
            amount: parseInt(partialCardPayment),
          },
          {
            name: "cash",
            amount: parseInt(partialCashPayment),
          },
          {
            name: "online",
            amount: parseInt(partialOnlinePayment),
          },
        ]
      );
    } else if (paymentname === 'other') {
      methods.push({
        name: orderOtherPaymentMethod.toLowerCase(),
        amount: parseInt(orderBillAmount),
      });
    } else {
      methods.push({
        name: orderPaymentMethod.toLowerCase(),
        amount: parseInt(orderBillAmount),
      });
    }

    const response = await completeOrderBill({
      methods,
      tableVPA,
      paymentname,
      tips: tip,
    });
    if (response) {
      DoneOrderBYbillNo(billNo);
    }
  };
  const convertCartToOrderFormat = (cartItems) => {
    return cartItems?.map((item) => ({
      itemId: item.id,
      quantity: item.quantity,
      varientid: item.variantId,
    }));
  };
  const saveManualOrder = async () => {
    let cart = Object.values(cartRef.current.reduce((acc, item) => {
      if (item.uniqueId.includes("_online")) {
        item.uniqueId = item.id + (item.isVariant ? `_${item.variantId}` : "");
      }
      if (!acc[item.uniqueId]) {
        acc[item.uniqueId] = item;
      } else {
        acc[item.uniqueId].quantity += parseInt(item.quantity);
      }
      return acc;
    }, {}));

    const cartItems = cart.filter((item) => !item?.canceled);
    const canceledItems = cart.filter((item) => item.canceled);

    let rawOrder = cartItems;
    console.log(rawOrder)
    rawOrder = rawOrder.map((item) => ({
      ...item,
      itemId: item.id,
      id: item.id,
      variantId: item.variantId,
      name: item.name,
      variantName: item.isVariant ? item.variantName : "",
      price: item.price,
      quantity: item.quantity,
      uniqueId: item.id + (item?.isVariant ? `_${item.variantId}` : ""),
      extra: item.extra,
      status: "KOTPrint",
    }));
    const formattedOrder = convertCartToOrderFormat(cartItems);
    return new Promise(async (resolve, reject) => {
      try {
        const {
          subtotal,
          calculated_cgst,
          calculated_sgst,
          serviceTax,
          total,
          discount,
          orderBillAmount,
        } = getBillDetails();

        const billDetails = {
          gst: isGST,
          gstNo: "",
          cgstPercent: cgst,
          sgstPercent: sgst,
          cgst: calculated_cgst,
          sgst: calculated_sgst,
          gstExcluded: gstItemType == "Exclude",
          service: orderServiceTax,
          total: orderBillAmount,
          beforeDiscountTotal: total,
          subTotal: subtotal,
          discount: discount,
          discountPercent: percentageDiscount,
          discountFlat: flatDiscount,
          tip: tip,
        };

        const response = await processManualOrder({
          canceledItems: canceledItems,
          rawOrder,
          formattedOrder,
          table: {
            room: data.room,
            table: data.table,
            vpa: data.tableVPA,
          },
          customer: {
            name: customerName || "NA",
            phone: customerPhone || "NA",
            address: customerAddress || 'NA'
          },
          bill: {
            subTotal: subtotal,
            cgst: calculated_cgst,
            sgst: calculated_sgst,
            flatDiscount: flatDiscount,
            discountPercent: percentageDiscount,
            totalDiscount: flatDiscount + total * (percentageDiscount / 100),
            billBeforeDiscount: total,
            billAmount: orderBillAmount,
          },
          billDetails
        });

        if (response) {
          setCart(cart)
          resolve(response);
        }
      } catch (error) {
        reject(error);
        toast.error("Something went wrong please try again");
      }
    });
  };
  const handleCloseOrderMenu = () => {
    closeEdit();
    setIsMenuOpen(false);

  };
  const handleOrderCancel = () => {
    console.log("protected canceled order");
    cancelOrderReasonRef.current = true
    setShowAdminControlPasswordDialogue(true)
    callBackRef.current = {
      func: confirmCancelOrder,
      parameters: []
    }
  };
  const confirmCancelOrder = (reason) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await cancelOrder(data.tableVPA, reason);
        if (response) {
          resolve(true);
            DoneOrderBYbillNo(billNo);
        } else {
          reject(false);
          toast.error("Something went wrong please try again");
        }
      } catch (error) {
        reject(false);
        toast.error("Something went wrong please try again");
      }
    });
  };
  const formattedTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleAdminControl = async ({ password, reason }) => {
    if (password === "12345") {
      setAdminControlTimer(60);
      setAdminControl(true);
      adminControlRef.current = true
      setShowAdminControlPasswordDialogue(false);
      if (callBackRef.current) {
        console.log(callBackRef.current)
        if (cancelOrderReasonRef.current)
          callBackRef.current.parameters.push(reason)
        await callBackRef.current.func.apply(null, callBackRef.current.parameters)
      }
      callBackRef.current = null
      cancelOrderReasonRef.current = false
    } else {
      toast.error("Password is incorrect");
    }
  };
  const generateRandomID = () => {
    return Math.floor(10000 + Math.random() * 90000); // Generates a number between 10000 and 99999
  }
  const acceptKOTRequest = async ({ print }) => {
    const vpa = data.tableVPA
    try {
      const onlineCode = await getOnlineOrderBillNo(vpa);
      const response = await changeKOTStatus({
        billNo: onlineCode,
      });

      if (response.event) {
        setKOTPendingItems((prev) => [...prev, ...KOTRequestedItemsRef.current])
        if(print)
          handlePrintKOT()
        store.removeDigitalOrderVPA(vpa);
        setKOTRequestedItems([])
        await saveManualOrder();
        setKOTPendingItems([]);
        setKOTCancelledItems([])
        KOTPendingItemsRef.current = [];
        KOTCancelledItemsRef.current = {};
        
      }
    } catch (e) {
      toast.error("Something went wrong, Please refresh software and try again!");
    }
  }
  const rejectKOTRequest = async () => {
    const vpa = data.tableVPA
    try {
      const onlineCode = await getOnlineOrderBillNo(vpa);
      const response = await orderReject({
        billNo: onlineCode,
        items: [...KOTRequestedItems],
      });

      if (response.event) {
        await rejectOnlineOrder(vpa);
        setKOTRequestedItems([]);
        toast.success("Successfully Rejected KOT");
        handleCloseOrderMenu()
      }
    } catch (e) {
      toast.error("Something went wrong, Please refresh software and try again!");
    }
  }
  const handleAddExtra = async (printKOT = false) => {

    if (
      extraItemName.trim() === "" ||
      extraItemQuantity == 0 ||
      extraItemQuantity === "" ||
      extraItemPrice == 0 ||
      extraItemPrice === ""
    ) {
      toast.error("Verify add extra");
      return;
    }
    await addToCart(
      {
        id: generateRandomID(),
        name: extraItemName,
        quantity: parseInt(extraItemQuantity),
        price: parseInt(extraItemPrice),
        extra: true,
      },
      []
    );
    if (printKOT)
      await handlePrintKOT();

    setShowAddExtra(false);

  }

  const updateComment = (item, comment) => {
    setCart((prev) => {
      return prev.map(_item => {
        if (_item.uniqueId == item.uniqueId) {
          _item.comment = comment;
        }
        return _item
      })
    })
    KOTPendingItemsRef.current = KOTPendingItemsRef.current.map(_item => {
      if (_item.uniqueId == item.uniqueId) {
        _item.comment = comment;
      }
      return _item
    })
  }
  const trackCancelledItems = (item) => {
    if (!KOTCancelledItemsRef.current[item.uniqueId]) {
      KOTCancelledItemsRef.current[item.uniqueId] = { ...item, quantity: 1 }
      setKOTCancelledItems(Object.values(KOTCancelledItemsRef.current))
      return
    }
    KOTCancelledItemsRef.current[item.uniqueId].quantity += 1;
    setKOTCancelledItems(Object.values(KOTCancelledItemsRef.current))
  }
  const categoryImages = {
    CategoryChefIcon,
    CategoryChilliIcon,
    CategoryNonvegIcon,
    CategoryVegIcon,
    CategoryBestSellerIcon,
    CategoryStarIcon,
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <PasswordDialog
        isOpen={showAdminControlPasswordDialogue}
        onClose={() => setShowAdminControlPasswordDialogue(false)}
        onSubmit={handleAdminControl}
        askReason={cancelOrderReasonRef.current}
      />
      <WarningDialog
        title={"Are you confirm to cancel the order ?"}
        isOpen={showCancelWarning}
        onClose={() => setShowCancelWarning(false)}
        onSubmit={confirmCancelOrder}
      />

      <WarningDialog
        title={"KOT not saved, please confirm to go back without saving !"}
        isOpen={backWarning}
        onClose={() => setBackWarning(false)}
        onSubmit={() => {
          closeEdit();
          setIsMenuOpen(false);
          setBackWarning(false)
        }}
        confirmText="Go back"
      />


      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style={{zIndex: 500}}>
          <div
            ref={menuRef}
            className="bg-white rounded-lg shadow-xl w-full max-w-[100vw] h-screen flex flex-col overflow-hidden"
          >
            {/* header */}
            <div className="p-2 border-b border-gray-300 flex justify-between items-center">
              <h2 className="text-2xl font-bold text-gray-900">
                Table {data.table}{" "}
                <span className="rounded-2xl px-4 text-md bg-gray-100 ">
                  {data.room}
                </span>
              </h2>
              <div className="flex items-center gap-2">
                {adminControl && (
                  <>
                    {" "}
                    <LiveDot />{" "}
                    <span className="text-xs">
                      {" "}
                      {formattedTime(adminControlTimer)} Admin control
                    </span>
                  </>
                )}

                <button
                  onClick={() => {
                    if (KOTPendingItems.length > 0 || KOTCancelledItems.length > 0) {
                      setBackWarning(true);
                      return;
                    }
                    handleCloseOrderMenu()
                  }}
                  className="text-blue-400 hover:text-gray-600 border border-dashed border-blue-400 flex p-2 gap-2 rounded"
                >
                  <MoveLeft className="h-4 w-4  md:h-6 md:w-6" />
                  Back
                </button>
              </div>
            </div>
            <div className="flex-1 overflow-hidden flex flex-col md:flex-row">
              {/* Categories Sidebar */}
              <div className="w-full md:w-[15%] p-1 md:p-4 overflow-y-auto border-r">
                <h3 className="text-xs md:text-lg font-medium text-gray-900 mb-1 md:mb-4">
                  Categories
                </h3>
                <div className="md:space-y-2 flex flex-row md:flex-col gap-2 md:gap-0 overflow-x-scroll p-2 md:p-1 hide-scrollbar">
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => setSelectedCategory(category)}
                      className={`min-w-[80px] max-w-[250px] md:w-full text-left text-xs md:text-sm text-center md:text-left p-1 md:px-4 md:py-2 rounded-md  bg-gray-100 ${selectedCategory === category
                        ? " ring-2 bg-green-50 ring-green-500 text-green-700"
                        : "text-gray-700 hover:ring-1 hover:ring-green-500 "
                        }`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>

              {/* Main Content */}
              <div className="flex-1 flex flex-col overflow-hidden border-top border-solid">
                {/* Search Bar */}
                <div className="p-2 md:p-4 bg-white border-b border-gray-300 flex gap-2">
                  <div className="relative flex-1">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 " />
                    <input
                      ref={searchInputRef}
                      type="text"
                      placeholder="Search menu (Crtl+S)"
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none  text-xs md:text-l"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div
                    key={"extra-item"}
                    className="bg-gray-50  rounded-lg shadow-sm  hover:shadow-md transition-shadow border border-dashed border-gray-300 cursor-pointer hover:ring-green-1   relative  flex justify-center items-center content-center min-w-[100px] bg-green-600 text-white"
                    onClick={() => setShowAddExtra(true)}
                  >
                    <h3 className="text-sm font-medium text-white ">
                      Add Extra
                    </h3>
                  </div>
                </div>

                {/* Product Grid */}
                <div className="flex-1 overflow-y-auto p-2 md:p-4 bg-gray-50 no-scrollbar">
                  <div className="grid grid-cols-4 gap-2 md:gap-4 ">
                    {filteredProducts.map((product, index) => (
                      <div
                        key={product.id}
                        className={`bg-white rounded-lg shadow-sm p-2 pt-1 hover:shadow-md transition-shadow border border-gray-300 cursor-pointer hover:ring-1 hover:ring-green-500 relative h-[70px] ${productIndex === index && "ring-1 ring-green-500"
                          } ${lastAddedItem &&
                            lastAddedItem.id === product.id
                            ? "ring-2 ring-gray-100 transition-all duration-100"
                            : ""
                          }
                            "` }
                        onClick={() => {
                          product?.variant.length > 0
                            ? setSelectedProduct(product)
                            : addToCart(product);
                          setProductIndex(index);
                        }}
                      >
                        <h3 className="text-xs md:text-sm font-medium text-gray-900">
                          {product.name}
                        </h3>
                        <div className="absolute bottom-1 md:top-1 right-1">
                          {productDetailsMap[product.id].veg && (
                            <img src={categoryImages["CategoryVegIcon"]} alt="veg icon" width="15px" height="15px" />
                          )}
                          {productDetailsMap[product.id].nonveg && (
                            <img src={categoryImages["CategoryNonvegIcon"]} alt="nonveg icon" width="20px" height="20px" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Cart Panel */}
              <div className={`w-full md:w-[35%] max-h-[75%] md:max-h-none bg-white border-l border-gray-300 flex flex-col border border-solid relative`}>

                {/* drop down */}
                <svg
                  className={`w-6 h-6 transition-transform duration-200 md:hidden float-right ml-auto ${showCartDetails ? "" : "rotate-180"
                    }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  onClick={() => setShowCartDetails(prev => !prev)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>

                {/* Header */}
                {
                  showCartDetails && (<div className="p-4 border-b border-gray-300">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <span className="text-xs md:text-lg font-medium">Order</span>
                        {billNo && (
                          <span className="text-xs md:text-sm text-gray-500">
                            Bill: {billNo}
                          </span>
                        )}
                      </div>

                      {orderStatus != "" && (
                        <div className="flex items-center gap-1">
                          <button
                            className="px-3 py-1.5 text-xs md:text-sm border border-gray-300 rounded-md bg-red-600 text-white"
                            onClick={() => handleOrderCancel()}
                          >
                            Cancel order
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="text-xs md:text-sm text-gray-600 flex justify-between items-center">
                      <span> Customer: {customerName}</span>
                      <span>
                        <UserRoundPen
                          size={15}
                          className="hover:stroke-green-600 cursor-pointer"
                          onClick={() => setShowCustomerEditForm(true)}
                        />
                      </span>
                    </div>
                  </div>)
                }


                {/* Cart Items */}
                {
                  showCartDetails && <div className="flex-1  overflow-y-auto p-2 space-y-2">
                    {cart.map((item) => (
                      <div
                        key={`${item.id}-${item.variantId}`}
                        id={`cart-item-${item.id}-${item.variantId}`}
                        className={` border justify-between items-center gap-2 bg-gray-50 px-2 py-1 rounded-lg relative ${lastAddedItem &&
                          lastAddedItem.id === item.id &&
                          lastAddedItem.variantId === item.variantId
                          ? "ring-2 ring-green-500 transition-all duration-300"
                          : ""
                          }`}
                      >
                        <Play className="stroke-green-500 absolute top-1 left-1" width={'15px'} onClick={() => {
                          setSelectedCartItem(prev => {
                            if (prev === item.uniqueId) {
                              return ''
                            }
                            return item.uniqueId
                          })
                        }} />
                        <div className="flex">
                          <div className="flex-1 pl-4">
                            <h4 className="text-xs md:text-sm font-medium text-gray-900">
                              {item.name} {item.variant && "- " + item.variant}
                            </h4>

                            <p className="text-xs md:text-sm text-gray-600">
                              ₹{item.price}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => updateQuantity(item, false)}
                              className="h-4 w-4  md:h-6 md:w-6 flex items-center justify-center border border-gray-300 bg-red-500  rounded hover:bg-gray-100  "
                            >
                              <svg
                                className="w-3 h-3"
                                fill="none"
                                stroke="white"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M20 12H4"
                                />
                              </svg>
                            </button>
                            <span className="w-8 text-center">
                              {item.quantity}
                            </span>
                            <button
                              onClick={() => updateQuantity(item, true)}
                              className="h-4 w-4  md:h-6 md:w-6 flex items-center justify-center border border-gray-300 rounded bg-green-500 hover:bg-gray-100  "
                            >
                              <svg
                                className="w-3 h-3"
                                fill="none"
                                stroke="white"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 4v16m8-8H4"
                                />
                              </svg>
                            </button>
                            <button
                              onClick={() => removeItem(item)}
                              className=" h-5 w-5 md:h-10 md:w-10 flex items-center justify-center text-gray-400 hover:text-gray-600  "
                            >
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="red"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        {/* comment sections */}
                        {
                          selectedCartItem == item.uniqueId && <div className="mt-2">
                            <textarea
                              type="password"
                              id="password"
                              placeholder="Comment"
                              value={item.comment || ''}
                              onChange={(e) => updateComment(item, e.target.value)}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                        }
                      </div>
                    ))}
                  </div>

                }

                {/* Bill Details */}
                <div className="p-4 border-t border-gray-300 relative text-xs md:text-lg">
                  <button
                    className="w-full flex items-center justify-between mb-1 py-2 hover:bg-gray-50 rounded-md gap-2  "
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    <span className="font-medium flex-1 text-left">Bill Details</span>
                    <span className="text-xl"><b>₹ {orderBillAmount}</b></span>
                    <svg
                      className={`w-4 h-4 transition-transform duration-200 ${isExpanded ? "" : "rotate-180"
                        }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>

                  {isExpanded &&
                    (orderStatus === "KOTBillGenerated" ? (
                      <div className="space-y-2 mb-2">
                        {/* discounts */}
                        <div className="grid grid-cols-3 gap-2">
                          <div>
                            <label className="text-sm text-gray-600 mb-1 block">
                              Flat Discount
                            </label>
                            <div
                              className={`flex rounded-md ${flatDiscountErr && "ring-1 ring-red-200"
                                }`}
                            >
                              <input
                                type="text"
                                value={flatDiscount}
                                onChange={handleFlatDiscountChange}
                                className="w-full rounded-l-md border border-r-0 border-gray-300 px-3 py-1.5 text-sm focus:outline-none "
                              />
                              <button className="px-2 bg-gray-50 border border-l-0 border-gray-300 rounded-r-md text-sm">
                                Flat
                              </button>
                            </div>
                          </div>
                          <div>
                            <label className="text-sm text-gray-600 mb-1 block">
                              Discount(%)
                            </label>
                            <div
                              className={`flex rounded-md ${percentageDiscountErr && "ring-1 ring-red-200"
                                }`}
                            >
                              <input
                                type="text"
                                value={percentageDiscount}
                                onChange={handlePercentageDiscountChange}
                                className="w-full rounded-l-md border border-r-0 border-gray-300 px-3 py-1.5 text-sm focus:outline-none "
                              />
                              <button className="px-2 bg-gray-50 border border-l-0 border-gray-300 rounded-r-md text-sm">
                                %
                              </button>
                            </div>
                          </div>
                          <div>
                            <label className="text-sm text-gray-600 mb-1 block">
                              Coupon
                            </label>
                            <div className="flex">
                              <input
                                value={couponCode}
                                onChange={(e) =>
                                  setCouponCode(e.target.value)
                                }
                                placeholder="Code"
                                disabled
                                className="w-full rounded-l-md border border-r-0 border-gray-300 px-3 py-1.5 text-sm focus:outline-none "
                              />
                              <button className="px-2 bg-gray-50 border border-l-0 border-gray-300 rounded-r-md text-sm">
                                <svg
                                  className="w-4 h-4"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Waiter Selection */}
                        <div className="flex item-center gap-5">
                          <div className="relative flex-1">
                            {/* <button
                              type="button"
                              className="flex items-center justify-between w-full rounded-md border border-gray-300 px-1 py-1  text-sm bg-white hover:bg-gray-50"
                            >
                              <span className="text-gray-600">
                                Select waiter
                              </span>
                              <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                            </button> */}

                            <select value={orderWaiter} onChange={e => setOrderWaiter(e.target.value)} name="cars" id="cars" class="block w-full px-3 py-1  mt-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500">
                              <option value="none" class="text-gray-700"> Select waiter</option>
                              {
                                store.waiters.map((waiter) => <option value={waiter.id} class="text-gray-700"> {waiter.name}</option>)
                              }
                            </select>
                          </div>

                          {/* Tip Input */}
                          <div className="flex items-center gap-1">
                            <label className="text-sm text-gray-600">
                              Tips{" "}
                            </label>
                            <input
                              type="number"
                              value={tip}
                              onChange={handleTipChange}
                              className={`max-w-[100px] rounded-md border border-gray-300  px-3 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-gray-200  ${tipErr && "ring-1 focus:ring-red-200"
                                }`}
                            />
                          </div>
                        </div>

                        {/* Payment Methods */}
                        <div className="grid grid-cols-6 gap-2 py-5">
                          {["Cash", "Card", "Online", "Partial", "Pending", 'Other'].map(
                            (method) => (
                              <button
                                key={method}
                                className={`px-1 py-1 text-sm border border-gray-300 rounded-md  ${orderPaymentMethod === method &&
                                  "bg-green-500 text-white"
                                  } `}
                                onClick={() => setOrderPaymentMethod(method)}
                              >
                                {method}
                              </button>
                            )
                          )}
                        </div>

                        {/* Amount Paid */}
                        <div>
                          {orderPaymentMethod === "Partial" ? (
                            <>
                              <div className="flex justify-between items-center mb-1">
                                <label className="text-sm text-gray-600">
                                  Cash
                                </label>
                                <input
                                  type="number"
                                  value={partialCashPayment}
                                  onChange={(e) =>
                                    handlePartialPaymentChange(e, "Cash")
                                  }
                                  className={` ${partialCashPaymentErr &&
                                    "ring-1  ring-red-200 focus:ring-red-200"
                                    } max-w-[100px] rounded-md border border-gray-300  px-3 py-1 text-right text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                                />
                              </div>

                              <div className="flex justify-between items-center mb-1">
                                <label className="text-sm text-gray-600">
                                  Online
                                </label>
                                <input
                                  type="number"
                                  value={partialOnlinePayment}
                                  onChange={(e) =>
                                    handlePartialPaymentChange(e, "Online")
                                  }
                                  className={` ${partialOnlinePaymentErr &&
                                    "ring-1 ring-red-200 focus:ring-red-200"
                                    }  max-w-[100px] rounded-md border border-gray-300  px-3 py-1 text-right text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                                />
                              </div>

                              <div className="flex justify-between items-center mb-1">
                                <label className="text-sm text-gray-600">
                                  Card
                                </label>
                                <input
                                  type="number"
                                  value={partialCardPayment}
                                  onChange={(e) =>
                                    handlePartialPaymentChange(e, "Card")
                                  }
                                  className={` ${partialCardPaymentErr &&
                                    "ring-1 ring-red-200 focus:ring-red-200"
                                    } max-w-[100px] rounded-md border border-gray-300  px-3 py-1 text-right text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                                />
                              </div>

                            </>
                          ) : (
                            <></>
                          )}

                          {orderPaymentMethod === "Cash" ? (
                            <>
                              <div className="flex justify-between items-center">
                                <label className="text-sm text-gray-600">
                                  Amount Paid
                                </label>
                                <input
                                  type="number"
                                  value={amountPaid}
                                  onChange={(e) =>
                                    setAmountPaid(e.target.value)
                                  }
                                  className="max-w-[100px] rounded-md border border-gray-300  px-1 py-1 text-right text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                />
                              </div>

                              {/* Return Change */}
                              <div className="flex justify-between text-sm pr-1">
                                <span className="text-gray-600">
                                  Return change
                                </span>
                                <span>
                                  ₹{(amountPaid == '' ? 0 : parseInt(amountPaid)) - orderBillAmount}
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {orderPaymentMethod === "Other" ? (
                            <>
                              <div className="flex justify-between items-center">
                                <label className="text-sm text-gray-600">
                                  Other method
                                </label>
                                <select
                                  value={orderOtherPaymentMethod}
                                  onChange={(e) => setOrderOtherPaymentMethod(e.target.value)}
                                  className="max-w-[100px] rounded-md border border-gray-300 px-1 py-1 text-right text-sm focus:outline-none focus:ring-2 focus:ring-gray-200"
                                >
                                  <option value="Zomato Dining/District">Zomato Dining/District
                                   </option>
                                  <option value="⁠Swiggy/Dineout">⁠Swiggy/Dineout</option>
                                  <option value="MagicPin">MagicPin</option>
                                  <option value="Sodexo">Sodexo</option>
                                  <option value="⁠Eazydiner"> ⁠Eazydiner</option>
                                  <option value="Friends & Family"> Friends & Family </option>
                                  <option value="⁠Other">⁠Other</option>
                                </select>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}


                        </div>

                        {/* Amount Breakdown */}
                        <div className="space-y-1 py-1 my-5 border-t border-gray-100 ">
                          <div className="flex justify-between text-sm">
                            <span className="text-gray-600">Subtotal</span>
                            <span>₹{subTotal}</span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="text-gray-600">
                              Additional charges(Gst,service)
                            </span>
                            <span>
                              ₹{orderCGST + orderSGST + orderServiceTax}
                            </span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="text-gray-600">Discount</span>
                            <span>₹{totalDiscount}</span>
                          </div>
                          <div className="flex justify-between text-sm font-medium pt-2 border-t border-gray-100">
                            <span>Total:</span>
                            {orderBillAmount == orderTotal ? (
                              <p>₹{orderTotal}</p>
                            ) : (
                              <p>
                                <span className="line-through">
                                  ₹{orderTotal}
                                </span>{" "}
                                ₹{orderBillAmount}{" "}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="space-y-4 mb-4">
                        {orderStatus === "KOTPrint" && (
                          <div className="grid grid-cols-3 gap-2 hidden md:flex">
                            <div>
                              <label className="text-sm text-gray-600 mb-1 block">
                                Flat Discount
                              </label>
                              <div
                                className={`flex rounded-md ${flatDiscountErr && "ring-1 ring-red-200"
                                  }`}
                              >
                                <input
                                  type="text"
                                  value={flatDiscount}
                                  onChange={handleFlatDiscountChange}
                                  className="w-full rounded-l-md border border-r-0 border-gray-300 px-3 py-1.5 text-sm focus:outline-none "
                                />
                                <button className="px-2 bg-gray-50 border border-l-0 border-gray-300 rounded-r-md text-sm">
                                  Flat
                                </button>
                              </div>
                            </div>
                            <div>
                              <label className="text-sm text-gray-600 mb-1 block">
                                Discount(%)
                              </label>
                              <div
                                className={`flex rounded-md ${percentageDiscountErr && "ring-1 ring-red-200"
                                  }`}
                              >
                                <input
                                  type="text"
                                  value={percentageDiscount}
                                  onChange={handlePercentageDiscountChange}
                                  className="w-full rounded-l-md border border-r-0 border-gray-300 px-3 py-1.5 text-sm focus:outline-none "
                                />
                                <button className="px-2 bg-gray-50 border border-l-0 border-gray-300 rounded-r-md text-sm">
                                  %
                                </button>
                              </div>
                            </div>
                            <div>
                              <label className="text-sm text-gray-600 mb-1 block">
                                Coupon
                              </label>
                              <div className="flex">
                                <input
                                  value={couponCode}
                                  onChange={(e) =>
                                    setCouponCode(e.target.value)
                                  }
                                  placeholder="Code"
                                  disabled
                                  className="w-full rounded-l-md border border-r-0 border-gray-300 px-3 py-1.5 text-sm focus:outline-none "
                                />
                                <button className="px-2 bg-gray-50 border border-l-0 border-gray-300 rounded-r-md text-sm">
                                  <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="space-y-2 text-sm">
                          <div className="flex justify-between">
                            <span className="text-gray-600">Subtotal</span>
                            <span>₹{subTotal}</span>
                          </div>

                          {isGST && (
                            <div className="flex justify-between">
                              <span className="text-gray-600">
                                CGST ({cgst}%)
                              </span>
                              <span>₹{orderCGST}</span>
                            </div>
                          )}
                          {isGST && (
                            <div className="flex justify-between">
                              <span className="text-gray-600">
                                SGST ({sgst}%)
                              </span>
                              <span>₹{orderSGST}</span>
                            </div>
                          )}
                          {
                            isGST && <span className="px-2 bg-gray-100 rounded-xl absolute top-[-10px] right-1"> GST {gstItemType == 'Include' ? "Included" : "Excluded"} </span>
                          }
                          {orderServiceTax && (
                            <div className="flex justify-between">
                              <span className="text-gray-600">Service tax</span>
                              <span>₹{orderServiceTax}</span>
                            </div>
                          )}
                          {flatDiscount > 0 && (
                            <div className="flex justify-between">
                              <span className="text-gray-600">
                                Flat discount
                              </span>
                              <span>₹{flatDiscount}</span>
                            </div>
                          )}
                          {percentageDiscount > 0 && (
                            <div className="flex justify-between">
                              <span className="text-gray-600">
                                discount {percentageDiscount}%
                              </span>
                              <span>
                                ₹
                                {Math.round(
                                  orderTotal * (percentageDiscount / 100)
                                )}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="flex justify-between text-base font-medium text-gray-900 mb-4">
                          <p>Total</p>
                          {orderBillAmount == orderTotal ? (
                            <p>₹{orderTotal}</p>
                          ) : (
                            <p>
                              <span className="line-through">
                                ₹{orderTotal}
                              </span>{" "}
                              ₹{orderBillAmount}{" "}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}

                  <div className="grid grid-cols-2 gap-2">
                    <button
                      disabled={!adminControl && ["KOTBillGenerated"].includes(orderStatus)}
                      className={`p-2 tex-xs md:text-sm md:px-4 md:py-2 border border-gray-300 rounded-md hover:bg-gray-5 disabled:bg-gray-200 disabled:cursor-not-allowed relative 
                      ${KOTPendingItems.length > 0 || KOTCancelledItems.length > 0 ? "bg-black text-white" : ''}`}
                      onClick={handleSaveKOT}
                    >
                      {
                        KOTPendingItems.length > 0 || KOTCancelledItems.length > 0
                          ? <TriangleAlert size="15" className="stroke-yellow-600 absolute top-1 right-1" /> : ""}
                      Save
                    </button>
                    <button
                      className={`p-2 tex-xs md:text-sm md:px-4 md:py-2  border border-gray-300 rounded-md hover:bg-gray-50 disabled:bg-gray-200 disabled:cursor-not-allowed  
                      ${KOTPendingItems.length > 0 || KOTCancelledItems.length > 0 ? "bg-black text-white" : ''}`}
                      onClick={() => handlePrintKOT(
                        !(KOTPendingItemsRef.current.length > 0 || Object.keys(KOTCancelledItemsRef.current).length > 0)
                      )}
                    >
                      {KOTPendingItemsRef.current.length > 0 || Object.keys(KOTCancelledItemsRef.current).length > 0 ? 'Print KOT' : 'Reprint KOT'}
                    </button>
                    <button
                      className="p-2 tex-xs md:text-sm md:px-4 md:py-2  border border-gray-300 rounded-md bg-green-600 text-white disabled:bg-gray-200 disabled:cursor-not-allowed hidden md:block"
                      onClick={handlePrintBill}
                    >
                      {orderStatus === "KOTBillGenerated"
                        ? "RePrint Bill"
                        : "Print Bill"}
                    </button>
                    <button
                      disabled={["KOTPrint", ""].includes(orderStatus)}
                      className="p-2 tex-xs md:text-sm md:px-4 md:py-2 border border-gray-300  bg-blue-600 text-white rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed hidden md:block"
                      onClick={handleDoneOrder}
                    >
                      Order Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Product Selection Popup */}
      {selectedProduct && selectedProduct?.variant?.length > 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" style={{zIndex:501}}>
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <form onSubmit={handleSubmit}>
              <h2 className="text-xl font-bold mb-4">{selectedProduct.name}</h2>
              <p className="text-lg font-medium mb-4">
                ₹{selectedProduct.price}
              </p>

              <div className="mb-4">
                <h3 className="text-lg font-medium mb-2">Select Variants:</h3>
                <div className="grid grid-cols-2 gap-3">
                  {selectedProduct.variant.map((variant, index) => (
                    <button
                      key={variant.id}
                      type="button"
                      onClick={() => {
                        setSelectedVariants((prev) =>
                          prev.some((_variant) => _variant.id === variant.id)
                            ? prev.filter(
                              (_variant) => _variant.id !== variant.id
                            )
                            : [...prev, variant]
                        );
                      }}
                      className={`p-4 rounded-lg border-2 text-left transition-all
                        ${lastAddedItem &&
                          lastAddedItem.id === selectedProduct.id && lastAddedItem.variantId === variant.id
                          ? "ring-2 ring-gray-100 transition-all duration-100"
                          : ""
                        }
                        ${variantIndex == index && "border-green-600"}
                       ${selectedVariants.some(
                          (_variant) => _variant.id === variant.id
                        )
                          ? "border-blue-600"
                          : "border-gray-300 hover:border-gray-300"
                        }
                     `}
                    >
                      <span className="font-medium">{variant.varientName}</span>
                    </button>
                  ))}
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => {
                    setSelectedVariants([]);
                    setSelectedProduct([]);
                  }}
                  className="p-2 tex-xs md:text-sm md:px-4 md:py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => addToCart(selectedProduct, selectedVariants)}
                  className="p-2 tex-xs md:text-sm md:px-4 md:py-2 bg-green-600 text-white rounded-md hover:ring-2 hover:ring-green-200"
                >
                  Add to Order
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showCustomerEditForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" style={{zIndex:501}}>
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <label
                  htmlFor="customername"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer Name
                </label>
                <input
                  type="text"
                  id="customername"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  required
                />
              </div>
              <div className="mt-3">
                <label
                  htmlFor="customerphone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </label>
                <input
                  type="number"
                  id="customerphone"
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  required
                />
              </div>
              {
                data.room.toLowerCase() === 'parcel' && <div className="mt-3">
                  <label
                    htmlFor="customerphone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    id="custmerAddress"
                    value={customerAddress}
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    required
                  />
                </div>

              }

              <div className="flex justify-end space-x-2 mt-5">
                <button
                  type="button"
                  onClick={() => {
                    setShowCustomerEditForm(false);
                  }}
                  className="p-2 tex-xs md:text-sm md:px-4 md:py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleSaveCustomerDetails()}
                  className="p-2 tex-xs md:text-sm md:px-4 md:py-2 bg-green-600 text-white rounded-md hover:ring-1  hover:ring-green-500 "
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showExtra && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" style={{zIndex:501}}>
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Item Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={extraItemName}
                  onChange={(e) => setExtraItemName(e.target.value)}
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  required
                />
              </div>
              <div className="mt-3">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Price (₹)
                </label>
                <input
                  type="number"
                  id="price"
                  value={extraItemPrice}
                  onChange={(e) => setExtraItemPrice(e.target.value)}
                  min="0"
                  step="0.01"
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  required
                />
              </div>
              <div className="mt-3">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700"
                >
                  quantity
                </label>
                <input
                  type="number"
                  id="price"
                  value={extraItemQuantity}
                  onChange={(e) => setExtraItemQuantity(e.target.value)}
                  min="0"
                  step="0.01"
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  required
                />
              </div>
              <div className="flex justify-end space-x-2 mt-5">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddExtra(false);
                  }}
                  className="p-2 tex-xs md:text-sm md:px-4 md:py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleAddExtra()
                  }}
                  className="p-2 tex-xs md:text-sm md:px-4 md:py-2 bg-green-600 text-white rounded-md hover:ring-1  hover:ring-green-500 "
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick=
                  {async () => {
                    handleAddExtra(true)
                  }}
                  className="p-2 tex-xs md:text-sm md:px-4 md:py-2 bg-green-600 text-white rounded-md hover:ring-1  hover:ring-green-500 "
                >
                  Print KOT
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {
        KOTRequestedItems.length > 0 && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" style={{zIndex:501}} >
          <div className="bg-white rounded-lg p-6 max-w-md w-full space-y-2">
            <h4> Digital Order</h4>
            {
              KOTRequestedItems.map((item) => (
                <div
                  key={`${item.id}-${item.variantId}`}
                  id={`cart-item-${item.id}-${item.variantId}`}
                  className={`flex border justify-between items-center gap-2 bg-gray-50 px-2 py-1 rounded-lg ${lastAddedItem &&
                    lastAddedItem.id === item.id &&
                    lastAddedItem.variantId === item.variantId
                    ? "ring-2 ring-green-500 transition-all duration-300"
                    : ""
                    }`}
                >
                  <div className="flex-1">
                    <h4 className="text-sm font-medium text-gray-900">
                      {item.name}{" "}
                      {item.variant && "- " + item.variant}
                    </h4>
                    <p className="text-sm text-gray-600">
                      ₹{item.price}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => updateQuantity(item, false)}
                      disabled
                      className="h-4 w-4  md:h-6 md:w-6 flex items-center justify-center border border-gray-300 rounded hover:bg-gray-100  "
                    >
                      <svg
                        className="w-3 h-3"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M20 12H4"
                        />
                      </svg>
                    </button>
                    <span className="w-8 text-center">
                      {item.quantity}
                    </span>
                    <button
                      onClick={() => updateQuantity(item, true)}
                      disabled
                      className="h-4 w-4  md:h-6 md:w-6 flex items-center justify-center border border-gray-300 rounded hover:bg-gray-100  "
                    >
                      <svg
                        className="w-3 h-3"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}

            <div className="flex justify-end space-x-2 mt-5">
              <button
                type="button"
                onClick={() => {
                  rejectKOTRequest()
                }}
                className="p-2 tex-xs md:text-sm md:px-4 md:py-2 border border-gray-300 rounded-md text-gray-700 bg-red-50"
              >
                Reject
              </button>
              <button
                type="button"
                onClick=
                {async () => {
                  acceptKOTRequest({ print: false })
                }}
                className="p-2 tex-xs md:text-sm md:px-4 md:py-2 bg-green-600 text-white rounded-md hover:ring-1  hover:ring-green-500 "
              >
                Save
              </button>

              <button
                type="button"
                onClick=
                {async () => {
                  acceptKOTRequest({ print: true })
                }}
                className="p-2 tex-xs md:text-sm md:px-4 md:py-2 bg-green-600 text-white rounded-md hover:ring-1  hover:ring-green-500 "
              >
                Print KOT
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const LiveDot = () => {
  return (
    <div className="relative inline-flex">
      <span className="flex h-3 w-3">
        <span className="animate-ping absolute inline-flex h-screen w-full rounded-full bg-red-200 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-200"></span>
      </span>
      <span className="absolute -inset-0.5 rounded-full border border-red-300 opacity-75"></span>
    </div>
  );
};

export default observer(OrderMenu);
