import { useState, useEffect, useRef } from "react"
import { useCloud } from "../hooks/useCloud"
import store from "../store/index";
import Switch from "../Component/Switch"
import toast from "react-hot-toast";
import { Check, X, MoreVertical, Plus, ChevronDown, User, Search } from 'lucide-react'

const ENABLED_SETTINGS = ['partners', 'gst', 'fssai', 'staff']

export default function Settings() {
  const [activeTab, setActiveTab] = useState("gst")

  // GST CONFIGURATIONS
  const { gstUpdate } = useCloud();
  const [state, setState] = useState({
    gstActive: false,
    selectedValue: '',
    gstn: '',
    sgst: '',
    cgst: '',
    acSgst: '',
    acCgst: '',
    vat: '',
    serviceCharge: '',
  });
  const [editOption, setEditOption] = useState(false);

  useEffect(() => {
    console.log(store.restaurantInfo)

    const { restaurantInfo } = store
    const isGST = restaurantInfo.isGST !== undefined ? restaurantInfo.isGST : localStorage.getItem('isGST') === 'true';
    const selectedValue = restaurantInfo.gstItemType || localStorage.getItem('gstPriceType');
    const gstn = restaurantInfo.gstn || localStorage.getItem('GSTN');
    const sgst = restaurantInfo.sgst || localStorage.getItem('SGST');
    const cgst = restaurantInfo.cgst || localStorage.getItem('CGST');
    const acSgst = restaurantInfo.acSgst || localStorage.getItem('AC_SGST');
    const acCgst = restaurantInfo.acCgst || localStorage.getItem('AC_CGST');
    const vat = restaurantInfo.vat || localStorage.getItem('VAT');
    const serviceCharge = restaurantInfo.serviceCharge || localStorage.getItem('SERVICE');
    const fassi_no = restaurantInfo.fassi_no;
    setState((prev) => ({ selectedValue, gstn, sgst, cgst, acSgst, acCgst, vat, gstActive: isGST, serviceCharge, isGST, fassi_no }));
  }, []);

  const handleStateChange = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = async () => {
    const { gstn, selectedValue, sgst, cgst, acSgst, acCgst, vat, serviceCharge, fassi_no } = state;
    const data = await gstUpdate(state);
    store.restaurantInfo = { ...store.restaurantInfo, gstn, gstItemType: selectedValue, sgst, cgst, acSgst, acCgst, vat, serviceCharge, fassi_no }
    localStorage.setItem('gstPriceType', selectedValue);
    localStorage.setItem('GSTN', gstn);
    localStorage.setItem('SGST', sgst);
    localStorage.setItem('CGST', cgst);
    localStorage.setItem('AC_SGST', acSgst);
    localStorage.setItem('AC_CGST', acCgst);
    localStorage.setItem('VAT', vat);
    localStorage.setItem('SERVICE', serviceCharge);

    toast.success('GST and tax details saved successfully!');
  };

  const toggleGST = (value) => {
    const updatedGstActive = value;
    localStorage.setItem('isGST', updatedGstActive);
    setState((prev) => ({ ...prev, gstActive: updatedGstActive }));
    store.restaurantInfo = { ...store.restaurantInfo, isGST: updatedGstActive }
    handleSave()
  };

  // PARTNER CONFIGURATIONS
  const [partners, setPartners] = useState([])

  // State for form
  const [selectedPartner, setSelectedPartner] = useState("")
  const [restaurantId, setRestaurantId] = useState("")
  const [isAddingNew, setIsAddingNew] = useState(false)
  const [editingPartnerId, setEditingPartnerId] = useState(null)

  // State for dropdown menu
  const [openMenuId, setOpenMenuId] = useState(null)
  const [isPartnerDropdownOpen, setIsPartnerDropdownOpen] = useState(false)
  const menuRef = useRef(null)
  const partnerDropdownRef = useRef(null)


  useEffect(() => {
    const storedPartners = JSON.parse(localStorage.getItem('integratedPartners')) || [];
    setPartners(Array.isArray(storedPartners) ? storedPartners : []);
  }, []);

  // Close dropdowns when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      // if (menuRef.current && !menuRef.current.contains(event.target)) {
      //   setOpenMenuId(null)
      // }
      if (partnerDropdownRef.current && !partnerDropdownRef.current.contains(event.target)) {
        setIsPartnerDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  // Handle form submission
  const handleSubmit = () => {
    if (!selectedPartner || !restaurantId) return

    if (editingPartnerId) {
      // Update existing partner
      setPartners(
        partners.map((partner) =>
          partner._id === editingPartnerId ? { ...partner, partner: selectedPartner, id: restaurantId } : partner,
        ),
      )
      setEditingPartnerId(null)
    } else {
      // Add new partner
      const newPartner = {
        _id: Date.now().toString(),
        partner: selectedPartner,
        id: restaurantId,
      }
      setPartners([...partners, newPartner])
      localStorage.setItem('integratedPartners', JSON.stringify([...partners, newPartner]));
    }

    // Reset form
    setSelectedPartner("")
    setRestaurantId("")
    setIsAddingNew(false)

  }

  // Start editing a partner
  const handleEdit = (partner) => {
    setSelectedPartner(partner.partner)
    setRestaurantId(partner.id)
    setEditingPartnerId(partner._id)
    setIsAddingNew(true)
    setOpenMenuId(null)
  }

  // Delete a partner
  const handleDelete = (_id) => {
    setPartners(partners.filter((partner) => partner._id !== _id))
    setOpenMenuId(null)
    localStorage.setItem('integratedPartners', JSON.stringify(partners));
  }

  // Cancel adding/editing
  const handleCancel = () => {
    setIsAddingNew(false)
    setEditingPartnerId(null)
    setSelectedPartner("")
    setRestaurantId("")
  }

  // Select partner from dropdown
  const handleSelectPartner = (partner) => {
    setSelectedPartner(partner)
    setIsPartnerDropdownOpen(false)
  }

  return (
    <div className="bg-gray-50 ">
      <div className="max-w-[1400px] h-[calc(100vh)] w-[100vw] md:w-[calc(100vw-120px)] mx-auto p-4 box-border bg-gray-50 overflow-x-hidden relative hide-scrollbar">
        <div className="bg-white rounded-lg shadow-lg border border-gray-200 h-full overflow-x-scroll hide-scrollbar relative ">
          <div className="p-2 px-6 bg-white border-b border-gray-200 rounded-t-lg  bg-white flex-wrap gap-2 sticky top-0 flex flex-col gap-4 rounded-t-lg bg-white">
            <div>
              <h2 className="font-semibold text-lg text-gray-900">Settings</h2>
            </div>
          </div>

          {/* Tabs */}
          <div className="border-b bg-white  border-gray-200 sticky top-0" style={{ zIndex: 100 }}>
            <div className="flex overflow-x-auto">

              {
                ENABLED_SETTINGS.includes('gst') && <button
                  onClick={() => setActiveTab("gst")}
                  className={`px-6 py-3 text-sm font-medium whitespace-nowrap ${activeTab === "gst"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : " hover:text-gray-700 hover:border-gray-300"
                    }`}
                >
                  GST/VAT
                </button>
              }

              {
                ENABLED_SETTINGS.includes('fssai') && <button
                  onClick={() => setActiveTab("fssai")}
                  className={`px-6 py-3 text-sm font-medium whitespace-nowrap ${activeTab === "fssai"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : " hover:text-gray-700 hover:border-gray-300"
                    }`}
                >
                  FSSAI
                </button>
              }
              {
                ENABLED_SETTINGS.includes('partners') && <button
                  onClick={() => setActiveTab("partners")}
                  className={`px-6 py-3 text-sm font-medium whitespace-nowrap ${activeTab === "partners"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : " hover:text-gray-700 hover:border-gray-300"
                    }`}
                >
                  Partners
                </button>
              }

              {
                ENABLED_SETTINGS.includes('staff') && <button
                  onClick={() => setActiveTab("staff")}
                  className={`px-6 py-3 text-sm font-medium whitespace-nowrap ${activeTab === "staff"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : " hover:text-gray-700 hover:border-gray-300"
                    }`}
                >
                  Staff
                </button>
              }
              {
                ENABLED_SETTINGS.includes('notifications') && <button
                  onClick={() => setActiveTab("notifications")}
                  className={`px-6 py-3 text-sm font-medium whitespace-nowrap ${activeTab === "notifications"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : " hover:text-gray-700 hover:border-gray-300"
                    }`}
                >
                  Notifications
                </button>
              }
            </div>
          </div>

          {/* Partners Tab Content */}
          {activeTab === "partners" && (
            <div className="p-6">
              <div className="flex justify-end mb-2">
                {!isAddingNew && (
                  <button
                    onClick={() => setIsAddingNew(true)}
                    className="flex items-center gap-1 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-50 rounded-md border border-dashed border-gray-300"
                  >
                    <Plus size={14} />
                    Add New Partner
                  </button>
                )}
              </div>

              <div className="border border-gray-200 rounded-md shadow-sm">
                <table className="w-full h-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3 border-b border-gray-200">
                        Partner
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/2 border-b border-gray-200">
                        Restaurant ID
                      </th>
                      <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6 border-b border-gray-200">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {/* Form row for adding/editing - always at the top */}
                    {isAddingNew && (
                      <tr className="bg-gray-50">
                        <td className="px-4 py-3">
                          <div className="relative" ref={partnerDropdownRef}>
                            <button
                              type="button"
                              onClick={() => setIsPartnerDropdownOpen(!isPartnerDropdownOpen)}
                              className="w-full flex items-center justify-between px-3 py-1.5 text-sm rounded-md border border-gray-300 bg-white hover:bg-gray-50"
                            >
                              {selectedPartner || "Select Partner"}
                              <ChevronDown
                                size={16}
                                className={`transition-transform ${isPartnerDropdownOpen ? "rotate-180" : ""}`}
                              />
                            </button>

                            {isPartnerDropdownOpen && (
                              <div className="absolute left-0 mt-1 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                <div className="py-1">
                                  {["Swiggy", "Zomato"].map((partner) => (
                                    <button
                                      key={partner}
                                      onClick={() => handleSelectPartner(partner)}
                                      className={`w-full text-left px-4 py-2 text-sm ${selectedPartner === partner
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700 hover:bg-gray-50"
                                        }`}
                                    >
                                      {partner}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-4 py-3">
                          <input
                            type="text"
                            value={restaurantId}
                            onChange={(e) => setRestaurantId(e.target.value)}
                            placeholder="Enter restaurant ID"
                            className="w-full px-3 py-1.5 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                        </td>
                        <td className="px-4 py-3 text-center">
                          <div className="flex justify-center gap-2">
                            <button
                              onClick={handleSubmit}
                              disabled={!selectedPartner || !restaurantId}
                              className={`p-1.5 rounded-md ${!selectedPartner || !restaurantId
                                ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                                : "bg-gray-500 text-white hover:bg-gray-600"
                                }`}
                              title={editingPartnerId ? "Update" : "Save"}
                            >
                              <Check size={16} />
                            </button>
                            <button
                              onClick={handleCancel}
                              className="p-1.5 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                              title="Cancel"
                            >
                              <X size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}

                    {/* Existing partners */}
                    {partners.map((partner) => (
                      <tr key={partner._id} className={editingPartnerId === partner._id ? "hidden" : ""}>
                        <td className="px-4 py-3 text-sm text-gray-900">{partner.partner}</td>
                        <td className="px-4 py-3 text-sm text-gray-500">{partner.id}</td>
                        <td className="px-4 py-3 text-center">
                          <div className="inline-block relative">
                            <button
                              type="button"
                              onClick={() => setOpenMenuId(openMenuId == partner._id ? null : partner._id)}
                              className="p-1.5 rounded-md hover:bg-gray-100"
                            >
                              <MoreVertical size={16} />
                            </button>

                            {openMenuId === partner._id && (
                              <div className="absolute right-0 mt-1 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10" key={partner._id}>
                                <div className="py-1">
                                  <button
                                    onClick={() => handleEdit(partner)}
                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDelete(partner._id)}
                                    className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}


                    {/* Empty state */}
                    {partners.length === 0 && !isAddingNew && (
                      <tr>
                        <td colSpan={3} className="px-4 py-4 text-center text-sm text-gray-500">
                          No partners integrated yet.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* GST Tab Content */}
          {activeTab === "gst" && (
            <div className="p-6">

              {
                !state.gstActive ? (<div className="">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-semibold text-gray-800">GST Status</h2>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium text-gray-900">Activate GST</h3>
                    </div>
                    <div className="md:col-span-3">
                      <div className="space-y-4">
                        <div>
                          <Switch value={state.gstActive} onChange={toggleGST} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>) : (
                  <>
                    <div className="">
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold text-gray-800">GST & Tax Configuration</h2>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                        <div className="md:col-span-1">
                          <h3 className="text-base font-medium text-gray-900">Pricing Configuration</h3>
                        </div>
                        <div className="md:col-span-3">
                          <div className="space-y-4">
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">Select Pricing Type</label>
                              <select className="w-full px-3 py-2 border border-gray-300 rounded-md" value={state.selectedValue}
                                onChange={(e) => handleStateChange('selectedValue', e.target.value)}>
                                <option>Select Pricing Type</option>
                                <option value="Include">Inclusive of Tax</option>
                                <option value="Exclude">Exclusive of Tax</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 pt-6 mt-6">
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                        <div className="md:col-span-1">
                          <h3 className="text-base font-medium text-gray-900">GSTN Details</h3>
                        </div>
                        <div className="md:col-span-3">
                          <div className="space-y-4">
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">GSTN Number</label>
                              <input
                                type="text"
                                placeholder="Enter your GSTN"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                value={state.gstn}
                                onChange={(e) => handleStateChange('gstn', e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 pt-6 mt-6">
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                        <div className="md:col-span-1">
                          <h3 className="text-base font-medium text-gray-900">Tax Rates</h3>
                        </div>
                        <div className="md:col-span-3">
                          <div className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">SGST (%)</label>
                                <input
                                  type="text"
                                  placeholder="Enter SGST"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                  value={state.sgst}
                                  onChange={(e) => handleStateChange('sgst', e.target.value)}
                                />
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">CGST (%)</label>
                                <input
                                  type="text"
                                  placeholder="Enter CGST"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                  value={state.cgst}
                                  onChange={(e) => handleStateChange('cgst', e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">AC SGST (%)</label>
                                <input
                                  type="text"
                                  placeholder="Enter AC SGST"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                  value={state.acSgst}
                                  onChange={(e) => handleStateChange('acSgst', e.target.value)}
                                />
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">AC CGST (%)</label>
                                <input
                                  type="text"
                                  placeholder="Enter AC CGST"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                  value={state.acCgst}
                                  onChange={(e) => handleStateChange('acCgst', e.target.value)}

                                />
                              </div>
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">VAT (%)</label>
                              <input
                                type="text"
                                placeholder="Enter VAT"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                value={state.vat}
                                onChange={(e) => handleStateChange('vat', e.target.value)}

                              />
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">Service Charge (%)</label>
                              <input
                                type="text"
                                placeholder="Enter Service Charge"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                value={state.serviceCharge}
                                onChange={(e) => handleStateChange('serviceCharge', e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 text-right flex gap-2">
                      <div className="flex-1"> </div>
                      <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md transition-colors"
                        onClick={handleSave}
                      >
                        Save Tax Settings
                      </button>
                      <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition-colors"
                        onClick={() => toggleGST(false)}
                      >
                        Deactivate GST
                      </button>
                    </div>
                  </>)
              }



            </div>
          )}

          {/* FSSAI Tab Content */}
          {activeTab === "fssai" && (
            <div className="p-6">
              <>
                <div className="">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-semibold text-gray-800">FSSAI</h2>
                  </div>
                </div>
                <div className="border-t border-gray-200 pt-6 mt-6">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium text-gray-900">FSSAI Details</h3>
                    </div>
                    <div className="md:col-span-3">
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">FSSAI Number</label>
                          <input
                            type="text"
                            placeholder="Enter your FSSAI"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            value={state.fassi_no}
                            onChange={(e) => handleStateChange('fassi_no', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-8 text-right flex gap-2">
                  <div className="flex-1"> </div>
                  <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md transition-colors"
                    onClick={handleSave}
                  >
                    Save FSSAI
                  </button>
                </div>
              </>
            </div>
          )}

          {/* Staff Tab Content */}
          {activeTab === "staff" && (<Staff_Management />
          )}

          {/* Notifications Tab Content */}
          {activeTab === "notifications" && (
            <div className="p-6">
              <div className="mb-8">
                <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md transition-colors">
                  Test Digital order notification
                </button>
              </div>

              <div className="">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                  <div className="md:col-span-1">
                    <h3 className="text-base font-medium text-gray-900">Notification Channels</h3>
                  </div>
                  <div className="md:col-span-3">
                    <div className="space-y-4">
                      <div className="flex items-center justify-between p-3 border border-gray-200 rounded-md">
                        <div>
                          <h3 className="font-medium text-gray-800">Email Notifications</h3>
                          <p className="text-sm ">Receive order updates via email</p>
                        </div>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" className="sr-only peer" defaultChecked />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                      </div>

                      <div className="flex items-center justify-between p-3 border border-gray-200 rounded-md">
                        <div>
                          <h3 className="font-medium text-gray-800">SMS Notifications</h3>
                          <p className="text-sm ">Receive order updates via SMS</p>
                        </div>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" className="sr-only peer" />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                      </div>

                      <div className="flex items-center justify-between p-3 border border-gray-200 rounded-md">
                        <div>
                          <h3 className="font-medium text-gray-800">Push Notifications</h3>
                          <p className="text-sm ">Receive push notifications for new orders</p>
                        </div>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" className="sr-only peer" defaultChecked />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-200 pt-6 mt-6">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                  <div className="md:col-span-1">
                    <h3 className="text-base font-medium text-gray-900">Contact Information</h3>
                  </div>
                  <div className="md:col-span-3">
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Notification Email</label>
                        <input
                          type="email"
                          placeholder="Enter email address"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Notification Phone Number</label>
                        <input
                          type="tel"
                          placeholder="Enter phone number"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 text-right">
                <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors">
                  Save Notification Settings
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function Partner_Management() {
}

function FSSAI_Management() {
}

function GST_Management() {
}

function Staff_Management() {
  const { getWaiterList, addNewWaiter, updateWaiter, removeWaiter } = useCloud()
  const [searchQuery, setSearchQuery] = useState("")
  const [loading, setLoading] = useState(true)
  const [staff, setStaff] = useState([
  ])
  const [filteredStaff, setFilteredStaff] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [name, setName] = useState("")
  const [staffId, setWaiterId] = useState("")
  const [phone, setPhone] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("")
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false)
  const [editingStaffId, setEditingStaffId] = useState(null)
  const [openMenuId, setOpenMenuId] = useState(null)

  const statusDropdownRef = useRef(null)
  const menuRefs = useRef({})
  const modalRef = useRef(null)

  const fetchStaff = async () => {
    const { success, waiters } = await getWaiterList();
    if (success) {
      const staff = waiters.map(staff => ({
        _id: Math.random(),
        id: staff.id,
        name: staff.name,
        phone: "Not available",
        status: staff.isAvailable ? "Active" : "Not Active"
      }))
      setStaff(staff)
      setFilteredStaff(staff)

    }
    setLoading(false)
  }

  useEffect(() => {
    fetchStaff()
  }, [])

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
        setIsStatusDropdownOpen(false)
      }

      // Close any open menu when clicking outside
      if (openMenuId && menuRefs.current[openMenuId] && !menuRefs.current[openMenuId]?.contains(event.target)) {
        setOpenMenuId(null)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [openMenuId])

  // Handle modal click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCancel()
      }
    }

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isModalOpen])

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isModalOpen])

  useEffect(() => {
    if (searchQuery.length > 0) {
      const filteredStaff = staff.filter(
        (member) =>
          member.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          member.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
          member.phone.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      console.log(filteredStaff)
      setFilteredStaff(filteredStaff)
    } else {
      setFilteredStaff(staff)
    }
  }, [searchQuery.length])


  const handleSelectStatus = (status) => {
    setSelectedStatus(status)
    setIsStatusDropdownOpen(false)
  }

  const handleSubmit = async () => {
    if (editingStaffId) {
      const staff = staff.find(staff => staff._id = editingStaffId)
      // Update existing staff
      const res = await updateWaiter({
        waiterId: staff.id
      })
      console.log(res)
      setStaff(
        staff.map((staff) =>
          staff._id === editingStaffId ? { ...staff, name, id: staffId, phone, status: selectedStatus } : staff,
        ),
      )
    } else {
      // Add new staff

      const { event, waiter } = await addNewWaiter({
        name,
      })
      if (!event) {
        toast.error("Failed, please try again ")
        return
      }
      const newStaff = {
        _id: Date.now().toString(),
        name: waiter.name,
        id: waiter.id,
        phone: "Not available",
        status: waiter.isAvailable ? "Active" : "Not Active"
      }
      setStaff([...staff, newStaff])
      setFilteredStaff(([...filteredStaff, newStaff]))
      toast.success("Staff added successfully")
    }

    // Reset form
    handleCancel()
  }

  const handleCancel = () => {
    setName("")
    setWaiterId("")
    setPhone("")
    setSelectedStatus("")
    setIsModalOpen(false)
    setEditingStaffId(null)
  }

  const handleAddNew = () => {
    setEditingStaffId(null)
    setName("")
    setWaiterId("")
    setPhone("")
    setSelectedStatus("")
    setIsModalOpen(true)
  }

  const handleEdit = (staff) => {
    setName(staff.name)
    setWaiterId(staff.id)
    setPhone(staff.phone)
    setSelectedStatus(staff.status)
    setEditingStaffId(staff._id)
    setIsModalOpen(true)
    setOpenMenuId(null)
  }

  const handleDelete = async (id, staffId) => {
    const { event } = await removeWaiter({
      waiterId: staffId
    })
    if (!event) {
      toast.error("Failed to remove staff, try again")
    }
    setStaff(staff.filter((staff) => staff._id !== id))
    setFilteredStaff(filteredStaff.filter((staff) => staff._id !== id))
    setOpenMenuId(null)
    toast.success("Staff removed successfully")
  }

  const loadingUserAnimation = () => (<div className="bg-white rounded-lg shadow-md border border-gray-200 overflow-hidden">
    <div className="p-4">
      <div className="flex justify-between items-start">
        <div className="flex items-center gap-3">
          <div className="flex-shrink-0 h-12 w-12 rounded-full bg-gray-200 animate-pulse"></div>
          <div>
            <div className="h-5 w-32 bg-gray-200 rounded animate-pulse mb-2"></div>
            <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
          </div>
        </div>
        <div className="h-8 w-8 bg-gray-200 rounded animate-pulse"></div>
      </div>

      <div className="mt-4 pt-4 border-t border-gray-100">
        <div className="grid grid-cols-2 gap-2">
          <div>
            <div className="h-3 w-10 bg-gray-200 rounded animate-pulse mb-2"></div>
            <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
          </div>
          <div>
            <div className="h-3 w-10 bg-gray-200 rounded animate-pulse mb-2"></div>
            <div className="h-5 w-16 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  </div>)

  if (loading) {
    return (
      <div className="p-6 w-full h-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {[1,2,3].map(user => loadingUserAnimation())}
        </div>
      </div>
    )
  }



  return (
    <div className="p-6">
      <div className="flex justify-end items-center mb-6 gap-2">
        <div className="relative w-64">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <Search size={16} className="text-gray-400" />
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-3 py-1.5 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search staff..."
          />
          {searchQuery && (
            <button
              onClick={() => setSearchQuery("")}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
            >
              <X size={14} />
            </button>
          )}
        </div>
        <button
          onClick={handleAddNew}
          className="flex items-center gap-1 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-50 rounded-md border border-dashed border-gray-300"
        >
          <Plus size={16} />
          Add New Staff
        </button>
      </div>
      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4" style={{ zIndex: 500 }}>
          <div
            ref={modalRef}
            className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center border-b border-gray-200 px-6 py-4">
              <h3 className="text-lg font-medium text-gray-900">
                {editingStaffId ? "Edit Staff" : "Add New Staff"}
              </h3>
              <button onClick={handleCancel} className="text-gray-400 hover:text-gray-500">
                <X size={20} />
              </button>
            </div>

            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter name"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                {/* <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">ID</label>
                  <input
                    type="text"
                    value={staffId}
                    onChange={(e) => setWaiterId(e.target.value)}
                    placeholder="Enter ID"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter phone"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div> */}
                {/* <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                  <div className="relative" ref={statusDropdownRef}>
                    <button
                      type="button"
                      onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
                      className="w-full flex items-center justify-between px-3 py-2 text-sm rounded-md border border-gray-300 bg-white hover:bg-gray-50"
                    >
                      {selectedStatus || "Select Status"}
                      <ChevronDown
                        size={16}
                        className={`transition-transform ${isStatusDropdownOpen ? "rotate-180" : ""}`}
                      />
                    </button>

                    {isStatusDropdownOpen && (
                      <div className="absolute left-0 mt-1 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                        <div className="py-1">
                          {["Active", "Inactive"].map((status) => (
                            <button
                              key={status}
                              onClick={() => handleSelectStatus(status)}
                              className={`w-full text-left px-4 py-2 text-sm ${selectedStatus === status
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700 hover:bg-gray-50"
                                }`}
                            >
                              {status}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
              {/* Actions */}
              <div className="flex justify-end gap-2 mt-6">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={!name}
                  className={`px-4 py-2 rounded-md text-sm text-white ${!name
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-green-500 hover:bg-green-600"
                    }`}
                >
                  {editingStaffId ? "Update Staff" : "Add Staff"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Staff Cards */}
      {filteredStaff.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredStaff.map((staff) => (
            <div key={staff._id} className="bg-white rounded-lg shadow-md border border-gray-200 overflow-hidden">
              <div className="p-4">
                <div className="flex justify-between items-start">
                  <div className="flex items-center gap-3">
                    <div className="flex-shrink-0 h-12 w-12 bg-blue-100 rounded-full flex items-center justify-center text-blue-600">
                      <User className="h-6 w-6" />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">{staff.name}</h3>
                      <p className="text-sm text-gray-500">ID: {staff.id}</p>
                    </div>
                  </div>
                  <div className="relative" ref={(el) => (menuRefs.current[staff._id] = el)}>
                    <button
                      type="button"
                      onClick={() => setOpenMenuId(openMenuId === staff._id ? null : staff._id)}
                      className="p-1.5 rounded-md hover:bg-gray-100"
                    >
                      <MoreVertical size={16} />
                    </button>

                    {openMenuId === staff._id && (
                      <div className="absolute right-0 mt-1 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                        <div className="py-1">
                          {/* Not supported from backend yet */}
                          {/* <button
                            onClick={() => handleEdit(staff)}
                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Edit
                          </button> */}
                          <button
                            onClick={() => handleDelete(staff._id, staff.id)}
                            className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-4 pt-4 border-t border-gray-100">
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <p className="text-xs text-gray-500">Phone</p>
                      <p className="text-sm">{staff.phone}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-500">Status</p>
                      <span
                        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${staff.status === "Active" ? "bg-green-100 text-green-800" : "bg-gray-100 text-gray-800"
                          }`}
                      >
                        {staff.status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white rounded-lg p-8 text-center">
          <User className="h-12 w-12 mx-auto text-gray-400" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">No staff added yet</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by adding your first staff.</p>
          <button
            onClick={handleAddNew}
            className="mt-4 px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-md border border-dashed border-gray-300"
          >
            Add Staff
          </button>
        </div>
      )}
    </div>
  )
}