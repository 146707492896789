import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import AppRouter from "./routes/AppRouter"
import { Toaster } from "react-hot-toast";
import {
  Link,
} from "react-router-dom";
import { useCloud } from "./hooks/useCloud";
import { useStore } from "./hooks/useStore";
import useOrder from "./hooks/useOrder";
import store from "./store/index";
import { openDB } from "idb";
import { messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import { playRing } from "./Component/PlaySound";
import { fetchDataAndHandleOrders } from "./service_zomato";
import { transformDigitalOrderItems } from "./utils/common"

const DB_NAME = "orderDB";
const IN_PROCESS_ORDERS_STORE_NAME = "InProcessOrders";
const COMPLETE_ORDERS_STORE_NAME = "completedOrders";
const ORDER_HISTORY_STORE_NAME = "orderHistory";
const DB_VERSION = 1;


function App() {
  useStore();
  const { getDigitalOrders } = useCloud();
  const { processOrder } = useOrder();

  const [digitalOrderReceived, setDigitalOrderReceived] = React.useState(false);
  const [partnerOrderReceived, setPartnerOrderReceived] = React.useState(false);

  // INIT LOCAL DB
  useEffect(() => {
    const initDB = async () => {
      const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(IN_PROCESS_ORDERS_STORE_NAME)) {
            db.createObjectStore(IN_PROCESS_ORDERS_STORE_NAME, {
              keyPath: "tableVPA",
            });
          }
          if (!db.objectStoreNames.contains(COMPLETE_ORDERS_STORE_NAME)) {
            db.createObjectStore(COMPLETE_ORDERS_STORE_NAME, {
              keyPath: "billNo",
            });
          }
          if (!db.objectStoreNames.contains(ORDER_HISTORY_STORE_NAME)) {
            db.createObjectStore(ORDER_HISTORY_STORE_NAME, {
              keyPath: "billNo",
            });
          }
          if (!db.objectStoreNames.contains("newOrder")) {
            db.createObjectStore("newOrder", {
              keyPath: "order",
            });
          }
        },
      });
      store.setDBInstance(db);
    };

    initDB();
  }, []);

  // DIGITAL ORDER NOTIFICATION
  useEffect(() => {
    const unsubscribe = onMessage(messaging, async (payload) => {
      handleNewOrder();
    });

    return () => unsubscribe();
  }, []);

  // CHECK DIGITAL ORDER
  useEffect(() => {
    const checkNewOrder = async () => {
      const db = store.dbInstance;
      if (db) {
        try {
          const order = await db.get("newOrder", "newOrder");
          if (order) {
            await handleNewOrder();
            await db.delete("newOrder", "newOrder");
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    checkNewOrder();
    window.addEventListener("focus", checkNewOrder);
    return () => {
      window.removeEventListener("focus", checkNewOrder);
    };
  }, []);

  // DIGITAL ORDER PROCESSING
  useEffect(() => {
    const fetchDigitalOrder = async () => {
      const { event, requestKOTAmoutntItem, requestKot } =
        await getDigitalOrders();
      if (event) {
        // You can add some condition to check if the order has already been processed
        requestKOTAmoutntItem.forEach(async (order) => {
          const items = transformDigitalOrderItems(order)
          const formatedOrder = order.items.map((newOrder) => ({
            itemId: newOrder.itemId,
            quantity: newOrder.quantity,
            varientid: newOrder.varientid || undefined,
          }));
          const rawOrder = items.map((newOrder) => ({
            uniqueId: newOrder.uniqueId,
            itemId: newOrder.itemId,
            id: newOrder.id,
            name: newOrder.name,
            price: newOrder.price || "0",
            variant: newOrder.variant,
            quantity: parseInt(newOrder.quantity),
            variantId: newOrder.variantId,
            variantName: newOrder.variantName,
            isVariant: newOrder.isVariant,
            extra: false,
            createdAt: new Date(),
            readyAt: false
          }));
          const response = await processOrder({
            rawOrder,
            formatedOrder,
            tableVPA: order.tableVPA,
            cancleOrders: [],
            extraOrders: [],
            username: order.name,
            usermobile: order?.mobileNo || "NA",
            orderStatus: "RequestKOT",
            onlineBillNo: order.onlineCode,
            replaceItems: "RequestKOT",
            isOnline: true,
          });
        });
        const digitalOrdersTables = requestKot.map((table) => table.tableVPA);
        store.setDigitalOrderVPA(digitalOrdersTables);
        const digitalOrderProcessed = new CustomEvent(
          "digitalOrderProcessed",
          {}
        );
        window.dispatchEvent(digitalOrderProcessed);
      }
    };

    // Function to call fetchDigitalOrder on event
    const handleDigitalOrderReceived = () => {
      setDigitalOrderReceived(true);
      fetchDigitalOrder();
    };

    // Add event listener for 
    window.addEventListener(
      "DIGITAL_ORDER_RECEIVED",
      handleDigitalOrderReceived
    );

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener(
        "DIGITAL_ORDER_RECEIVED",
        handleDigitalOrderReceived
      );
    };
  }, []);

  // SWIGGY/ZOMATO CHECKUP
  useEffect(() => {
    const interval = setInterval(async () => {
      const count = await fetchDataAndHandleOrders();
      if (count > 0) {
        setPartnerOrderReceived(true)
      }
    }, 20000);
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleNewOrder = async () => {
    try {
      await playRing();
    } catch (e) {
      console.log(e);
    }
    const orderEvent = new CustomEvent("DIGITAL_ORDER_RECEIVED", {});
    window.dispatchEvent(orderEvent);
  };

  return (
    <div className="w-screen h-screen">
      {/* DIGITAL ORDER */}
      {digitalOrderReceived && (
        <div
          class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          style={{ zIndex: 501 }}
          onClick={() => setDigitalOrderReceived(false)}
        >
          <div class="bg-white rounded-lg shadow-lg p-6 w-96 text-center relative">
            <div class="absolute top-[10px] left-1/2 transform -translate-x-1/2 text-3xl text-green-500">
              <i class="fas fa-exclamation-circle"></i>
            </div>
            <div class="text-xl font-semibold flashing text-gray-800 mt-6">
              Digital Order Received
            </div>
          </div>
        </div>
      )}
      {partnerOrderReceived && (
        // <Link to="/order-parcel" onClick={() => setPartnerOrderReceived(false)}>
          <div
            class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            style={{ zIndex: 501 }}
            onClick={()=> setPartnerOrderReceived(false)}
          >
            <div class="bg-white rounded-lg shadow-lg p-6 w-96 text-center relative">
              <div class="absolute top-[10px] left-1/2 transform -translate-x-1/2 text-3xl text-green-500">
                <i class="fas fa-exclamation-circle"></i>
              </div>
              <div class="text-xl font-semibold flashing text-gray-800 mt-6">
                Partner Order Received
              </div>
            </div>
          </div>
        // </Link>
      )}
      <Toaster position="top-right" toastOptions={{ duration: 1000 }} />
      {/* HEADER */}
      {/* SIDEBAR */}

      <AppRouter />
    </div>
  )

}


export default App;
