import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OrderListDiital from "./Component/OrderList";

const Ordermain = () => {
  const [activeTab, setActiveTab] = useState("parcel");
  const [orderParcel, setOrderParcel] = useState([
    { orderNo: 1 },
    { orderNo: 2 },
    { orderNo: 4 },
  ]);
  const navigate = useNavigate();
  return (
    <>
      <OrderListDiital />
    </>
  );
};

export default Ordermain;
